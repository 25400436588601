import * as React from "react";

import {
	IDenyUserCriteria,
	IDenyUserResponse,
	IRegisterUser,
} from "../../types/types";
import { Theme, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { denyUserAction } from "./userlist.actions";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
	paper: { minWidth: "500px",  maxwidth:"700px"},
}));

interface ModalDialogueProps {
	dialogOpen: boolean;
	handleDialogClose: any;
	deniedUser?: IRegisterUser;
}

const RegistrationDenyUserDialog = ({
	deniedUser,
	dialogOpen,
	handleDialogClose,
}: ModalDialogueProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const denyUserResponse: IDenyUserResponse = useSelector(
		(state: any) => state.userListState.denyUserResponse
	);
	const descriptionElementRef = React.useRef<HTMLElement>(null);

	const handleSubmitDenyUser = () => {
		if (deniedUser !== undefined) {
			let criteria: IDenyUserCriteria = { userId: deniedUser.id };
			dispatch(denyUserAction(criteria));
		}
	};


	React.useEffect(() => {
		if (dialogOpen) {
			const { current: descriptionElement } = descriptionElementRef;

			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [dialogOpen]);

	React.useEffect(() => {
		if (denyUserResponse) {
			handleDialogClose(false);
		}
	}, [denyUserResponse, dispatch, handleDialogClose]);

	return (
		<div>
			<Dialog
				open={dialogOpen}
				onClose={handleDialogClose}
				fullWidth
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
				classes={{ paper: classes.paper}}
			>
				<DialogTitle className="cmn-dialogtitle" id="scroll-dialog-title">
					Deny Request for New User Account
				</DialogTitle>

				<DialogContent dividers={true}>
					<DialogContentText
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
						className="cmn-dialogtext"
					>
						<Typography
							align="center"
							style={{ wordWrap: "break-word" }}
							color="black"
							component="span"
							className="cmn-dialogtext"
						>
							This will deny the user's registration request.
							<br />
							<br />
							They will not be able to login to the system or
							submit claims.
						</Typography>
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button color="inherit" className="cmn-secbtn"  size="small"  onClick={handleDialogClose}>Cancel</Button>
					<Button
						onClick={handleSubmitDenyUser}
						color="primary"
						size="small"
                        variant="contained"
						className="btn-primary" 
					>
						Deny
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default RegistrationDenyUserDialog;
