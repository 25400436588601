import { SubmissionVisisbility, DocumentType } from "../../types/enums";
import { ISubmissionListItem } from "../../types/types";

export const submissionStates = [
    { "label": "Started", "value": '12', "order": 1 },
    { "label": "In Process", "value": '1', "order": 2 },
    { "label": "Error", "value": '8', "order": 3 },
    { "label": "Partially Loaded", "value": '11', "order": 4 },
    { "label": "Rejected", "value": '9', "order": 5 },
    { "label": "Loaded", "value": '7', "order": 6 },
    { "label": "Deficient", "value": '13', "order": 7 }
];

export const iconMap: any = {
    "submitted": "CheckCircleOutline",
    "extracted": "CheckCircleOutline",
    "extracting": "AccessTime",
    "verifying": "AccessTime",
    "verified": "CheckCircleOutline",
    "loading": "AccessTime",
    "loaded": "CheckCircleOutline",
    "error": "ErrorOutline",
    "rejected": "Cancel",
    "partially extracted": "CheckCircleOutline",
    "partially loaded": "PercentIcon",
    "started": "SendIcon",
    "pending": "PendingActionsIcon",
    "in process": "CheckCircleOutline",
    "deficient": "ErrorOutline"
};

export const submissionDocumentType = [
    { "label": "Template", "value": DocumentType.Template, isInternal: true },
    { "label": "Submission", "value": DocumentType.Submission, isInternal: false },
    { "label": "Return Submission", "value": DocumentType.ReturnSubmission, isInternal: true },
    { "label": "Proof Of Claim", "value": DocumentType.ProofofClaim, isInternal: false },
    { "label": "Signature Authorization", "value": DocumentType.SignatureAuthorization, isInternal: false },
    { "label": "Attestation Document", "value": DocumentType.AttestationDocument, isInternal: false },
    { "label": "Data Verification", "value": DocumentType.DataVerification, isInternal: false },
    { "label": "Other", "value": DocumentType.Other, isInternal: true },
    { "label": "Defect Workbook", "value": DocumentType.DeficiencyWorkbook, isInternal: true },
    { "label": "Defect Response", "value": DocumentType.DeficiencyResponse, isInternal: false },
    { "label": "Defect Notice", "value": DocumentType.DeficiencyNotice, isInternal: true },
    { "label": "Consolidated Supporting Documents", "value": DocumentType.ConsolidatedSupportingDocuments, isInternal: false }
];

export const filterSubmissions = (submissions: ISubmissionListItem[], isNominee: boolean) => {
    let submissionsToDisplay: ISubmissionListItem[] = [];

    submissions.forEach((item) => {
        if (item.submissionVisibilityId === SubmissionVisisbility.EveryOne)
            submissionsToDisplay.push(item);

        if (item.submissionVisibilityId === SubmissionVisisbility.EpiqOnly && !isNominee)
            submissionsToDisplay.push(item);
    });

    return submissionsToDisplay;
}