import { Link as MuiLink, Fab, Theme, Button, Backdrop, Stack, CircularProgress } from '@mui/material';
import { IStringValue } from '../../types/types';
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import authService from '../../components/api-authorization/AuthorizeService';
import { Strings, TermsOfUseTitle } from '../../types/enums';

const useStyles = makeStyles((theme: Theme) => ({
    textLink: {
        color: theme.palette.text.primary,
        padding: '0.5em'
    },
    commonbackgroundcard: {
        boxShadow: '0px 10px 15px -3px rgb(0 0 0 / 30%)',
        padding: '1.5rem 1rem 0.25rem',
        margin: '1rem 1rem 0 0',
        background: '#fff',
        borderRadius: '5px',
	},
	title: {
		flexGrow: 1,
		marginBottom: '15px',
	},
	floatLeft: {
		float: 'left'
	},
	floatRight: {
		float: 'right',
	},
	headOuter: {
		display: 'inline-block',
		width: '100%',
	},
	disabled: {
		display: 'none'
	},
}));

interface ITermsOfUseProps {
    message: string;
}

const TermsOfUsePage = ({message}: ITermsOfUseProps) => {
    const dispatch = useDispatch();
	const classes = useStyles();
    const [saveLaterText, setSaveLaterText] = useState<string>("");
    const [stringContentData, setStringContentData] = useState<IStringValue[]>();
    document.title = TermsOfUseTitle.Title;

    useEffect(() => {

        if (!stringContentData)
            GetStringValue();

    }, []);
    

    const GetStringValue = async (): Promise<void> => {
        const token = await authService.getAccessToken();
        const criteria = [Strings.SubmissionDocsNotSubmitted,Strings.TermsOfService];
        await fetch(`api/Common/GetStringsByKeys`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(criteria)
        })
            .then((response) => {
                const contentType = response.headers.get('content-type');

                if (!contentType || !contentType.includes('application/json')) {
                    throw new TypeError("Oops, we haven't got JSON!");
                }

                return response.json();
            })
            .then((data) => {
                setStringContentData(data);
                if (data !== null) {
                    data?.map((content: IStringValue) => {
                        if (content.key === Strings.TermsOfService) {
                            setSaveLaterText(content.value);
                        }
                    });
        
                }
            })
            .catch((error) => console.error(error));
    }

    return (
        
        <div className={classes.commonbackgroundcard}>
            <div className={classes.headOuter}>
            <div className={classes.floatLeft}>
					<Typography variant="h6" className={classes.title} noWrap>
						Terms Of Use
					</Typography>
				</div>
            </div>
            {saveLaterText && (
            <Typography style={{ whiteSpace: 'normal' }} className={classes.title} dangerouslySetInnerHTML={{ __html: saveLaterText }}></Typography>
            )}
        </div>

    );
};

export default TermsOfUsePage;