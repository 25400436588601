import { ITemplate } from "../../types/types";

export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAILED = 'GET_TEMPLATES_FAILED';

export const GET_TEMPLATE_DETAIL = 'GET_TEMPLATE_DETAIL';
export const GET_TEMPLATE_DETAIL_SUCCESS = 'GET_TEMPLATE_DETAIL_SUCCESS';
export const GET_TEMPLATE_DETAIL_FAILED = 'GET_TEMPLATE_DETAIL_FAILED';

export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const SAVE_TEMPLATE_SUCCESS = 'SAVE_TEMPLATE_SUCCESS';
export const SAVE_TEMPLATE_FAILED = 'SAVE_TEMPLATE_FAILED';

export const getTemplatesAction = (payload: any) => ({
	type: GET_TEMPLATES,
	payload
});

export const getTemplatesSuccess = (payload: ITemplate[]) => ({
	type: GET_TEMPLATES_SUCCESS,
	payload
});

export const getTemplatesFailed = (payload: any) => ({
	type: GET_TEMPLATES_FAILED,
	payload
});

export const getTemplateDetailAction = (payload: { templateId: number }) => ({
	type: GET_TEMPLATE_DETAIL,
	payload
});

export const getTemplateDetailSuccess = (payload: ITemplate) => ({
	type: GET_TEMPLATE_DETAIL_SUCCESS,
	payload
});

export const getTemplateDetailFailed = (payload: any) => ({
	type: GET_TEMPLATE_DETAIL_FAILED,
	payload
});

export const saveTemplateAction = (payload: any) => ({
	type: SAVE_TEMPLATE,
	payload
});

export const saveTemplateSuccess = (payload: any) => ({
	type: SAVE_TEMPLATE_SUCCESS,
	payload
});

export const saveTemplateFailed = (payload: any) => ({
	type: SAVE_TEMPLATE_FAILED,
	payload
});