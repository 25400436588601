import {
	IClaimantListState,
	IAction
} from '../../types/types';
import {
	GET_CLAIMANTS_FOR_SUBMISSION,
	GET_CLAIMANTS_FOR_SUBMISSION_SUCCESS,
	GET_CLAIMANTS_FOR_SUBMISSION_FAILED
} from './claimants.actions';
import { DEFAULT_ROWS_PER_PAGE } from '../../constants/CommonConstants';

const initialState: IClaimantListState = {
	isLoading: false,
	isLoaded: false,
	claimants: [],
    totalRowCount: 0,
	pageNumber: 1,
	rowsPerPage: DEFAULT_ROWS_PER_PAGE,
	error: null
}

const handleAction = (state = initialState, { type, payload }: IAction) => {
	switch (type) {
		case 	GET_CLAIMANTS_FOR_SUBMISSION:
			return {
				...state,
				isLoading: true,
				isLoaded: false,
				claimants: [],
				pageNumber: payload.pageNumber,
				rowsPerPage: payload.rowsPerPage,
				error: null
			}
		case GET_CLAIMANTS_FOR_SUBMISSION_SUCCESS:
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				claimants: payload.claimants,
				totalRowCount: payload.totalRowCount
			}
		case GET_CLAIMANTS_FOR_SUBMISSION_FAILED:
			return {
				...state,
				isLoading: false,
				isLoaded: true,
				error: payload
			}
		default:
			return state;
	}
}

export default handleAction;