import { IAdminCardCountsState, IAlert, ICaseState, ICasebookState, IClaimantListState, IInstitutionListState, ITemplateState, INomineeCardCountsState, ISubmissionListState, IUserListState , ICmxState} from '../types/types';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import caseState from '../modules/Cases/cases.reducer';
import templateState from '../modules/Templates/template.reducer';
import settings, { ISettingsState } from '../modules/Settings/settings.reducer';
import { History } from 'history';
import alerts from '../modules/Alerts/alerts.reducer';
import caseBookState from '../modules/Casebook/casebook.reducer';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga';
import userListState from '../modules/Users/userlist.reducer';
import userState from '../modules/User/user.reducer';
import institutionListState from '../modules/Institutions/institutionlist.reducer';
import submissionListState from '../modules/Submissions/submissions.reducer';
import claimantListState from '../modules/Claimants/claimants.reducer';
import cardCountsState from '../modules/Dashboard/dashboard.reducer';
import adminCardCountsState from '../modules/Admin/admin.reducer';
import claimFileState from '../modules/ClaimFileUpload/claimFile.reducer';
import cmxState from '../modules/Cmx/cmx.reducer';


export interface ApplicationState {
	settings: ISettingsState | undefined;
	cases: ICaseState | undefined;
	templateState: ITemplateState | undefined;
	casebook: ICasebookState | undefined;
	alerts: IAlert | undefined;
	userListState: IUserListState | undefined;
	institutionListState: IInstitutionListState | undefined;
	submissionListState: ISubmissionListState | undefined;
	claimantListState: IClaimantListState | undefined;
	cardCountsState: INomineeCardCountsState | undefined;
	adminCardCountsState: IAdminCardCountsState | undefined;
	cmxState: ICmxState | undefined;
}

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(history: History, initialState?: ApplicationState) {
	const middleware = [
		sagaMiddleware
	];

	const rootReducer = combineReducers({
		settings,
		caseState,
		templateState,
		caseBookState,
		alerts,
		userState,
		userListState,
		institutionListState,
		submissionListState,
		claimantListState,
		cardCountsState,
		adminCardCountsState,
		claimFileState,
		cmxState
	});

	const enhancers = [];
	const windowIfDefined = typeof window === 'undefined' ? null : window as any;

	if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__)
		enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());

	const store = createStore(
		rootReducer,
		initialState,
		compose(applyMiddleware(...middleware), ...enhancers)
	);

	sagaMiddleware.run(rootSaga);

	return store;
}