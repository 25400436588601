export const GET_CLAIMANTS_FOR_SUBMISSION = 'GET_CLAIMANTS_FOR_SUBMISSION';
export const GET_CLAIMANTS_FOR_SUBMISSION_SUCCESS = 'GET_CLAIMANTS_FOR_SUBMISSION_SUCCESS';
export const GET_CLAIMANTS_FOR_SUBMISSION_FAILED = 'GET_CLAIMANTS_FOR_SUBMISSION_FAILED';
export const EXPORT_SUBMISSION_CLAIMANTS = 'EXPORT_SUBMISSION_CLAIMANTS';
export const EXPORT_SUBMISSION_CLAIMANTS_SUCCESS = 'EXPORT_SUBMISSION_CLAIMANTS_SUCCESS';
export const EXPORT_SUBMISSION_CLAIMANTS_FAILED = 'EXPORT_SUBMISSION_CLAIMANTS_FAILED';


export const getClaimantsForSubmissionAction = (payload: any) => ({
	type: GET_CLAIMANTS_FOR_SUBMISSION,
	payload
});

export const getClaimantsForSubmissionSuccess = (payload: any) => ({
	type: GET_CLAIMANTS_FOR_SUBMISSION_SUCCESS,
	payload
});

export const getClaimantsForSubmissionFailed = (payload: any) => ({
	type: GET_CLAIMANTS_FOR_SUBMISSION_FAILED,
	payload
});

export const exportSubmissionClaimantsAction = (payload: any) => ({
	type: EXPORT_SUBMISSION_CLAIMANTS,
	payload
});

export const exportSubmissionClaimantsSuccess = (payload: any) => ({
	type: EXPORT_SUBMISSION_CLAIMANTS_SUCCESS,
	payload
});

export const exportSubmissionClaimantsFailed = (payload: any) => ({
	type: EXPORT_SUBMISSION_CLAIMANTS_FAILED,
	payload
});
