import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './store/configureStore';
import App from './App';
import UserProvider from './modules/User/UserContext';
import ReactGA from "react-ga4";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const getGAkey = async () => {
    try {
        let response = await fetch(`api/Common/GetGAKey`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok)
            throw new Error(response.statusText);

        return await response.text();
    }

    catch (exception) {
        console.error("Error getting GAKey." + exception);        
    }

    return '';
};

    async function test() {
        let gakey = await getGAkey();
        ReactGA.initialize(gakey);
    }
    test();


const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename={baseUrl}>
            <UserProvider>
                <App />
            </UserProvider>
        </BrowserRouter>
    </Provider>,
    rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();