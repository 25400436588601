const institutionTypes = [
    'Broker', 
    'Bank', 
    'Law Firm', 
    'Third Party', 
    'Investment Manager', 
    'Trust Company', 
    'Other' 	
];

export const getInstitutionTypeName = (id: number): string => {
    if (id < 1 || id > institutionTypes.length){
        return '';
    }

    return institutionTypes[id-1];
}
