import { IUserListState, IRegisterUser, IRoles, IStringValue } from "../../types/types";
import React, { useContext, useEffect, useState, useRef } from "react";
import { Theme, Typography, Button } from "@mui/material";
import { UserContext, UserContextType } from "../../modules/User/UserContext";
import { clearApproveDenyUserResponseAction, getApproveUserContent, getRolesList } from "./userlist.actions";
import { useDispatch, useSelector } from "react-redux";
import RegistrationDenyUserDialog from "./RegistrationDenyUserDialog";
import RegistrationRequestsGrid from "./RegistrationRequestsGrid";
import ScrollDialog from "../Users/RegistrationUserApproveModal";
import makeStyles from "@mui/styles/makeStyles";
import ManageUserRolesModal from "./ManageUserRolesModal";
import { RegistrationRequestsPageTitle, Strings } from "../../types/enums";
import { getInfoJsonWithArray, transformHelpUrl } from "../../utilities/utilities";
import { TECHNICAL_ERROR_MESSAGE } from "../../constants/CommonConstants";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import authService from '../../components/api-authorization/AuthorizeService';
import { EpiqUser, Nominee } from '../../constants/roles';

const useStyles = makeStyles((theme: Theme) => ({
	fab: {
		position: "absolute",
		bottom: theme.spacing(5),
		right: theme.spacing(5),
	},
	commonbackgroundcard: {
		boxShadow: "0px 10px 15px -3px rgb(0 0 0 / 30%)",
		padding: "1.5rem 1rem",
		margin: "2rem 1rem 0",
		background: "#fff",
		borderRadius: "5px",
	},
	title: {
		flexGrow: 1,
		marginBottom: "15px",
		display: 'inline-flex',
		marginRight: "5px"
	},
	floatLeft: {
		float: 'left'
	},
	floatRight: {
		float: 'right',
	},
	headOuter: {
		display: 'inline-block',
		width: '100%',
	},
	disabled: {
		display: 'none'
	},
	dashboardIconBackground: {
		fontSize: "20px"
	}
}));

const RegistrationRequestsPage = () => {
	const { user, isInitialized, isAuthenticated, isInRole } = useContext(UserContext) as UserContextType;
	const dispatch = useDispatch();
	const classes = useStyles();

	const userListState: IUserListState = useSelector(
		(state: any) => state.userListState
	);

	let [selectedUser, setSelectedUser] = useState<IRegisterUser>();
	const [approveDialogOpen, setApproveDialogOpen] = React.useState(false);
	const [denyDialogOpen, setDenyDialogOpen] = React.useState(false);
	const [mangeRolesDialogOpen, setMangeRolesDialogOpen] = React.useState(false);

	let [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const ref = useRef(null);
	const [stringContentData, setStringContentData] = useState<IStringValue | null>();
	const [adminRegistrationRequest, setAdminRegistrationRequest] = useState<string>("");

	const text =
		"Please confirm the approval of this user.This approval will allow the user to submit claims on behalf of";
	let institutionId = 0;
	let isCreateNewInstitution = false;
	let address: any = null;
	var body,
		title = "";
	document.title = RegistrationRequestsPageTitle.Title;


	const approveUser = (id: number) => {
		const selectedUser = userListState.userList.find((x) => x.id === id);

		if (selectedUser) {
			setSelectedUser(selectedUser);
			dispatch(getApproveUserContent(selectedUser!.registrationId));
			setApproveDialogOpen(true);
		}

	};

	const manageUserRoles = (id: number) => {
		const selectedUser = userListState.userList.find((x) => x.id === id);

		if (selectedUser) {
			setSelectedUser(selectedUser);

			userListState.rolesList.forEach(function (role: IRoles) {
				role.isChecked = role.isEpiqDefault ? true : false;
				role.disabled = role.isEpiqDefault ? true : false;

				selectedUser?.roles?.forEach(function (userrole) {

					if (userrole === role.id) {
						role.isChecked = true;

						if (userrole.toLowerCase() === "epiqadmin".toLowerCase() && selectedUser.email === user.email)
							role.disabled = true;

						return;
					}

				});

			});

			setMangeRolesDialogOpen(true);
		}
	}

	const denyUser = (id: number) => {
		const selectedUser = userListState.userList.find((x) => x.id === id);

		if (selectedUser) {
			setSelectedUser(selectedUser);
			setDenyDialogOpen(true);
		}

	};

	const handleMangeRolesDialogClose = () => {
		setMangeRolesDialogOpen(false);
	};

	const handleApproveDialogClose = () => {
		setApproveDialogOpen(false);
		dispatch(clearApproveDenyUserResponseAction());
	};

	const handleDenyDialogClose = () => {
		setDenyDialogOpen(false);
		dispatch(clearApproveDenyUserResponseAction());
	};

	if (selectedUser) {

		if (
			userListState.institution !== undefined &&
			userListState.institution.id !== 0
		)
			address = (
				<address>
					<b> {userListState.institution.name} </b> <br />
					{userListState.institution.address1} <br />
					{(userListState.institution.address2 !== "") ? <>{userListState.institution.address2}<br /></> : ``}
					{(userListState.institution.address3 !== "") ? <>{userListState.institution.address3}<br /></> : ``}
					{userListState.institution.city},  {userListState.institution.stateProvince},  {userListState.institution.zipCode} <br />
					{userListState.institution.country} <br />
				</address>
			);
		else
			address = (
				<address>
					<b> {selectedUser.registrationInstitutionName} </b> <br />
					{selectedUser.registration.address1} <br />
					{(selectedUser.registration.address2 !== "") ? <>{selectedUser.registration.address2} <br /></> : ``}
					{(selectedUser.registration.address3 !== "") ? <>{selectedUser.registration.address3}<br /></> : ``}
					{selectedUser.registration.city},  {selectedUser.registration.stateProvince},  {selectedUser.registration.zipCode}  <br />
					{selectedUser.registration.country} <br />
				</address>
			);

		institutionId = userListState.institution
			? userListState.institution.id
			: 0;
		isCreateNewInstitution = institutionId === 0 ? true : false;
		body = text;
		title =
			userListState.institution !== undefined &&
				userListState.institution.id !== 0
				? "Approve User and Assign to Institution"
				: "Approve User and Create Institution";
	}

	const handleClick = (event: any) => {
		setShow(!show);
		setTarget(event.target);
	};

	const processInfoResponse = (data: any) => {
		data.map((item: IStringValue) => {
			switch (item.key) {
				case Strings.AdminRegistrationRequest:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setStringContentData(item);
					break;


				default:
					break;
			}
		});
	};

	useEffect(() => {
		const fetchInfoStrings = async () => {
			const data: any = await getInfoJsonWithArray([
				Strings.AdminRegistrationRequest,
			]);

			processInfoResponse(data);
		};


		if (isInitialized && isAuthenticated) {
			fetchInfoStrings();
		}
	}, [isAuthenticated, isInitialized]);

	return (
		<div className={classes.commonbackgroundcard}>
			
			<div className={classes.floatLeft} ref={ref}>
			<Typography variant="h6" className={classes.title} noWrap>
				Registration Requests
			</Typography>
					<Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent:"left", minWidth:"25px" }} onClick={handleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} /></Button>
					<Overlay
						show={show}
						target={target}
						placement="right-start"
						container={ref}
						containerPadding={20}
						rootClose
						onHide={() => setShow(false)}
					>
						<Popover id="popover-contained">
							<Popover.Header as="h3"><span style={{ color: "#000" }}>Registration Request</span></Popover.Header>
							{stringContentData && (
							<Popover.Body dangerouslySetInnerHTML={{ __html: stringContentData.value }}></Popover.Body>
							)}
						</Popover>
					</Overlay>
					
				</div>

			<RegistrationRequestsGrid
				approveUser={approveUser}
				denyUser={denyUser}
				needsRefresh={userListState.needsRefresh}
				manageUserRoles={manageUserRoles}
			/>


			{userListState.isLoading === false && userListState.error ===  null && (
				<>
					<ScrollDialog
						AssociateInstitutionId={institutionId}
						isCreateNewInstitution={isCreateNewInstitution}
						insertedBy={-1}
						selectedUser={selectedUser}
						title={title}
						body={body}
						Address={address}
						modalOpen={approveDialogOpen}
						handleClose={handleApproveDialogClose}
					/>

					<RegistrationDenyUserDialog
						deniedUser={selectedUser}
						dialogOpen={denyDialogOpen}
						handleDialogClose={handleDenyDialogClose}
					/>

					{userListState?.rolesList?.length > 0 && <ManageUserRolesModal
						selectedUser={selectedUser}
						modalOpen={mangeRolesDialogOpen}
						handleClose={handleMangeRolesDialogClose}
						roles={userListState.rolesList}
					/>}


				</>
			)}

			{userListState.error && <p style={{ color: 'red' }}>{TECHNICAL_ERROR_MESSAGE}</p>}
		</div>
	);
};

export default RegistrationRequestsPage;