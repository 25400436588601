import authService from '../components/api-authorization/AuthorizeService';
import { getSettings } from '../modules/Settings/settings.api';
import { IStringValue } from '../types/types';

export function formatUSZipCode(zip: string): string {
    let cleanedZip = zip.trim();

    // Check if the zip code contains any non-digit characters
    if (/\D/.test(cleanedZip)) {
        return zip; // Return the original input if it contains non-digit characters
    }

    // Format the cleaned zip code
    if (cleanedZip.length > 5) {
        return cleanedZip.slice(0, 5) + '-' + cleanedZip.slice(5);
    } else {
        return zip; // Return the original input if not a valid zip code format
    }
}

export function formatPhoneNumber(phone: string | number | undefined): string {
    if (!phone) {
        return '';
    }

    let cleanedPhone = phone.toString().trim();

    // Check if the phone code contains any non-digit characters
    if (/\D/.test(cleanedPhone)) {
        return phone.toString(); // Return the original input if it contains non-digit characters
    }

    // Format the cleaned phone code
    if (cleanedPhone.length != 10) {
        return cleanedPhone;
    }

    return cleanedPhone.slice(0, 3) + '-' + cleanedPhone.slice(3, 6) + '-' + cleanedPhone.slice(6, 10);
}

export const convertUTCToLocalTime = (dateString: string | number | Date) => {
    let date = new Date(dateString);
    let minDate = new Date("0001-01-01T00:00:00");

    if (date.toLocaleString() === minDate.toLocaleString())
        return "";

    const milliseconds = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    );
    const localTime = new Date(milliseconds);
    return localTime.toLocaleString('en-US', { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).toUpperCase();
};

export const convertUTCToLocalDate = (dateString: string | number | Date) => {
    let date = new Date(dateString);
    let minDate = new Date("0001-01-01T00:00:00");

    if (date.toLocaleString() === minDate.toLocaleString())
        return "";

    const milliseconds = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    );

    const localTime = new Date(milliseconds);
    return localTime.toLocaleString('en-US', { day: '2-digit', month: '2-digit', year: '2-digit' }).toUpperCase();
};

export const convertToDate = (dateString: string | number | Date) => {
    let date = new Date(dateString);
    let minDate = new Date("0001-01-01T00:00:00");

    if (date.toLocaleString() === minDate.toLocaleString())
        return "";

    return date.toLocaleString('en-US', { day: '2-digit', month: '2-digit', year: '2-digit' }).toUpperCase();
};

export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : 0;
};

export function padLeft(number: number, length: number, character: string) {
    var result = String(number);

    for (var i = result.length; i < length; ++i) {
        result = character + result;
    }

    return result;
};

export function format12hourDateTime(datetime: Date): string {
    let month = padLeft(datetime.getMonth() + 1, 2, '0');
    let day = padLeft(datetime.getDate(), 2, '0');
    let year = padLeft(datetime.getFullYear(), 2, '0');
    let hours = ((datetime.getHours() + 11) % 12 + 1);
    let minutes = padLeft(datetime.getMinutes(), 2, '0');
    let seconds = padLeft(datetime.getSeconds(), 2, '0');
    let meridian = datetime.getHours() >= 12 ? 'PM' : 'AM';

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds} ${meridian}`
}

export const callGetStringsByKeys = async (criteria: string): Promise<IStringValue[] | null> => {
    const token = await authService.getAccessToken();

    let response = await fetch(`api/Common/GetStringsByKeys`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: criteria,
    });

    if (!response.ok) {

        throw new Error("Error calling GetStringsByKeys");

    }

    return response.json()
}

export const getInfoJsonWithArray = async (criteria: string[]): Promise<IStringValue[] | null> => {
    let response = await callGetStringsByKeys(JSON.stringify(criteria))

    return response;
}

export const GetStringValue = async (key: string): Promise<IStringValue | null> => {
    let response = await callGetStringsByKeys(JSON.stringify([key]))

    let returnValue: IStringValue | null = null;

    if (response) {
        response.map((content: IStringValue) => {
            switch (content.key) {
                case key:
                    returnValue = content;
                    break;
                default:
                    break;
            }

        });
    }

    return returnValue;
}


export const transformHelpUrl = (helpURL: string, isInternalUser: boolean): string => {

    if (isInternalUser) {
        helpURL = helpURL.replace("{{additionalQueryParameters}}", '?f=Audience%3AInternal')
    }

    else {
        helpURL = helpURL.replace("{{additionalQueryParameters}}", '?f=Audience%3AInternal%20!')
    }

    return helpURL;

}

export const createEpiqFilingTokenCookie = async (): Promise<void> => {
    const token = await authService.getAccessToken();
    const hostname = window.location.hostname;
    const domain = '.' + hostname.split('.').slice(-2).join('.');
    const theCookie = `epiqFilingToken=${token};domain=${domain};path=/`;
    document.cookie = theCookie;
}

export const deleteEpiqFilingTokenCookie = async (): Promise<void> => {
    const now = new Date();
    const time = now.getTime();
    const settings: any = await getClientSettings();
    const expireTime = time - parseInt(settings.sessionTimeOutMilliSeconds);
    now.setTime(expireTime);

    const hostname = window.location.hostname;
    const domain = '.' + hostname.split('.').slice(-2).join('.');
    const theCookie = `epiqFilingToken=;expires=` + now.toUTCString() + `;domain=${domain};path=/`;

    document.cookie = theCookie;
}

function getUTCTimeString() {
    const now = new Date();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const year = now.getUTCFullYear();
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');

    return `${month}${day}${year}${hours}${minutes}${seconds}`;
}

export const getEncryptedCasebookApiKey = async (): Promise<string> => {
    const settings: any = await getClientSettings();
    const CryptoJS = require('crypto-js');
    const theTime = getUTCTimeString();
    const apiKey = `{"apiKey": "${settings.casebookApiKey}", "time": "${theTime}"}`
    const publicKey = settings.casebookApiPublicKey;

    var encryptedKey = CryptoJS.AES.encrypt(apiKey, CryptoJS.enc.Utf8.parse(publicKey), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    }).toString();

    return encryptedKey;
}

export async function getClientSettings(): Promise<any> {
    return process.env.NODE_ENV.localeCompare('development', undefined, { sensitivity: 'base' }) === 0
        ? {
            'casebookApiBaseURL': process.env.REACT_APP_CASEBOOKAPIBASEURL,
            'helpBaseUrl': process.env.REACT_APP_HELPBASEURL,
            'sessionTimeOutMilliSeconds': process.env.REACT_APP_SESSION_TIMEOUT_MILLISECONDS,
            'casebookApiPublicKey': process.env.REACT_APP_CASEBOOKAPIPUBLICKEY,
            'casebookApiKey': process.env.REACT_APP_CASEBOOKAPIKEY,
        }
        : await getSettings();
}