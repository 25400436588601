import { all, call, put, take, takeEvery, takeLeading } from 'redux-saga/effects';
import { IAction, IClaimFileUploadResponse, IConfiguration, ISupportingDocTypeProps, IResponse, ICaseDocumentTypeCriteria } from '../../types/types';
import { AlertType } from '../../types/enums';
import { showAlert } from '../Alerts/alerts.actions';
import {
	uploadClaimFileSuccessAction, uploadClaimFileFailedAction, UPLOAD_CLAIMFILE, uploadProgressAction,
	PUBLISH_SUBMISSION_MESSAGE, publishSubmissionMessageSuccessAction, publishSubmissionMessageFailedAction,
	DELETE_SUBMISSION, deleteSubmissionSuccessAction, deleteSubmissionFailedAction,
	GET_CASE_DOCUMENT_TYPES, getCaseDocumentTypesSuccessAction, getCaseDocumentTypesFailedAction,
	GET_SUPPORT_EMAIL_ADDRESS, getSupportEmailAddressSuccessAction, getSupportEmailAddressFailedAction,
	GET_CLAIM_ACKNOWLEDGEMENT_TEXT, getClaimAcknowledgementTextSuccessAction, getClaimAcknowledgementTextFailedAction,
	DELETE_DOCUMENT, deleteDocumentsSuccessAction, deleteDocumentsFailedAction,
	UPLOAD_SUPPORTING_DOC_FILE, uploadSupportingDocFileSuccessAction, uploadSupportingDocFileFailedAction, uploadSupportingDocFileProgressAction 
	}
from './claimFile.actions';
import {
	createUploadFileChannel,
	createUploadSupportingDocFileChannelAsync,
	publishSubmissionMessageAsync,
	deleteSubmissionByIdAsync,
	getCaseDocumentTypesAsync, 
	getConfigurationValueAsync,
	deleteDocumentsByIdListAsync,
	getStringContentAsync
	}
from './claimFile.api';

function* uploadClaimFileSaga(action: IAction): any {

	const channel = yield call(createUploadFileChannel, action.payload.claimFilePayload);

	try {

		while (true) {
			const { progress = 0, error, success, message, submissionId, submissionDescription } = yield take(channel);

			if (error) {
				const claimFileUploadResponse: IClaimFileUploadResponse = { success: false, message: error.message }
				yield put(uploadClaimFileFailedAction(claimFileUploadResponse));
				yield put(showAlert({
					hidden: false,
					message: error.message,
					severity: AlertType.error
				}));
				return;
			}

			if (success) {
				const claimFileUploadResponse: IClaimFileUploadResponse = { success: true, message: message, submissionId: submissionId, submissionDescription: submissionDescription }
				yield put(uploadClaimFileSuccessAction(claimFileUploadResponse));
				yield put(showAlert({
					hidden: false,
					message: claimFileUploadResponse.message,
					severity: claimFileUploadResponse.success
						? AlertType.success
						: AlertType.error
				}));
				return;
			}

			yield put(uploadProgressAction(action.payload.claimFile, progress));

		}

	}

	catch (error: any) {

		const claimFileUploadResponse: IClaimFileUploadResponse = { success: false, message: error.message }
		yield put(uploadClaimFileFailedAction(claimFileUploadResponse));

		yield put(showAlert({
			hidden: false,
			message: error.message,
			severity: AlertType.error
		}));

	}

}

function* uploadClaimFileWatcher() {
	yield takeLeading(UPLOAD_CLAIMFILE, uploadClaimFileSaga);
}


function* uploadSupportingDocFileSaga(action: IAction): any {

	const channel = yield call(createUploadSupportingDocFileChannelAsync, action.payload.submissionId, action.payload.documentTypeId, action.payload.file);
	let uploadProps: ISupportingDocTypeProps = action.payload.supportingDocTypeProps;

	try {

		while (true) {

			const { progress = 0, error, success, message } = yield take(channel);

			if (error) {

				const fileUploadResponse: IResponse = { success: false, message: error.message };
				uploadProps.isUploading = false;
				uploadProps.isUploadFailed = true;
				uploadProps.response = fileUploadResponse;

				yield put(uploadSupportingDocFileFailedAction(uploadProps));
				yield put(showAlert({
					hidden: false,
					message: fileUploadResponse.message,
					severity: AlertType.error
				}));
				return;

			}

			if (success) {

				const fileUploadResponse: IResponse = { success: true, message: message };
				uploadProps.isUploading = false;
				uploadProps.isUploaded = true;
				uploadProps.response = fileUploadResponse;
				yield put(uploadSupportingDocFileSuccessAction(uploadProps));
				yield put(showAlert({
					hidden: false,
					message: fileUploadResponse.message,
					severity: fileUploadResponse.success
						? AlertType.success
						: AlertType.error
				}));
				return;

			}

			uploadProps.progress = progress;
			yield put(uploadSupportingDocFileProgressAction(action.payload.file, uploadProps));

		}

	}

	catch (error: any) {

		const fileUploadResponse: IResponse = { success: false, message: error.message };
		uploadProps.response = fileUploadResponse;
		uploadProps.isUploading = false;

		uploadProps.isUploadFailed = true;
		yield put(uploadSupportingDocFileFailedAction(uploadProps));
		yield put(showAlert({
			hidden: false,
			message: error.message,
			severity: AlertType.error
		}));

	}

}

function* uploadSupportingDocFileWatcher() {
	yield takeEvery(UPLOAD_SUPPORTING_DOC_FILE, uploadSupportingDocFileSaga);
}


function* publishSubmissionMessageSaga({ payload: { submissionId } }: any): any {

	try {

			const submissionMessageResponse: IResponse = yield call(publishSubmissionMessageAsync, submissionId);

			if (submissionMessageResponse.success) {

				yield put(publishSubmissionMessageSuccessAction(submissionMessageResponse));

				yield put(showAlert({
					hidden: false,
					message: submissionMessageResponse.message,
					severity: submissionMessageResponse.success
						? AlertType.success
						: AlertType.error
				}));
				return;

			}

			else {

				yield put(publishSubmissionMessageFailedAction(submissionMessageResponse.message));

				yield put(showAlert({
					hidden: false,
					message: submissionMessageResponse.message,
					severity: AlertType.error
				}));
				return;
			}

	}
	catch (error: any) {

		yield put(publishSubmissionMessageFailedAction(error.message));

		yield put(showAlert({
			hidden: false,
			message: error.message,
			severity: AlertType.error
		}));

	}
}

function* publishSubmissionMessageWatcher() {
	yield takeLeading(PUBLISH_SUBMISSION_MESSAGE, publishSubmissionMessageSaga);
}


function* deleteSubmissionByIdSaga({ payload: { submissionId } }: any): any {

	try {

		const deleteSubmissionResponse = yield call(deleteSubmissionByIdAsync, submissionId);

		if (deleteSubmissionResponse.success) {

			yield put(deleteSubmissionSuccessAction(deleteSubmissionResponse));
			return;

		}

		else {

			yield put(deleteSubmissionFailedAction(deleteSubmissionResponse.message));

			yield put(showAlert({
				hidden: false,
				message: deleteSubmissionResponse.message,
				severity: AlertType.error
			}));

			return;

		}

	}

	catch (error: any) {

		yield put(deleteSubmissionFailedAction(error.message));

		yield put(showAlert({
			hidden: false,
			message: error.message,
			severity: AlertType.error
		}));

	}
}

function* deleteSubmissionByIdWatcher() {
	yield takeLeading(DELETE_SUBMISSION, deleteSubmissionByIdSaga);
}


function* getCaseDocumentTypesSaga({ payload: { caseId, submissionType, includeConsolidatedDocumentsType } }: any): any {

	try {
		let criteria: ICaseDocumentTypeCriteria = {	caseId, submissionType, includeConsolidatedDocumentsType}
		const documentTypesResponse = yield call(getCaseDocumentTypesAsync, criteria);
		yield put(getCaseDocumentTypesSuccessAction(documentTypesResponse));

	}

	catch (error: any) {

		yield put(showAlert({
			hidden: false,
			message: error.message,
			severity: AlertType.error
		}));

		yield put(getCaseDocumentTypesFailedAction(error.message));

	}

}

function* getCaseDocumentTypesWatcher() {
	yield takeLeading(GET_CASE_DOCUMENT_TYPES, getCaseDocumentTypesSaga);
}


function* getEmailSupportAddressSaga({ payload: { configurationKey } }: any): any {

	try {

		const supportEmailAddress: IConfiguration = yield call(getConfigurationValueAsync, configurationKey);

		if (supportEmailAddress.value) {

			yield put(getSupportEmailAddressSuccessAction(supportEmailAddress));

		}

		else {

			const message = "There was a problem retrieving email support address";
			yield put(getSupportEmailAddressFailedAction(message));

			yield put(showAlert({
				hidden: false,
				message: message,
				severity: AlertType.error
			}));

		}

	}

	catch (error: any) {

		yield put(getSupportEmailAddressFailedAction(error.message));

		yield put(showAlert({
			hidden: false,
			message: error.message,
			severity: AlertType.error
		}));

	}

}

function* getEmailSupportAddressWatcher() {
	yield takeLeading(GET_SUPPORT_EMAIL_ADDRESS, getEmailSupportAddressSaga);
}

function* getClaimAcknowledgementTextSaga({ payload: { configurationKey } }: any): any {

	try {

		const claimAcknowledgementText: IConfiguration[] = yield call(getStringContentAsync, configurationKey);

		if (claimAcknowledgementText[0].value) {

			yield put(getClaimAcknowledgementTextSuccessAction(claimAcknowledgementText[0]));

		}

		else { 

			const message = "There was a problem retrieving claimant acknowledgement text";
			yield put(getClaimAcknowledgementTextFailedAction(message));

			yield put(showAlert({
				hidden: false,
				message: message,
				severity: AlertType.error
			}));

        }

	}

	catch (error: any) {

		yield put(getClaimAcknowledgementTextFailedAction(error.message));

		yield put(showAlert({
			hidden: false,
			message: error.message,
			severity: AlertType.error
		}));

	}
}

function* getClaimAcknowledgementTextWatcher() {
	yield takeLeading(GET_CLAIM_ACKNOWLEDGEMENT_TEXT, getClaimAcknowledgementTextSaga);
}


function* deleteDocumentsByIdListSaga({ payload: { documentIds } }: any): any {

	try {

		const response = yield call(deleteDocumentsByIdListAsync, documentIds);

		if (response.success) {

			yield put(deleteDocumentsSuccessAction(response));

			return;

		}

		else {

			yield put(deleteDocumentsFailedAction(response.message));

			yield put(showAlert({
				hidden: false,
				message: response.message,
				severity: AlertType.error
			}));

			return;

		}
	}

	catch (error: any) {

		yield put(deleteDocumentsFailedAction(error.message));

		yield put(showAlert({
			hidden: false,
			message: error.message,
			severity: AlertType.error
		}));

	}
}

function* deleteDocumentsByIdListWatcher() {
	yield takeLeading(DELETE_DOCUMENT, deleteDocumentsByIdListSaga);
}

export default function* claimFileSaga() {
	yield all([
		uploadClaimFileWatcher(),
		publishSubmissionMessageWatcher(),
		deleteSubmissionByIdWatcher(),
		getCaseDocumentTypesWatcher(),
		getEmailSupportAddressWatcher(),
		getClaimAcknowledgementTextWatcher(),
		deleteDocumentsByIdListWatcher(),
		uploadSupportingDocFileWatcher()
	]);
}
