import { IApproveUserCriteria, IDenyUserCriteria, IDeleteUser, IFilterDTO, IRegisterUser, IReviewUserCriteria, IUserCriteria } from "../../types/types";
import { UserListType } from "./../../types/enums";
import authService from "../../components/api-authorization/AuthorizeService";
import { createFilterCriteria } from "../../utilities/filterCriteria";

export const getUserList = async (
	filter: IFilterDTO[],
	userListType: UserListType) => {
	const token = await authService.getAccessToken();
	let criteria = createFilterCriteria(filter) as IUserCriteria;
	criteria.approvedOnEndDate = criteria.approvedOnEndDate?.replace(
		"00:00:00.000Z",
		"23:59:59.000Z"
	);
	criteria.confirmedOnEndDate = criteria.confirmedOnEndDate?.replace(
		"00:00:00.000Z",
		"23:59:59.000Z"
	);
	let userList: IRegisterUser[] = [] as IRegisterUser[];

	if (userListType !== UserListType.all) {
		criteria.isConfirmed = true;

		switch (userListType) {
			case UserListType.approved:
				criteria.isApproved = true;
				break;
			case UserListType.unapproved:
				criteria.isApproved = false;
				break;
		}
	}

	try {

		let response = await fetch(`api/User/UserList`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(criteria),
		});

		if (!response.ok) {

			throw new Error("Failed to get user list.");

		}

		userList = await response.json();

	}

	catch (exception) {

		console.error(exception)
		throw exception;

	}

	return userList;
};

export const getApproveUserContent = async (registrationId: number) => {
	const token = await authService.getAccessToken();

	try {

		let response = await fetch(`api/Institution/GetMatchingInstitutionForRegistration/${registrationId}`,
			{ headers: !token ? {} : { Authorization: `Bearer ${token}` } }
		);

		if (!response.ok) {

			throw new Error("Failed to get approve user content.");

		}

		return response.json();

	}

	catch (exception) {

		console.error(exception)
		throw exception;

	}

};

export const approveUser = async (criteria: IApproveUserCriteria) => {
	const token = await authService.getAccessToken();

	try {
		let response = await fetch(`api/User/ApproveUser/`, {
			method: "POST",
			headers: !token
				? {}
				: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			body: JSON.stringify(criteria),
		});

		if (!response.ok) {

			throw new Error("Failed to approve user.");

		}

		return response.json();

	}

	catch (exception) {

		console.error(exception)
		throw exception;

	}

};

export const denyUser = async (criteria: IDenyUserCriteria) => {
	const token = await authService.getAccessToken();

	try {

		let response = await fetch(`api/User/DenyUser/${criteria.userId}`,
			{ headers: !token ? {} : { Authorization: `Bearer ${token}` } });

		if (!response.ok) {

			throw new Error("Failed to deny user.");

		}

		return response.json();

	}

	catch (exception) {

		console.error(exception)
		throw exception;

	}

};

export const removeUserAccess = async (deleteUserInfo: IDeleteUser) => {
	try {
		const token = await authService.getAccessToken();

		let response = await fetch(`api/User/RemoveUserAccess/${deleteUserInfo.userId}/${deleteUserInfo.deletedByUserId}`, {
			method: 'GET',
			headers: {
				"Authorization": `Bearer ${token}`,
			}
		});

		if (!response.ok)
			throw new Error(response.statusText);

		return response.json();
	} catch (error) {
		console.error(error);
	};
};

export const reviewUser = async (criteria: IReviewUserCriteria) => {
	const token = await authService.getAccessToken();

	try {

		let response = await fetch(`api/User/RegistrationDetail/${criteria.registrationId}`,
			{ headers: !token ? {} : { Authorization: `Bearer ${token}` } });

		if (!response.ok) {

			throw new Error("Failed to get registration detail.");

		}

		return response.json();

	}

	catch (exception) {

		console.error(exception)
		throw exception;

	}

};

export const updateUser = async (user: IRegisterUser) => {
	try {
		const token = await authService.getAccessToken();

		let response = await fetch(`api/User/ModifyUser/`, {
			method: 'POST',
			headers: {
				"Authorization": `Bearer ${token}`,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(user)
		});

		if (!response.ok)
			throw new Error(response.statusText);

		return response.json();
	} catch (error) {
		console.error(error);
	};
};

export const getAllRoles = async (isOnlyEpiqRoles: boolean) => {
	try {
		const token = await authService.getAccessToken();

		let response = await fetch(`api/Roles/GetRoles/${isOnlyEpiqRoles}`, {
			method: 'GET',
			headers: {
				"Authorization": `Bearer ${token}`
			}
		});

		if (!response.ok) {

			throw new Error("Failed to get all roles.");

		}

		return response.json();

	}

	catch (error) {

		console.error(error);
		throw error;

	};

}