import { IFilterDTO, IInstitutionUpdate, IInstitutionCriteria } from '../../types/types';
import authService from '../../components/api-authorization/AuthorizeService';
import { createFilterCriteria } from '../../utilities/filterCriteria';

export const getInstitutionList = async (filter: IFilterDTO[]) => {
    const token = await authService.getAccessToken();
    let criteria = createFilterCriteria(filter) as IInstitutionCriteria;

    try {

        let response = await fetch(`api/Institution/InstitutionList`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(criteria)
        });

        if (!response.ok) {

            throw new Error("Failed to get insititution list.");
            
        }

        return response.json();

    }

    catch (exception) {

        console.error(exception)
        throw exception;

    }

};


export const getInstitutionTypeAsync  = async () => {
    try {
        const token = await authService.getAccessToken();

        let response = await fetch(`api/Institution/GetAllInstitutionTypes`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (!response.ok) {

            throw new Error("Failed to get all insititution types.");

        }

        return response.json();

    }

    catch (exception) {

        console.error(exception)
        throw exception;

    };
}

export const updateInstitutionAsync = async (institution: IInstitutionUpdate) => {

    try {

        const token = await authService.getAccessToken();

        let response = await fetch(`api/institution/saveInstitution`, {
            method: 'POST',
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(institution)
        });

        if (!response.ok) {

            throw new Error("Failed to update insititution.");

        }


        return response.json();

    }

    catch (exception) {

        console.error(exception)
        throw exception;

    };

};

export const getCountriesStatesAsync = async () => {

    try {

        const token = await authService.getAccessToken();

        let response = await fetch(`api/Institution/GetCountriesAndStates`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (!response.ok) {

            throw new Error("Failed to get countries and states list.");

        }

        return response.json();

    }

    catch (exception) {

        console.error(exception)
        throw exception;

    };
}


export const getInstitutionByIdAsync  = async (id: number) => {

    const token = await authService.getAccessToken();

    try {

        let response = await fetch(`api/Institution/details/${id}`, {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
        });

        if (!response.ok) {

            throw new Error("Failed to get insititution by Id.");

        }

        return response.json();

    }

    catch (exception) {

        console.error(exception)
        throw exception;

    }

};