import { SHOW_ALERT, SHOW_ALERT_FAILED, SHOW_ALERT_SUCCESS } from './alerts.actions';
import { IAlert } from '../../types/types';
import { AlertType } from '../../types/enums';

const initialState: IAlert = {
    hidden: true,
    severity: AlertType.info,
    message: ''
}

export default (state = initialState, { type, payload }: any) => {
    switch (type) {
        case SHOW_ALERT:
            return {
                ...state,
                alert: payload
            }
        case SHOW_ALERT_SUCCESS:
            return {
                ...state,
                alert: payload
            }
        case SHOW_ALERT_FAILED:
            return {
                ...state,
                error: payload
            }
        default:
            return state;
    }
}