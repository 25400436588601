import { Theme, Typography, Button } from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { ITemplate, IStringValue } from "../../types/types";
import { AlertType, Strings } from "../../types/enums";
import { showAlert } from "../Alerts/alerts.actions";
import { getTemplateDetailAction } from "./template.actions";
import TemplateDetailForm from "./TemplateDetailForm";
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getInfoJsonWithArray, transformHelpUrl } from "../../utilities/utilities";
import { UserContext, UserContextType } from "../User/UserContext";
import { EpiqUser } from '../../constants/roles';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

const useStyles = makeStyles((theme: Theme) => ({
	commonBackgroundCard: {
		boxShadow: "0px 10px 15px -3px rgb(0 0 0 / 30%)",
		padding: "1.5rem 1rem",
		margin: "1rem 1rem 0 0",
		background: "#fff",
		borderRadius: "5px",
	},
	title: {
		flexGrow: 1,
		marginBottom: "15px",
		display: "inline-flex",
		marginRight: "5px"
	},
	headOuter: {
		display: 'inline-block',
		width: '100%',
	},
	floatLeft: {
		float: 'left'
	},
	floatRight: {
		float: 'right',
	},
	dashboardIconBackground: {
		fontSize: "20px"
	}
}));

export default function TemplateDetailPage() {
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();

	let [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const ref = useRef(null);
	const [stringContentData, setStringContentData] = useState<IStringValue | null>();
	const { isAuthenticated, isInitialized, isInRole } = useContext(UserContext) as UserContextType;
	const { user } = useContext(UserContext) as UserContextType;

	let template: ITemplate = useSelector((state: any) => state.templateState.selectedTemplate);
	let { id } = useParams<{ id: string }>();
	const location = useLocation();

	useEffect(() => {
		const path = location.pathname + location.search;
		ReactGA.send({ hitType: "pageview", page: path, title: "Template Details Page" });
	}, [location]);

	useEffect(() => {
		const templateId = Number(id);

		if (isNaN(templateId)) {
			dispatch(
				showAlert({
					hidden: false,
					message: `The requested template id is invalid.`,
					severity: AlertType.error,
				})
			);

			history.push("/TemplateList");
			return;
		}

		
		dispatch(getTemplateDetailAction({ templateId: templateId }));
	}, [dispatch, id, history]);

	const handleClick = (event: any) => {
		setShow(!show);
		setTarget(event.target);
	};

	const processInfoResponse = (data: any) => {
		data.map((item: IStringValue) => {
			switch (item.key) {
				case Strings.TemplateDetails:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setStringContentData(item);
					break;


				default:
					break;
			}
		});
	};

	useEffect(() => {
		const fetchInfoStrings = async () => {
			const data: any = await getInfoJsonWithArray([
				Strings.TemplateDetails,
			]);

			processInfoResponse(data);
		};


		if (isInitialized && isAuthenticated) {
			fetchInfoStrings();
		}
	}, [isAuthenticated, isInitialized]);

	return (
		<div className={classes.commonBackgroundCard}>
			<div className={classes.headOuter}>
				<div className={classes.floatLeft} ref={ref}>
					<Typography variant="h6" className={classes.title} noWrap>
						Template Details
					</Typography>
					<Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent: "left", minWidth: "25px" }} onClick={handleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} /></Button>
					<Overlay
						show={show}
						target={target}
						placement="right-start"
						container={ref}
						containerPadding={20}
						rootClose
						onHide={() => setShow(false)}
					>
						<Popover id="popover-contained">
							<Popover.Header as="h3"><span style={{ color: "#000" }}>Template Details</span></Popover.Header>
							{stringContentData && (
								<Popover.Body dangerouslySetInnerHTML={{ __html: stringContentData.value }}></Popover.Body>
							)}
						</Popover>
					</Overlay>
				</div>
			</div>
			<TemplateDetailForm template={template} />
		</div>
	);
}
