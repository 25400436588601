import {
	ICase,
	ICaseBookDetails,
	ICaseCriteria,
	IFilterDTO,
	IImportCase,
	ITemplate
} from '../../types/types';

import authService from '../../components/api-authorization/AuthorizeService';
import { createFilterCriteria } from '../../utilities/filterCriteria';
import download from "downloadjs"

const filterCases = (caseList: ICase[], criteria: ICaseCriteria): ICase[] => {
	let filteredCaseList = caseList.filter((item: ICase) => {
		if (criteria.caseNumber) {
			if (!item.number)
				return false;

			if (!(item.number.toUpperCase().indexOf(criteria.caseNumber.toUpperCase()) > -1))
				return false;
		}

		if (criteria.caseName) {
			if (!item.name)
				return false;

			if (!(item.name.toUpperCase().indexOf(criteria.caseName.toUpperCase()) > -1))
				return false;
		}

		if (criteria.classPeriodFrom) {
			if (!item.classPeriodFrom)
				return false;

			criteria.classPeriodFrom = criteria.classPeriodFrom.replace(".000Z", ""); // here we are getting the zone information due to this the date conversion happening to the local timezone. so we are removing the zone part so it shouldn't convert to local time
			let critFrom = new Date(criteria.classPeriodFrom);
			let itemFrom = new Date(item.classPeriodFrom);  //Note: criteria is UTC. make the item match it for comparison

			critFrom.setHours(0o00, 0o00, 0o00, 0o0000001);

			if (!(itemFrom.getTime() >= critFrom.getTime()))
				return false;
		}

		if (criteria.classPeriodTo) {
			if (!item.classPeriodTo)
				return false;

			criteria.classPeriodTo = criteria.classPeriodTo.replace(".000Z", ""); // here we are getting the zone information due to this the date conversion happening to the local timezone. so we are removing the zone part so it shouldn't convert to local time
			let critTo = new Date(criteria.classPeriodTo);
			let itemTo = new Date(item.classPeriodTo);			

			critTo.setHours(23, 59, 59, 9999999);
			
			if (!(itemTo.getTime() <= critTo.getTime()))
				return false;			
		}

		if (criteria.claimsDeadline) {
			if (!item.claimsDeadline)
				return false;

			let critDt = new Date(criteria.claimsDeadline);
			let itemDt = new Date(item.claimsDeadline + '.000Z'); //see above note			

			if (!(itemDt.getTime() <= critDt.getTime()))
				return false;
		}

		if (criteria.isAcceptingSubmissions !== null && criteria.isAcceptingSubmissions !== undefined) {
			if (!(criteria.isAcceptingSubmissions === item.isAcceptingSubmissions))
				return false;
		}

		return true;
	});

	return filteredCaseList;
}


const removePastFilingDeadlines = (caseList: ICase[]): ICase[] => {
	let futureCaseList = caseList.filter((item: ICase) => {

		if (!item.claimsDeadline)
			return false;

		let itemDt = new Date(item.claimsDeadline);
		let today = new Date();
		today.setHours(0, 0, 0, 0);

		if (itemDt.getTime() >= today.getTime())
			return true;

		return false;
	});

	return futureCaseList;
}

export const getCases = async (filter: IFilterDTO[], showPastFilingDeadline: boolean = true) => {
	let criteria = createFilterCriteria(filter) as ICaseCriteria;
	const token = await authService.getAccessToken();
	let cases: ICase[] = [] as ICase[];

	try {

		let response = await fetch(`api/Case/List`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify(criteria)
		});

		if (!response.ok)
			throw new Error(response.statusText);

		cases = await response.json();
		
	}

	catch (exception) {
		console.error("Error getting cases.")
		console.error(exception)
	}

	if (!showPastFilingDeadline)
		cases = removePastFilingDeadlines(cases);

	let filteredCases = filterCases(cases, criteria);
	return filteredCases;
};

export const getCaseById = async (caseId: string) : Promise<ICase> => {
	const token = await authService.getAccessToken();
	let theCase: ICase = {} as ICase

	try {
		let response = (await fetch(`api/Case/${caseId}`,
			{ 
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` } 
			}));

			if (!response.ok) {

				if (response.status >= 400 && response.status <= 499) {
					document.location.href = "/errorPage";
					return theCase;
				}
	
				throw (response);
			}

		theCase = await response.json();
	}

	catch (exception) {
		console.error("Error getting case...")
		console.error(exception)
	}

	return theCase;
};

export const getCaseBookCase = async (caseDetailId: number) => {
	let caseBookDetails: ICaseBookDetails = {} as ICaseBookDetails;
	const casebookBaseURL = localStorage.getItem('casebookApiBaseURL');

	try {
		let response = (await fetch(`${casebookBaseURL}/CaseDetails/${caseDetailId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			}
		})).json();

		caseBookDetails = await response;
	} catch (exception) {
		console.error("Error getting casebook case.")
		console.error(exception)
	}

	return caseBookDetails;
}

export const getCaseTemplate = async (caseId: string): Promise<ITemplate> => {
	const token = await authService.getAccessToken();

	return fetch(`api/Template/CaseId/${caseId}`,
		{ headers: !token ? {} : { 'Authorization': `Bearer ${token}` } })
		.then((response) => response.json())
		.catch((error) => Promise.reject(error));
};

export const importCase = async (theCase: IImportCase) => {
	const token = await authService.getAccessToken();

	return fetch(`api/Case/import`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		},
		body: JSON.stringify(theCase)
	})
		.then((response) => response.json())
		.catch((error) => Promise.reject(error));
}

export const saveCase = async (caseInfo: ICase) => {
	const token = await authService.getAccessToken();

	return fetch(`api/Case`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		},
		body: JSON.stringify(caseInfo)
	})
		.then((response) => response.json())
		.catch((error) => Promise.reject(error));
}

export const downloadCaseTemplate = async (caseId: string) => {
	const token = await authService.getAccessToken();

	let response = await fetch(`api/document/caseTemplate/${caseId}`, {
		method: 'GET',
		headers: {
			"Content-Type": "application/octet-stream",
			'Authorization': `Bearer ${token}`
		}
	});

	if (!response.ok)
		throw new Error(response.statusText);

	let blob = await response.blob();
	let file = new Blob([blob], { type: 'application/octet-stream' });
	let fileName = response.headers.get('Content-Disposition')?.split(';')[1].trim().split('=')[1].replaceAll('"', '');
	download(file, fileName, "application/octet-stream");
};


export const getCaseIdsAcceptingSubmissions = async () => {
	
	try {
		const token = await authService.getAccessToken();

		let response = await fetch(`api/Case/CaseIdsAcceptingSubmissions`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});

		if (!response.ok) {
			throw new Error(response.statusText);
		}

		return response.json();

	}

	catch (error) {
		console.error(error);
		throw error;
	}
};





