export const SHOW_ALERT = 'SHOW_ALERT';
export const SHOW_ALERT_SUCCESS = 'SHOW_ALERT_SUCCESS';
export const SHOW_ALERT_FAILED = 'SHOW_ALERT_FAILED';

export const showAlert = (payload: any) => ({
    type: SHOW_ALERT,
    payload
})

export const showAlertSuccess = (payload: any) => ({
    type: SHOW_ALERT_SUCCESS,
    payload
})

export const showAlertFailed = (payload: any) => ({
    type: SHOW_ALERT_FAILED,
    payload
})