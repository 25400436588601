import { all, call, put, takeLeading } from 'redux-saga/effects';
import { getLoggedInUserSuccess, getLoggedInUserFailed, GET_LOGGED_IN_USER, GET_LOGGED_IN_USER_SUCCESS, GET_LOGGED_IN_USER_FAILED } from './user.actions';
import { getLoggedInUser } from './user.api';
import { IAction } from '../../types/types';

function* getLoggedInUserSaga(action: IAction): any {
    try {
        const user = yield call(getLoggedInUser);
        yield put(getLoggedInUserSuccess(user)); 
    } catch (error: any) {
        yield put(getLoggedInUserFailed(error.message));
    }
}

function* getLoggedInUserWatcher() {
    yield takeLeading(GET_LOGGED_IN_USER, getLoggedInUserSaga);
}

export default function* userSaga() {
    yield all([getLoggedInUserWatcher()]) //only one of these right now. add more as needed
}