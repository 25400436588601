import { SubmissionType } from '../../types/enums';
import { ISupportingDocTypeProps } from '../../types/types';

export const UPLOAD_CLAIMFILE = 'UPLOAD_CLAIMFILE';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const UPLOAD_CLAIMFILE_SUCCESS = 'UPLOAD_CLAIMFILE_SUCCESS';
export const UPLOAD_CLAIMFILE_FAILED = 'UPLOAD_CLAIMFILE_FAILED';
export const CLEAR_CLAIMFILE = 'CLEAR_CLAIMFILE';

export const CLEAR_PUBLISH_SUBMISSION_MESSAGE = 'CLEAR_PUBLISH_SUBMISSION_MESSAGE';
export const PUBLISH_SUBMISSION_MESSAGE = 'PUBLISH_SUBMISSION_MESSAGE';
export const PUBLISH_SUBMISSION_MESSAGE_SUCCESS = 'PUBLISH_SUBMISSION_MESSAGE_SUCCESS';
export const PUBLISH_SUBMISSION_MESSAGE_FAILED = 'PUBLISH_SUBMISSION_MESSAGE_FAILED';

export const CLEAR_DELETE_SUBMISSION = 'CLEAR_DELETE_SUBMISSION';
export const DELETE_SUBMISSION = 'DELETE_SUBMISSION';
export const DELETE_SUBMISSION_SUCCESS = 'DELETE_SUBMISSION_SUCCESS';
export const DELETE_SUBMISSION_FAILED = 'DELETE_SUBMISSION_FAILED';

export const GET_CASE_DOCUMENT_TYPES = 'GET_CASE_DOCUMENT_TYPES';
export const GET_CASE_DOCUMENT_TYPES_SUCCESS = 'GET_CASE_DOCUMENT_TYPES_SUCCESS';
export const GET_CASE_DOCUMENT_TYPES_FAILED = 'GET_CASE_DOCUMENT_TYPES_FAILED';
export const CLEAR_CASE_DOCUMENT_TYPES = 'CLEAR_CASE_DOCUMENT_TYPES';

export const CLEAR_SUPPORT_EMAIL_ADDRESS = 'CLEAR_SUPPORT_EMAIL_ADDRESS';
export const GET_SUPPORT_EMAIL_ADDRESS = 'GET_SUPPORT_EMAIL_ADDRESS';
export const GET_SUPPORT_EMAIL_ADDRESS_SUCCESS = 'GET_SUPPORT_EMAIL_ADDRESS_SUCCESS';
export const GET_SUPPORT_EMAIL_ADDRESS_FAILED = 'GET_SUPPORT_EMAIL_ADDRESS_FAILED';

export const CLEAR_CLAIM_ACKNOWLEDGEMENT_TEXT = 'CLEAR_CLAIM_ACKNOWLEDGEMENT_TEXT';
export const GET_CLAIM_ACKNOWLEDGEMENT_TEXT = 'GET_CLAIM_ACKNOWLEDGEMENT_TEXT';
export const GET_CLAIM_ACKNOWLEDGEMENT_TEXT_SUCCESS = 'GET_CLAIM_ACKNOWLEDGEMENT_TEXT_SUCCESS';
export const GET_CLAIM_ACKNOWLEDGEMENT_TEXT_FAILED = 'GET_CLAIM_ACKNOWLEDGEMENT_TEXT_FAILED';

export const CLEAR_DELETE_DOCUMENT = 'CLEAR_DELETE_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILED = 'DELETE_DOCUMENT_FAILED';

export const SET_SUPPORTING_DOC_TYPE_PROPS = 'SET_SUPPORTING_DOC_TYPE_PROPS'
export const CLEAR_SUPPORTING_DOC_FILE = 'CLEAR_SUPPORTING_DOC_FILE';
export const UPLOAD_SUPPORTING_DOC_FILE = 'UPLOAD_SUPPORTING_DOC_FILE';
export const UPLOAD_SUPPORTING_DOC_FILE_PROGRESS = 'UPLOAD_SUPPORTING_DOC_PROGRESS';
export const UPLOAD_SUPPORTING_DOC_FILE_SUCCESS = 'UPLOAD_SUPPORTING_DOC_SUCCESS';
export const UPLOAD_SUPPORTING_DOC_FILE_FAILED = 'UPLOAD_SUPPORTING_DOC_FAILED';


export const uploadClaimFileAction = (payload: { claimFilePayload: FormData}) => ({
    type: UPLOAD_CLAIMFILE,
    payload
});

export const uploadProgressAction = (claimFile: FormData, progress: number) => ({
    type: UPLOAD_PROGRESS,
    payload: progress,
    meta: { claimFile },
});

export const clearClaimFileAction = () => ({
    type: CLEAR_CLAIMFILE
});

export const uploadClaimFileSuccessAction = (payload: any) => ({
    type: UPLOAD_CLAIMFILE_SUCCESS,
    payload
});

export const uploadClaimFileFailedAction = (payload: any) => ({
    type: UPLOAD_CLAIMFILE_FAILED,
    payload
});

export const clearPublishSubmissionMessageAction = () => ({
    type: CLEAR_PUBLISH_SUBMISSION_MESSAGE
});

export const publishSubmissionMessageAction = (payload: { submissionId: number }) => ({
    type: PUBLISH_SUBMISSION_MESSAGE,
    payload
});

export const publishSubmissionMessageSuccessAction = (payload: any) => ({
    type: PUBLISH_SUBMISSION_MESSAGE_SUCCESS,
    payload
});

export const publishSubmissionMessageFailedAction = (payload: any) => ({
    type: PUBLISH_SUBMISSION_MESSAGE_FAILED,
    payload
});

export const clearDeleteSubmissionAction = () => ({
    type: CLEAR_DELETE_SUBMISSION
});

export const deleteSubmissionAction = (payload: { submissionId: number }) => ({
    type: DELETE_SUBMISSION,
    payload
});

export const deleteSubmissionSuccessAction = (payload: any) => ({
    type: DELETE_SUBMISSION_SUCCESS,
    payload
});

export const deleteSubmissionFailedAction = (payload: any) => ({
    type: DELETE_SUBMISSION_FAILED,
    payload
});

export const getCaseDocumentTypesAction = (payload: { caseId: number, submissionType: SubmissionType, includeConsolidatedDocumentsType: boolean }) => ({
    type: GET_CASE_DOCUMENT_TYPES,
    payload
});

export const getCaseDocumentTypesSuccessAction = (payload: any) => ({
    type: GET_CASE_DOCUMENT_TYPES_SUCCESS,
    payload
});

export const getCaseDocumentTypesFailedAction = (payload: any) => ({
    type: GET_CASE_DOCUMENT_TYPES_FAILED,
    payload
});

export const clearCaseDocumentTypeAction = () => ({
    type: CLEAR_CASE_DOCUMENT_TYPES
});

export const clearSupportEmailAddressAction = () => ({
    type: CLEAR_SUPPORT_EMAIL_ADDRESS
});

export const getSupportEmailAddressAction = (payload: { configurationKey: number }) => ({
    type: GET_SUPPORT_EMAIL_ADDRESS,
    payload
});

export const getSupportEmailAddressSuccessAction = (payload: any) => ({
    type: GET_SUPPORT_EMAIL_ADDRESS_SUCCESS,
    payload
});

export const getSupportEmailAddressFailedAction = (payload: any) => ({
    type: GET_SUPPORT_EMAIL_ADDRESS_FAILED,
    payload
});

export const clearClaimAcknowledgementTextAction = () => ({
    type: CLEAR_CLAIM_ACKNOWLEDGEMENT_TEXT
});

export const getClaimAcknowledgementTextAction = (payload: { configurationKey: string }) => ({
    type: GET_CLAIM_ACKNOWLEDGEMENT_TEXT,
    payload
});

export const getClaimAcknowledgementTextSuccessAction = (payload: any) => ({
    type: GET_CLAIM_ACKNOWLEDGEMENT_TEXT_SUCCESS,
    payload
});

export const getClaimAcknowledgementTextFailedAction = (payload: any) => ({
    type: GET_CLAIM_ACKNOWLEDGEMENT_TEXT_FAILED,
    payload
});

export const clearDeleteDocumentAction = () => ({
    type: CLEAR_DELETE_DOCUMENT
});

export const deleteDocumentsAction = (payload: { documentIds: number[] }) => ({
    type: DELETE_DOCUMENT,
    payload
});

export const deleteDocumentsSuccessAction = (payload: any) => ({
    type: DELETE_DOCUMENT_SUCCESS,
    payload
});

export const deleteDocumentsFailedAction = (payload: any) => ({
    type: DELETE_DOCUMENT_FAILED,
    payload
});

export const setSupportingDocTypeProps = (payload: { supportingDocTypeProps: ISupportingDocTypeProps[] }) => ({
    type: SET_SUPPORTING_DOC_TYPE_PROPS,
    payload
});

export const uploadSupportingDocFileAction = (payload: {
    file: FormData,
    submissionId: Number,
    documentTypeId: Number,
    supportingDocTypeProps: ISupportingDocTypeProps
}) => ({
    type: UPLOAD_SUPPORTING_DOC_FILE,
    payload
});

export const uploadSupportingDocFileProgressAction = (
    file: FormData,
    uploadDocProps: ISupportingDocTypeProps) => ({
        type: UPLOAD_SUPPORTING_DOC_FILE_PROGRESS,
        payload: uploadDocProps,
        meta: { file },
});

export const uploadSupportingDocFileSuccessAction = (payload: any) => ({
    type: UPLOAD_SUPPORTING_DOC_FILE_SUCCESS,
    payload
});

export const uploadSupportingDocFileFailedAction = (payload: any) => ({
    type: UPLOAD_SUPPORTING_DOC_FILE_FAILED,
    payload
});

export const clearSupportingDocFileAction = () => ({
    type: CLEAR_SUPPORTING_DOC_FILE
});

