import * as React from 'react';

import { Icon, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';

import { NavLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

interface IListItemLink {
	id: string;
	icon: string;
	primary: string;
	to: string;
	tooltip: string;
}

const useStyles = makeStyles((theme) => ({
	active: {
		backgroundColor: '#e0e0e0 !important',
		fontWeight: 'bolder !important',
		color: 'black'
	},
}));

const ListItemLink = ({ id, icon, primary, to }: IListItemLink) => {
	const classes = useStyles();
	return (
		<Tooltip title={primary} placement="right-end">
			<li id={id}>
				<ListItem button to={to} exact component={NavLink} activeClassName={classes.active}>
					{icon ? <ListItemIcon><Icon>{icon}</Icon></ListItemIcon> : null}
					<ListItemText primary={primary} />
				</ListItem>
			</li>
		</Tooltip>
	);
};

export default ListItemLink