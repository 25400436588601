import { IBatchApprovalOptionsCriteria, IFilterDTO } from "../../types/types";

export const GET_CASE_SUBMISSIONS = 'GET_CASE_SUBMISSIONS';
export const GET_CASE_SUBMISSIONS_SUCCESS = 'GET_CASE_SUBMISSIONS_SUCCESS';
export const GET_CASE_SUBMISSIONS_FAILED = 'GET_CASE_SUBMISSIONS_FAILED';
export const CLEAR_CASE_SUBMISSIONS_LIST = 'CLEAR_CASE_SUBMISSIONS_LIST';

export const EXPORT_CASE_SUBMISSIONS = 'EXPORT_CASE_SUBMISSIONS';
export const EXPORT_CASE_SUBMISSIONS_SUCCESS = 'EXPORT_CASE_SUBMISSIONS_SUCCESS';
export const EXPORT_CASE_SUBMISSIONS_FAILED = 'EXPORT_CASE_SUBMISSIONS_FAILED';

export const GET_DEFICIENCY_COUNT = 'GET_DEFICIENCY_COUNT';
export const GET_DEFICIENCY_COUNT_SUCCESS = 'GET_DEFICIENCY_COUNT_SUCCESS';
export const GET_DEFICIENCY_COUNT_FAILED = 'GET_DEFICIENCY_COUNT_FAILED';

export const DOWNLOAD_SUBMISSION = 'DOWNLOAD_SUBMISSION';
export const DOWNLOAD_SUBMISSION_SUCCESS = 'DOWNLOAD_SUBMISSION_SUCCESS';
export const DOWNLOAD_SUBMISSION_FAILED = 'DOWNLOAD_SUBMISSION_FAILED';
export const GET_SUBMISSION_DETAIL_BY_ID = 'GET_SUBMISSION_DETAIL_BY_ID';
export const GET_SUBMISSION_DETAIL_BY_ID_SUCCESS = 'GET_SUBMISSION_DETAIL_BY_ID_SUCCESS';
export const GET_SUBMISSION_DETAIL_BY_ID_FAILED = 'GET_SUBMISSION_DETAIL_BY_ID_FAILED';
export const GET_SUBMISSION_DOCUMENTS_LIST = 'GET_SUBMISSION_DOCUMENTS_LIST';
export const GET_SUBMISSION_DOCUMENTS_LIST_SUCCESS = 'GET_SUBMISSION_DOCUMENTS_LIST_SUCCESS';
export const GET_SUBMISSION_DOCUMENTS_LIST_FAILED = 'GET_SUBMISSION_DOCUMENTS_LIST_FAILED';
export const CLEAR_SUBMISSION_DOCUMENTS_LIST = 'CLEAR_SUBMISSION_DOCUMENTS_LIST';

export const GET_INSTITUTION_DETAILS_BY_ID = 'GET_INSTITUTION_DETAILS_BY_ID';
export const GET_INSTITUTION_DETAILS_BY_ID_SUCCESS = 'GET_INSTITUTION_DETAILS_BY_ID_SUCCESS';
export const GET_INSTITUTION_DETAILS_BY_ID_FAILURE = 'GET_INSTITUTION_DETAILS_BY_ID_FAILURE';

export const GET_USER_DETAILS_BY_ID = 'GET_USER_DETAILS_BY_ID';
export const GET_USER_DETAILS_BY_ID_SUCCESS = 'GET_USER_DETAILS_BY_ID_SUCCESS';
export const GET_USER_DETAILS_BY_ID_FAILURE = 'GET_USER_DETAILS_BY_ID_FAILURE';

export const GET_RELATED_SUBMISSIONS = 'GET_RELATED_SUBMISSIONS';
export const GET_RELATED_SUBMISSIONS_SUCCESS = 'GET_RELATED_SUBMISSIONS_SUCCESS';
export const GET_RELATED_SUBMISSIONS_FAILED = 'GET_RELATED_SUBMISSIONS_FAILED';

export const GET_DOCUMENT = 'GET_DOCUMENT';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAILED = 'GET_DOCUMENT_FAILED';

export const GET_GENERATE_DEFICIENT_TEMPLATE = 'GET_GENERATE_DEFICIENT_TEMPLATE';
export const GET_GENERATE_DEFICIENT_TEMPLATE_SUCCESS = 'GET_GENERATE_DEFICIENT_TEMPLATE_SUCCESS';
export const GET_GENERATE_DEFICIENT_TEMPLATE_FAILED = 'GET_GENERATE_DEFICIENT_TEMPLATE_FAILED';

export const SET_SHOW_USER_SUBMISSIONS = 'SET_SHOW_USER_SUBMISSIONS';

export const BATCH_APPROVAL_OPTIONS_BY_ID = 'BATCH_APPROVAL_OPTIONS_BY_ID';
export const BATCH_APPROVAL_OPTIONS_BY_ID_FAILED = 'BATCH_APPROVAL_OPTIONS_BY_ID_FAILED';
export const BATCH_APPROVAL_OPTIONS_BY_ID_SUCCESS = 'BATCH_APPROVAL_OPTIONS_BY_ID_SUCCESS';

export const batchApprovalOptionsById = (payload: { submissionId: number, criteria: IBatchApprovalOptionsCriteria }) => ({
    type: BATCH_APPROVAL_OPTIONS_BY_ID,
    payload
});

export const batchApprovalOptionsByIdSuccess = (payload: any) => ({
    type: BATCH_APPROVAL_OPTIONS_BY_ID_SUCCESS,
    payload
});

export const batchApprovalOptionsByIdFailed = (payload: any) => ({
    type: BATCH_APPROVAL_OPTIONS_BY_ID_FAILED,
    payload
});

export const getCaseSubmissionsAction = (payload: any) => ({
    type: GET_CASE_SUBMISSIONS,
    payload
});

export const getCaseSubmissionsSuccess = (payload: any) => ({
    type: GET_CASE_SUBMISSIONS_SUCCESS,
    payload
});

export const getCaseSubmissionsFailed = (payload: any) => ({
    type: GET_CASE_SUBMISSIONS_FAILED,
    payload
});

export const clearCaseSubmissionsListAction = () => ({
    type: CLEAR_CASE_SUBMISSIONS_LIST
});

export const getDeficiencyCountAction = (payload: number) => ({
    type: GET_DEFICIENCY_COUNT,
    payload
});

export const getDeficiencyCountSuccess = (payload: any) => ({
    type: GET_DEFICIENCY_COUNT_SUCCESS,
    payload
});

export const getDeficiencyCountFailed = (payload: any) => ({
    type: GET_DEFICIENCY_COUNT_FAILED,
    payload
});

export const exportCaseSubmissionsAction = (payload: any) => ({
    type: EXPORT_CASE_SUBMISSIONS,
    payload
});

export const exportCaseSubmissionsSuccess = (payload: any) => ({
    type: EXPORT_CASE_SUBMISSIONS_SUCCESS,
    payload
});

export const exportCaseSubmissionsFailed = (payload: any) => ({
    type: EXPORT_CASE_SUBMISSIONS_FAILED,
    payload
});

export const getSubmissionDocumentAction = (submissionId: number) => {
    return {
        type: DOWNLOAD_SUBMISSION,
        submissionId
    }
};

export const getSubmissionDocumentSuccessAction = () => ({
    type: DOWNLOAD_SUBMISSION_SUCCESS
});

export const getSubmissionDocumentFailedAction = (payload: any) => ({
    type: DOWNLOAD_SUBMISSION_FAILED,
    payload
});

export const getSubmissionDetailByIdAction = (payload: { id: number, history: any }) => ({
    type: GET_SUBMISSION_DETAIL_BY_ID,
    payload
});

export const getSubmissionDetailByIdSuccess = (payload: any) => ({
    type: GET_SUBMISSION_DETAIL_BY_ID_SUCCESS,
    payload
});

export const getSubmissionDetailByIdFailed = (payload: any) => ({
    type: GET_SUBMISSION_DETAIL_BY_ID_FAILED,
    payload
});

export const getDocumentAction = (documentId: number) => {
    return {
        type: GET_DOCUMENT,
        documentId
    }
};

export const getDocumentSuccessAction = () => ({
    type: GET_DOCUMENT_SUCCESS,
});

export const getDocumentFailedAction = (payload: any) => ({
    type: GET_DOCUMENT_FAILED,
    payload
});

export const getInstitutionDetailsByIdAction = (payload: number) => ({
    type: GET_INSTITUTION_DETAILS_BY_ID,
    payload
});

export const getInstitutionDetailsByIdSuccess = (payload: any) => ({
    type: GET_INSTITUTION_DETAILS_BY_ID_SUCCESS,
    payload
});

export const getInstitutionDetailsByIdFailed = (payload: any) => ({
    type: GET_INSTITUTION_DETAILS_BY_ID_FAILURE,
    payload
});

export const getUserDetailsByIdAction = (payload: number) => ({
    type: GET_USER_DETAILS_BY_ID,
    payload
});

export const getUserDetailsByIdSuccess = (payload: any) => ({
    type: GET_USER_DETAILS_BY_ID_SUCCESS,
    payload
});

export const getUserDetailsByIdFailed = (payload: any) => ({
    type: GET_USER_DETAILS_BY_ID_FAILURE,
    payload
});

export const getSubmissionDocumentsListAction = (payload: { submissionId: number, filter: IFilterDTO[] }) => ({
    type: GET_SUBMISSION_DOCUMENTS_LIST,
    payload
});

export const getSubmissionDocumentsListSuccessAction = (payload: any) => ({
    type: GET_SUBMISSION_DOCUMENTS_LIST_SUCCESS,
    payload
});

export const getSubmissionDocumentsListFailedAction = (payload: any) => ({
    type: GET_SUBMISSION_DOCUMENTS_LIST_FAILED,
    payload
});

export const clearSubmissionDocumentsListAction = () => ({
    type: CLEAR_SUBMISSION_DOCUMENTS_LIST
});

export const getRelatedSubmissionsAction = (submissionId: number) => ({
    type: GET_RELATED_SUBMISSIONS,
    submissionId
});

export const getRelatedSubmissionsSuccess = (payload: any) => ({
    type: GET_RELATED_SUBMISSIONS_SUCCESS,
    payload
});

export const getRelatedSubmissionsFailed = (payload: any) => ({
    type: GET_RELATED_SUBMISSIONS_FAILED,
    payload
});

export const getGenerateDeficientTemplateAction = (payload: { caseId: number, submissionId: number }) => ({
    type: GET_GENERATE_DEFICIENT_TEMPLATE,
    payload
});

export const getGenerateDeficientTemplateSuccess = (payload: any) => ({
    type: GET_GENERATE_DEFICIENT_TEMPLATE_SUCCESS,
    payload
});

export const getGenerateDeficientTemplateFailed = (payload: any) => ({
    type: GET_GENERATE_DEFICIENT_TEMPLATE_FAILED,
    payload
});

export const setShowUserSubmissionsAction = (showUserSubmissions: boolean) => ({
    type: SET_SHOW_USER_SUBMISSIONS,
    payload: showUserSubmissions
});
