import { CardContent, Grid, InputLabel, Theme, Typography, Button, Backdrop, Stack, CircularProgress } from "@mui/material";
import {
    EpiqAdmin,
    EpiqProjectManager,
    EpiqTechSupport,
    Nominee,
    EpiqUser
} from '../../constants/roles';
import {
    IClaimFileModalProps,
    ISubmissionDetail,
    ISupportingDocModalProps,
    IStringValue
} from "../../types/types";
import { Link, useParams, useHistory } from "react-router-dom";
import React, { useContext, useEffect, useState, useRef } from "react";
import {
    SubmissionState,
    SubmissionType,
    SubmissionTypeDisplay,
    SubmissionTab,
    Strings
} from "../../types/enums";
import { UserContext, UserContextType } from '../../modules/User/UserContext';
import Box from '@mui/material/Box';
import ClaimFileUploadModal from '../ClaimFileUpload/ClaimFileUploadModal';
import DeficiencyClaimFileUploadModal from '../ClaimFileUpload/DeficiencyClaimFileUploadModal';
import DeficiencyOptionalFilesUploadModal from '../ClaimFileUpload/DeficiencyOptionalFilesUploadModal';
import RelatedSubmissionGrid from "./RelatedSubmissionGrid";
import SubmissionDetailDocumentationForm from "./SubmissionDetailDocumentationForm";
import SubmissionDetailDocumentsByEpiq from "./SubmissionDetailDocumentsByEpiq";
import SubmissionDetailSummaryForm from "./SubmissionSummary";
import SupportingDocFilesUploadModal from '../ClaimFileUpload/SupportingDocFilesUploadModal';
import ClaimantListGrid from "../Claimants/ClaimantListGrid";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { convertUTCToLocalTime, getEnumKeyByEnumValue, transformHelpUrl, convertUTCToLocalDate, getInfoJsonWithArray } from "../../utilities/utilities";
import makeStyles from '@mui/styles/makeStyles';
import { getSubmissionStatusText } from '../../utilities/submissionStatus';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { useDispatch, useSelector } from "react-redux";
import { clearSubmissionDocumentsListAction, getDeficiencyCountAction, getSubmissionDocumentsListAction } from "./submissions.actions";
import { getCaseById } from "../Cases/cases.actions";
import { clearDeleteSubmissionAction } from "../ClaimFileUpload/claimFile.actions";
import { refreshClaimStatus } from "../Claimants/claimants.api";

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        flexGrow: 1,
        fontSize: '14px',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '400',
        lineHeight: '1.5',
        flexWrap: 'wrap',
        color: '#000000',
        display: 'inline-flex',
        marginRight: "5px"
    },
    headTitle: {
        flexGrow: 1,
        marginBottom: "0px",
        display: 'inline-flex',
        marginRight: "5px",
        fontWeight: "500",
        fontSize: "1.25rem",
        color: "#000D13",
        lineHeight: "normal",
        letterSpacing: "0.15px",
        float: "left",
        textTransform: "capitalize"
    },
    label: {
        color: '#555A5C',
        fontWeight: 'bold',
        fontSize: '14px',
        fontFamily: 'Montserrat, sans-serif',
        float: 'left',
        marginRight: '5px',
        lineHeight: '1.5',
        margin: '0',
        width: '100%'
    },
    subTitle: {
        fontSize: '12px',
        color: 'grey',
        float: 'right',
        fontStyle: 'italic',
        opacity: '.5',
        marginLeft: '5px',
        marginTop: '4px'
    },
    anchorTitle: {
        flexGrow: 1,
        display: 'inline-flex',
        flexWrap: 'wrap',
        color: '#00A8EB',
        fontSize: '14px',
        lineHeight: '1.5',
        fontFamily: 'Montserrat, sans-serif',
    },

    divlink: {
        float: 'right',
    },
    link: {
        paddingLeft: '5px',
        paddingRight: '10px',
        marginRight: '5px',
        borderRight: '1px solid #087C9B'
    },
    linkReplace: {
        paddingLeft: '5px',
        paddingRight: '10px',
        marginRight: '0px',
        borderRight: 'none',
    },
    updateReplace: {
        textAlign: 'right',
        background: '#fff',
        borderRadius: '6px',
        marginBottom: '8px',
        padding: '10px',
        textTransform: 'uppercase',
        height: '44px'
    },
    dashboardIconBackground: {
        fontSize: "20px"
    },
    floatLeft: {
        float: 'left'
    },
    floatRight: {
        float: 'right',
    },
    newSubmissionBackground: {
        background: '#fff',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        padding: '10px 25px 15px 24px'
    },
    newTabSubmissionBackground: {
        background: '#fff',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        padding: '0px 25px 14px 24px'
    },
    newSubmissionBackgroundBox: {
        boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.25)'
    },
    deficiencyResponse: {

        "&& h3": {
            padding: '0.5rem 0',
            marginBottom: '0',
            fontSize: '1rem',
            background: 'none',
            border: 'none'
        }
    },
    tabCommonStyle: {
        padding: "0px",
        position: 'relative',
        top: '1px',
        zIndex: '9',
        "&& .MuiTab-root": {
            backgroundColor: "#F2F2F3",
            border: 'none',
            outline: 'none !important',
            borderBottom: '1px solid #087C9B !important',
        },
        "&& .Mui-selected": {
            borderBottom: 'none !important',
            outline: 'none !important',
            backgroundColor: '#fff !important',
            border: '1px solid #087C9B !important',
            borderRadius: '6px 6px 0 0'
        },

        "&& .MuiTabs-indicator": {
            display: 'none'
        },
    }
}));

interface ISubmissionDetailFormProps {
    submissionDetail?: ISubmissionDetail;
    slideoutDrawerInstitution: Function,
    slideoutDrawerUser: Function,
    submissionId: number,
    slideoutDrawerRejections: Function;
}

interface ITabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: ITabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const SubmissionDetailForm = ({ submissionDetail, //if we already have the submission detail, it gets passed in
    slideoutDrawerInstitution,
    slideoutDrawerUser,
    submissionId, // if we need to fetch the submission detail, the submission id is passed here
    slideoutDrawerRejections
}: ISubmissionDetailFormProps) => {
    const classes = useStyles();
    const [tabNumber, setTabNumber] = React.useState(0);
    let { id } = useParams<{ id: string }>();
    let { tabName = "Summary" } = useParams<{ tabName: any }>();
    let [claimFileModalIsOpen, setClaimFileModalIsOpen] = useState(false);
    let [deficiencyClaimFileModalIsOpen, setDeficiencyClaimFileModalIsOpen] = useState(false);
    let [deficiencyOptionalFilesModalIsOpen, setDeficiencyOptionalFilesModalIsOpen] = useState(false);
    let [supportingDocFilesModalIsOpen, setSupportingDocFilesModalIsOpen] = useState(false);
    let [claimFileModalProps, setClaimFileModalProps] = useState<IClaimFileModalProps>();
    let [supportingDocModalProps, setSupportingDocModalProps] = useState<ISupportingDocModalProps>();
    let [show, setShow] = useState(false);
    let [showHandleClickDeficiencyResponse, setShowHandleClickDeficiencyResponse] = useState(false);
    let [showInfoIcon, setShowInfoIcon] = useState(false);
    let [canRenderDeficiencyResponseButton, setCanRenderDeficiencyResponseButton] = useState(false);
    let [canRenderResumeButton, setCanRenderResumeButton] = useState(false);
    let [canRenderReplaceButton, setCanRenderReplaceButton] = useState(false);


    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const [stringContentData, setStringContentData] = useState<IStringValue | null>();
    const [stringContentDataDeficiencyResponse, setStringContentDataDeficiencyResponse] = useState<IStringValue | null>();
    const [stringContentDataSubmissionUpdateAction, setStringDataContentSubmissionUpdateAction] = useState<IStringValue | null>();
    const [stringContentDataSubmissionReplaceAction, setStringContentDataSubmissionReplaceAction] = useState<IStringValue | null>();
    const [stringContentDataSubmissionResumeAction, setStringContentDataSubmissionResumeAction] = useState<IStringValue | null>();
    const { isAuthenticated, isInitialized, isInRole, user } = useContext(UserContext) as UserContextType;
    const dispatch = useDispatch();
    const [isDeficiencyCountActionExecuted, setIsDeficiencyCountActionExecuted] = useState<boolean>(false);
    const [idToExpandOnLoad, setIdToExpandOnLoad] = useState<number | undefined>(undefined);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [tabDetermined, setTabDetermined] = useState<boolean>(false);
    const history = useHistory();

    document.title = (submissionDetail === null || submissionDetail === undefined) ? 'Submission Detail' : "Epiq Filling - " + submissionDetail.submissionDescription;

    const isDeleted: boolean = useSelector((state: any) => state.claimFileState.isDeleted);

    useEffect(() => {
        dispatch(clearSubmissionDocumentsListAction());

        dispatch(getSubmissionDocumentsListAction({
            submissionId: submissionId,
            filter: [{ parameter: 'includeRelatedSubmissions', type: 'boolean', value: 'true' }],
        }));

        if (submissionDetail?.caseId) {
            dispatch(getCaseById({ caseId: submissionDetail?.caseId, history: history }));
        }

    }, [dispatch, submissionDetail?.caseId, history, submissionId]);

    useEffect(() => {
        if (submissionDetail && showSpinner) {
            const callRefreshClaimStatus = async () => {

                await refreshClaimStatus(submissionId,
                    submissionDetail.cmxCACaseNumber,
                    submissionDetail.showCmxClaimResults,
                    submissionDetail.caseId);

                if (showSpinner) {
                    setShowSpinner(false);
                }
            }

            callRefreshClaimStatus().catch(console.error);
        }

    }, [submissionDetail, submissionId, showSpinner]);

    useEffect(() => {
        if (isDeleted) {
            dispatch(clearDeleteSubmissionAction());
            history.replace('/SubmissionList');
        } else if (isDirty) {
            setIsDirty(false);
            history.go(0);
        }
    }, [isDeleted, isDirty, dispatch, history]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabNumber(newValue);
    };

    const processInfoResponse = (data: any) => {
        data.map((item: IStringValue) => {
            switch (item.key) {
                case Strings.SubmissionDetails:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setStringContentData(item);
                    break;

                case Strings.SubmissionDefectAction:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setStringContentDataDeficiencyResponse(item);
                    break;

                case Strings.SubmissionUpdateAction:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setStringDataContentSubmissionUpdateAction(item);
                    break;

                case Strings.SubmissionReplaceAction:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setStringContentDataSubmissionReplaceAction(item);
                    break;

                case Strings.SubmissionResumeAction:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setStringContentDataSubmissionResumeAction(item);
                    break;

                default:
                    break;
            }
        });
    };

    useEffect(() => {

        const fetchInfoStrings = async () => {
            const data: any = await getInfoJsonWithArray([
                Strings.SubmissionDetails,
                Strings.SubmissionDefectAction,
                Strings.SubmissionUpdateAction,
                Strings.SubmissionReplaceAction,
                Strings.SubmissionResumeAction
            ]);

            processInfoResponse(data);
        }

        if (!isDeficiencyCountActionExecuted) {
            setIsDeficiencyCountActionExecuted(true);
            fetchInfoStrings();
            dispatch(getDeficiencyCountAction(submissionId));
        }

    }, [id, isAuthenticated, isInitialized, isInRole, user, submissionDetail, stringContentData, isDeficiencyCountActionExecuted, dispatch, submissionId]);


    useEffect(() => {
        if (tabName.toLowerCase() === 'epiqdocuments'
            || tabName.toLowerCase() === 'filerdocuments'
            || tabName.toLowerCase() === 'claims') {
            setTabNumber(gettabNameFromUrl(tabName));
            setTabDetermined(true);
            return;
        }

        if (submissionDetail && submissionId) {

            if (submissionDetail.id !== submissionId) {
                setTabNumber(SubmissionTab.Submissions);
                setIdToExpandOnLoad(submissionId);
            }

        }
        else {
            setTabNumber(SubmissionTab.Summary);
        }

        setTabDetermined(true);
    }, [submissionDetail, submissionId]);

    const selectSubmission = (caseId: number,
        submissionTypeDisplay: SubmissionTypeDisplay,
        submissionId: number,
        caseName: string,
        caseNumber: string,
        submissionDescription: string) => {
        setSupportingDocFilesModalIsOpen(false);

        setClaimFileModalProps(({
            handleClose: handleUploadClaimFileModalClosed,
            submissionTypeDisplay: submissionTypeDisplay,
            submissionType: (SubmissionType[getEnumKeyByEnumValue(SubmissionTypeDisplay, submissionTypeDisplay)]),
            parentSubmissionId: submissionId,
            caseId: caseId,
            caseName: caseName,
            caseNumber: caseNumber,
            handleSupportingDocModalOpen: handleSupportingDocModalOpen,
            handleSupportingDocModalClose: handleSupportingDocModalClose,
            setSupportingDocModalProps: setSupportingDocModalProps,
            isNonClaimDeficiencyResponse: false
        }));

        setClaimFileModalIsOpen(true);
    };

    const resumeSubmission = (caseId: number,
        submissionType: SubmissionType,
        submissionId: number,
        caseName: string,
        caseNumber: string,
        submissionDescription: string) => {

        var submissionTypeDisplay: SubmissionTypeDisplay = SubmissionTypeDisplay[SubmissionType[submissionType] as unknown as keyof typeof SubmissionTypeDisplay];

        setSupportingDocModalProps(
            ({
                handleClose: submissionType === SubmissionType.Deficiency
                    ? handleDeficiencyOptionalFilesModalClose
                    : handleSupportingDocModalClose,
                submissionTypeDisplay: submissionTypeDisplay,
                submissionType: submissionType,
                submissionId: submissionId,
                caseId: caseId,
                caseName: caseName,
                caseNumber: caseNumber,
                submissionState: SubmissionState.Started,
                submissionDescription: submissionDescription
            }));

        if (submissionType === SubmissionType.Deficiency) {
            setDeficiencyOptionalFilesModalIsOpen(false);
            handleDeficiencyOptionalFilesModalOpen();
        }
        else {
            setSupportingDocFilesModalIsOpen(false);
            handleSupportingDocModalOpen();
        }

    };

    const handleUploadClaimFileModalClosed = () => {
        setClaimFileModalIsOpen(false);
    };

    const handleSupportingDocModalOpen = () => {
        setSupportingDocFilesModalIsOpen(true);
    };

    const handleSupportingDocModalClose = () => {
        setSupportingDocFilesModalIsOpen(false);

        if (!isDeleted) {
            setIsDirty(true);
        }
    };

    const gettabNameFromUrl = (tabName: any) => {
        var urltabName = tabName.toLowerCase();
        var tabSubmissionIndex = 0;

        switch (urltabName) {
            case "summary": tabSubmissionIndex = SubmissionTab.Summary; break;
            case "submissions": tabSubmissionIndex = SubmissionTab.Submissions; break;
            case "claims": tabSubmissionIndex = SubmissionTab.Claims; break;
            case "epiqdocuments": tabSubmissionIndex = SubmissionTab.EpiqDocuments; break;
            case "filerdocuments": tabSubmissionIndex = SubmissionTab.FilerDocuments; break;
            default: tabSubmissionIndex = SubmissionTab.Summary; break;
        }
        return tabSubmissionIndex;
    };

    const handleClick = (event: any) => {
        setShow(!show);
        setTarget(event.target);
    };

    const handleClickDeficiencyResponse = (event: any) => {
        setShowHandleClickDeficiencyResponse(!showHandleClickDeficiencyResponse);
        setTarget(event.target);
    };

    const selectDeficiencySubmission = (caseId: number,
        submissionTypeDisplay: SubmissionTypeDisplay,
        submissionId: number,
        caseName: string,
        caseNumber: string,
        isNonClaimDeficiencyResponse: boolean) => {
        setSupportingDocFilesModalIsOpen(false);

        setClaimFileModalProps(({
            handleClose: handleDeficiencyUploadClaimFileModalClosed,
            submissionTypeDisplay: submissionTypeDisplay,
            submissionType: (SubmissionType[getEnumKeyByEnumValue(SubmissionTypeDisplay, submissionTypeDisplay)]),
            parentSubmissionId: submissionId,
            caseId: caseId,
            caseName: caseName,
            caseNumber: caseNumber,
            handleSupportingDocModalOpen: handleDeficiencyOptionalFilesModalOpen,
            handleSupportingDocModalClose: handleDeficiencyOptionalFilesModalClose,
            setSupportingDocModalProps: setSupportingDocModalProps,
            isNonClaimDeficiencyResponse: isNonClaimDeficiencyResponse
        }));

        setDeficiencyClaimFileModalIsOpen(true);
    };

    const handleDeficiencyUploadClaimFileModalClosed = () => {
        setDeficiencyClaimFileModalIsOpen(false);
    };

    const handleDeficiencyOptionalFilesModalOpen = () => {
        setDeficiencyOptionalFilesModalIsOpen(true);
    };

    const handleDeficiencyOptionalFilesModalClose = () => {
        setDeficiencyOptionalFilesModalIsOpen(false);
    };

    const popoverFormat = () => {

        let helpContent = "";

        if ((isInRole([EpiqAdmin, EpiqTechSupport, EpiqProjectManager, Nominee])
            && submissionDetail?.canAcceptDeficiencyResponse
            && stringContentDataDeficiencyResponse
            && submissionDetail?.submissionStatus !== "Started"
        )) {
            helpContent += stringContentDataDeficiencyResponse.value;
        }

        if ((isInRole([EpiqProjectManager, EpiqTechSupport, Nominee])
            && stringContentDataSubmissionResumeAction
            && submissionDetail?.submissionStatus === "Started"
        )) {
            helpContent += stringContentDataSubmissionResumeAction.value;
        }
        else {
            if ((isInRole([EpiqProjectManager, EpiqTechSupport, Nominee])
                && submissionDetail?.isCaseAcceptingSubmissions
                && stringContentDataSubmissionUpdateAction
                && submissionDetail.submissionStatus !== "Rejected"
            )) {
                helpContent += stringContentDataSubmissionUpdateAction?.value;
            }

            if ((isInRole([EpiqProjectManager, EpiqTechSupport, Nominee])
                && submissionDetail?.isCaseAcceptingSubmissions
                && stringContentDataSubmissionReplaceAction
            )) {
                helpContent += stringContentDataSubmissionReplaceAction.value;
            }
        }


        return (
            <Popover id="popover-contained">
                <Popover.Body className={classes.deficiencyResponse} dangerouslySetInnerHTML={{ __html: helpContent }}></Popover.Body>
            </Popover>
        )
    }

    useEffect(() => {
        if (isInRole([EpiqTechSupport, EpiqProjectManager, Nominee]) &&
            submissionDetail?.canAcceptDeficiencyResponse &&
            submissionDetail?.submissionStatus !== "Started"
        ) {
            setCanRenderDeficiencyResponseButton(true);
            setShowInfoIcon(true);
        }

        if (isInRole([EpiqProjectManager, EpiqTechSupport, Nominee]) && submissionDetail?.isCaseAcceptingSubmissions) {

            // if the submission state is in error or in progress, don't show any action buttons
            if ([
                "submitted",
                "extracting",
                "extracted",
                "verifying",
                "verified",
                "loading",
                "error",
                "partially extracted",
                "received"].includes(submissionDetail?.submissionStatus.toLocaleLowerCase())) {
                return;
            }

            setCanRenderResumeButton(true);
            setCanRenderReplaceButton(true);
            setShowInfoIcon(true);
        }
    }, [isInRole, submissionDetail?.canAcceptDeficiencyResponse, submissionDetail?.isCaseAcceptingSubmissions, submissionDetail?.submissionStatus]);

    return (
        <>
            {
                (submissionDetail && tabDetermined)
                    ?
                    (

                        <>
                            <div className={classes.updateReplace}>

                                {/* title */}
                                <div ref={ref}>
                                    <Typography variant="h6" className={classes.headTitle} noWrap>
                                        Submission Details
                                    </Typography>
                                    <Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "-2px", justifyContent: "left", minWidth: "25px", float: "left" }} onClick={handleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} /></Button>
                                    <Overlay
                                        show={show}
                                        target={target}
                                        placement="right-start"
                                        container={ref}
                                        containerPadding={20}
                                        rootClose
                                        onHide={() => setShow(false)}
                                    >
                                        <Popover id="popover-contained">
                                            <Popover.Header as="h3"><span style={{ color: "#000" }}>Submission Details</span></Popover.Header>
                                            {stringContentData && (
                                                <Popover.Body dangerouslySetInnerHTML={{ __html: stringContentData.value }}></Popover.Body>
                                            )}
                                        </Popover>
                                    </Overlay>
                                </div>

                                {/* action buttons (resume, update, replace, Defect Response)*/}
                                <div ref={ref} style={{ position: 'relative', display: 'inline-flex', paddingRight: '10px' }}>
                                    {
                                        canRenderDeficiencyResponseButton
                                            ?

                                            <Link className={classes.link} color="success" to="#" onClick={() => {
                                                selectDeficiencySubmission(submissionDetail.caseId,
                                                    SubmissionTypeDisplay.Deficiency,
                                                    submissionDetail.parentSubmissionId ?
                                                        submissionDetail.parentSubmissionId :
                                                        submissionDetail.id,
                                                    submissionDetail.caseName,
                                                    submissionDetail.caseNumber,
                                                    submissionDetail.isNonClaimDeficiencyResponse
                                                );
                                            }}>
                                                Defect Response
                                            </Link>

                                            :

                                            ""
                                    }

                                    {
                                        (submissionDetail.submissionStatus === "Started")
                                            ?

                                            canRenderResumeButton &&

                                            <Link className={classes.link} color="success" to="#" onClick={() => {

                                                let idToResume = submissionDetail.newestRelatedSubmissionId
                                                    ? submissionDetail.newestRelatedSubmissionId
                                                    : submissionDetail.id;

                                                let submissionTypeToResume: SubmissionType = submissionDetail.newestRelatedSubmissionTypeId
                                                    ? submissionDetail.newestRelatedSubmissionTypeId
                                                    : submissionDetail.submissionTypeId


                                                resumeSubmission(submissionDetail.caseId,
                                                    submissionTypeToResume,
                                                    idToResume,
                                                    submissionDetail.caseName,
                                                    submissionDetail.caseNumber,
                                                    submissionDetail.submissionDescription
                                                );
                                            }}>
                                                Resume
                                            </Link>

                                            :
                                            (
                                                submissionDetail.submissionStatus === "Rejected")
                                                ?

                                                canRenderReplaceButton &&

                                                <Link className={classes.linkReplace} color="success" to="#" onClick={() => {
                                                    selectSubmission(submissionDetail.caseId,
                                                        SubmissionTypeDisplay.Replacement,
                                                        submissionDetail.parentSubmissionId ?
                                                            submissionDetail.parentSubmissionId :
                                                            submissionDetail.id,
                                                        submissionDetail.caseName,
                                                        submissionDetail.caseNumber,
                                                        submissionDetail.submissionDescription
                                                    );
                                                }}>
                                                    REPLACE
                                                </Link>

                                                :
                                                (canRenderReplaceButton &&
                                                    <>

                                                        <Link className={classes.link} color="success" to="#" onClick={() => {
                                                            selectSubmission(submissionDetail.caseId,
                                                                SubmissionTypeDisplay.Update,
                                                                submissionDetail.parentSubmissionId ?
                                                                    submissionDetail.parentSubmissionId :
                                                                    submissionDetail.id,
                                                                submissionDetail.caseName,
                                                                submissionDetail.caseNumber,
                                                                submissionDetail.submissionDescription
                                                            );
                                                        }}
                                                        >
                                                            UPDATE
                                                        </Link>

                                                        <Link className={classes.linkReplace} color="success" to="#"
                                                            onClick={() => {
                                                                selectSubmission(submissionDetail.caseId,
                                                                    SubmissionTypeDisplay.Replacement,
                                                                    submissionDetail.parentSubmissionId ?
                                                                        submissionDetail.parentSubmissionId :
                                                                        submissionDetail.id,
                                                                    submissionDetail.caseName,
                                                                    submissionDetail.caseNumber,
                                                                    submissionDetail.submissionDescription
                                                                );
                                                            }}
                                                        >
                                                            REPLACE
                                                        </Link>
                                                    </>

                                                )
                                    }

                                    {
                                        showInfoIcon &&
                                        <Button style={{ background: "none", outline: "none", padding: "0", position: 'relative', bottom: '0', justifyContent: "left", minWidth: "25px", float: "left" }} onClick={handleClickDeficiencyResponse}><InfoOutlinedIcon style={{ color: '#087c9b', fontSize: '15px' }} className={classes.dashboardIconBackground} />
                                        </Button>
                                    }


                                    <Overlay
                                        show={showHandleClickDeficiencyResponse}
                                        target={target}
                                        placement="left-start"
                                        container={ref}
                                        containerPadding={20}
                                        rootClose
                                        onHide={() => setShowHandleClickDeficiencyResponse(false)}
                                    >
                                        {popoverFormat()}
                                    </Overlay>

                                </div>
                            </div>

                            {/* submission detail fields */}
                            <div className={classes.newSubmissionBackground}>
                                <CardContent className={classes.newSubmissionBackgroundBox}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={1} lg={1}>
                                            <InputLabel className={classes.label}>Submission</InputLabel>
                                            <div className={classes.floatLeft} ref={ref}>
                                                <Typography className={classes.title} noWrap>
                                                    {submissionDetail.submissionDescription}
                                                </Typography>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} sm={2} lg={2}>
                                            <InputLabel className={classes.label}>Status</InputLabel>
                                            {([SubmissionState.Rejected].includes(submissionDetail.submissionStateId))
                                                ?
                                                   <Link color="success" to="#" onClick={() => { slideoutDrawerRejections(submissionDetail.id) }}>
                                                        <span>{getSubmissionStatusText(isInRole([Nominee]), submissionDetail.submissionStatus) || ''}</span>
                                                    </Link>
                                                    :
                                                    <Typography className={classes.title} noWrap component="div">
                                                        {getSubmissionStatusText(isInRole([Nominee]), submissionDetail.submissionStatus) || ''}
                                                    </Typography>
                                            }
                                            <Typography className={classes.title} noWrap component="div">
                                                 <div className={classes.subTitle}>{(submissionDetail.transitionedOn) ?
                                                      convertUTCToLocalTime(submissionDetail.transitionedOn) : convertUTCToLocalTime(submissionDetail.receivedOn)}
                                                </div>
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={2} lg={2}>
                                            <InputLabel className={classes.label}>Case Name</InputLabel>
                                            <Typography sx={{ whiteSpace: 'normal' }} className={classes.title} noWrap>
                                                {submissionDetail.caseName}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={1} lg={1}>
                                            <InputLabel className={classes.label}>Case #</InputLabel>
                                            <Link color="success" to={`/CaseDetail/${submissionDetail.caseId}`}>
                                                {submissionDetail.caseNumber}
                                            </Link>
                                        </Grid>

                                        <Grid item xs={12} sm={2} lg={2}>
                                            <InputLabel className={classes.label}>Institution</InputLabel>
                                            <Typography style={{ cursor: "pointer", whiteSpace: "normal", overflow: "hidden", textOverflow: "ellipsis" }} onClick={() => { slideoutDrawerInstitution(submissionDetail.institutionId, true); }} className={classes.anchorTitle} noWrap>
                                                {submissionDetail.institutionName}
                                            </Typography>

                                        </Grid>

                                        <Grid item xs={12} sm={2} lg={2}>
                                            <InputLabel className={classes.label}>Submitted by:</InputLabel>

                                            {submissionDetail.submissionOriginId === 2 &&
                                                <Typography className={classes.title} noWrap>
                                                    {submissionDetail.submittedByUserName}
                                                </Typography>
                                            }

                                            {submissionDetail.submissionOriginId !== 2 &&
                                                <Typography style={{ cursor: "pointer" }} onClick={() => { slideoutDrawerUser(submissionDetail.submittedByUserId, true); }} className={classes.anchorTitle} noWrap>
                                                    {submissionDetail.submittedByUserName}
                                                </Typography>
                                            }

                                        </Grid>

                                        <Grid item xs={12} sm={2} lg={2}>
                                            <InputLabel className={classes.label}>Received Date:</InputLabel>
                                            <Typography className={classes.title} noWrap>
                                                {convertUTCToLocalDate(submissionDetail.receivedOn)}
                                            </Typography>

                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </div>
                        </>
                    )
                    :
                    (
                        <Typography variant="h6" className={classes.title} noWrap component="div">
                            <div>Submission: </div>
                            <div>Status: </div>
                        </Typography>
                    )
            }

            {/* submission tabs */}
            {tabDetermined &&
                <Box sx={{ width: '100%', background: '#fff' }}>
                    <Box sx={{ borderBottom: 0, borderColor: 'divider', margin: '0 25px' }}>
                        <Tabs className={classes.tabCommonStyle} sx={{ background: '#F2F2F3', display: 'inline-flex' }} value={tabNumber} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Summary" {...a11yProps(0)} sx={{ color: "#484D54", fontWeight: 'bold' }} />
                            <Tab label="Submissions" {...a11yProps(1)} sx={{ color: "#484D54", fontWeight: 'bold' }} />
                            <Tab label="Claims" {...a11yProps(2)} sx={{ color: "#484D54", fontWeight: 'bold' }} />
                            <Tab label="Documents By Epiq" {...a11yProps(3)} sx={{ color: "#484D54", fontWeight: 'bold' }} />
                            <Tab label="Documents By Filers" {...a11yProps(4)} sx={{ color: "#484D54", fontWeight: 'bold' }} />
                        </Tabs>
                    </Box>

                    {/*summary*/}
                    <TabPanel value={tabNumber} index={0}>
                        <div>
                            {
                                submissionDetail &&
                                <SubmissionDetailSummaryForm
                                    submissionDetail={submissionDetail}
                                    shouldSumCounts={true}
                                />
                            }
                        </div>
                    </TabPanel>

                    {/*amendments*/}
                    <TabPanel value={tabNumber} index={1}>
                        <div id="historyAndClaims">
                            {
                                submissionDetail &&
                                <RelatedSubmissionGrid
                                    submissionId={submissionDetail.id}
                                    idToExpandOnLoad={idToExpandOnLoad}
                                />

                            }
                        </div>
                    </TabPanel>

                    {/*claims*/}
                    <TabPanel value={tabNumber} index={2}>
                        <div id="historyAndClaims">
                            {
                                submissionDetail &&
                                <ClaimantListGrid
                                    submissionId={parseInt(id)}
                                    cmxCACaseNumber={submissionDetail.cmxCACaseNumber}
                                />
                            }
                        </div>
                    </TabPanel>

                    {/*documents by epiq*/}
                    <TabPanel value={tabNumber} index={3}>
                        <div>
                            {
                                submissionDetail &&
                                <SubmissionDetailDocumentsByEpiq
                                    submissionDetail={submissionDetail}
                                />
                            }
                        </div>
                    </TabPanel>

                    {/*documents by filers*/}
                    <TabPanel value={tabNumber} index={4}>
                        <div>
                            {
                                submissionDetail &&
                                <SubmissionDetailDocumentationForm
                                    submissionDetail={submissionDetail}
                                />
                            }
                        </div>
                    </TabPanel>
                </Box>
            }
            {
                claimFileModalIsOpen && claimFileModalProps &&
                <ClaimFileUploadModal modalOpen={claimFileModalIsOpen} claimFileModalProps={claimFileModalProps} />
            }

            {
                supportingDocFilesModalIsOpen && supportingDocModalProps &&
                <SupportingDocFilesUploadModal modalOpen={supportingDocFilesModalIsOpen} supportingDocModalProps={supportingDocModalProps} />
            }

            {
                deficiencyClaimFileModalIsOpen && claimFileModalProps &&
                <DeficiencyClaimFileUploadModal
                    modalOpen={deficiencyClaimFileModalIsOpen}
                    claimFileModalProps={claimFileModalProps}
                />
            }

            {
                deficiencyOptionalFilesModalIsOpen && supportingDocModalProps &&
                <DeficiencyOptionalFilesUploadModal modalOpen={deficiencyOptionalFilesModalIsOpen} supportingDocModalProps={supportingDocModalProps} />
            }

            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showSpinner}>
                <Stack alignItems="center">
                    <CircularProgress color="inherit" />
                </Stack>
            </Backdrop>
        </>
    );
}

export default SubmissionDetailForm;