import { ICaseState, IClaimFileModalProps, ISupportingDocModalProps, IStringValue, IClaimFileState } from '../../types/types';
import { Link as MuiLink, Fab, Theme, Button, Backdrop, Stack, CircularProgress } from '@mui/material';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { downloadCaseTemplateAction } from './cases.actions';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import CasebookImportModal from '../Casebook/CasebookImportModal';
import ClaimFileUploadModal from '../ClaimFileUpload/ClaimFileUploadModal';
import SupportingDocFilesUploadModal from '../ClaimFileUpload/SupportingDocFilesUploadModal';
import { clearCaseSearch } from '../Casebook/casebook.actions';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import CaseListGrid from './CaseListGrid';
import { CaseListTabTitle, SubmissionType, SubmissionTypeDisplay, Strings } from "../../types/enums";
import { EpiqProjectManager, EpiqTechSupport } from '../../constants/roles';
import { UserContext, UserContextType } from '../../modules/User/UserContext'
import { getEnumKeyByEnumValue, getInfoJsonWithArray, transformHelpUrl } from "../../utilities/utilities";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { EpiqUser } from '../../constants/roles';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import ConfirmationMessageModal from '../../components/Shared/ConfirmationMessageModal';

const useStyles = makeStyles((theme: Theme) => ({
    fab: {
        background: 'none',
        color: '#006B86',
        boxShadow: 'none',
        width: 'auto',
        height: 'auto',
        "&:hover": {
            background: 'none',
        },
        "&:focus": {
            outline: 'none',
            background: 'none',
            boxShadow: 'none',
        },
        "&:active": {
            outline: 'none',
            background: 'none',
            boxShadow: 'none',
        },
        "&:disabled": {
            display: 'none',
        },
    },
    button: {
        background: "#006B86",
        size: 'small',
        margin: theme.spacing(1),
        color: 'white'
    },
    textLink: {
        color: theme.palette.text.primary,
        padding: '0.5em'
    },
    commonbackgroundcard: {
        boxShadow: '0px 10px 15px -3px rgb(0 0 0 / 30%)',
        padding: '1.5rem 1rem 0.25rem',
        margin: '1rem 1rem 0 0',
        background: '#fff',
        borderRadius: "5px",
        "& tbody tr": {
            borderBottom: "1px solid rgba(224, 224, 224, 1)"
        },
        "& thead tr th:first-child": {
            width: "11%"
        },
        "& thead tr th:nth-child(2)": {
            width: "17%"
        },
        "& thead tr th:nth-child(3)": {
            width: "12%"
        },
        "& thead tr th:nth-child(3) > div": {
            width: "100%"
        },
        "& thead tr th:nth-child(4)": {
            width: "12%"
        },
        "& thead tr th:nth-child(4) > div": {
            width: "100%"
        },
        "& thead tr th:nth-child(5)": {
            width: "12%"
        },
        "& thead tr th:nth-child(6)": {
            width: "12%"
        },
        "& thead tr th:nth-child(7)": {
            width: "8%"
        },
        "& thead tr th:nth-child(7) span svg": {
            display: "none"
        },
        "& thead tr th:nth-child(7) p": {
            display: "none"
        },
        "& thead tr th:last-child": {
            width: "16%"
        },
        "& thead tr th:last-child div": {
            minWidth: "100%"
        },
        "& tbody tr td:first-child": {
            width: "11%",
            paddingLeft: "20px !important"
        },
        "& tbody tr td:nth-child(2)": {
            width: "22%",
            textAlign: "left"
        },
        "& tbody tr td:nth-child(3)": {
            width: "12%",
            textAlign: "left"
        },
        "& tbody tr td:nth-child(4)": {
            width: "12%",
            textAlign: "left"
        },
        "& tbody tr td:nth-child(5)": {
            width: "12%",
            textAlign: "left"
        },
        "& tbody tr td:nth-child(6)": {
            width: "12%",
            textAlign: "left"
        },
        "& tbody tr td:nth-child(7)": {
            width: "8%",
            textAlign: "left"
        },
        "& tbody tr td:last-child": {
            width: "8%"
        },
        "& tbody tr td:last-child li": {
            float: "left",
            padding: "0"
        },
        "@media (max-width: 1368px)": {
            "& thead tr th span": {
                fontSize: "0.8rem"
            },
            "& tbody tr td": {
                fontSize: "0.8rem"
            },
            "& tbody tr td a": {
                fontSize: "0.8rem"
            },
            "& thead tr th:first-child": {
                width: "13%"
            },
            "& thead tr th:nth-child(2)": {
                width: "14.5%"
            },
            "& thead tr th:nth-child(3)": {
                width: "14.5%"
            },
            "& thead tr th:nth-child(4)": {
                width: "14.5%"
            },
            "& thead tr th:nth-child(5)": {
                width: "14.5%"
            },
            "& thead tr th:nth-child(6)": {
                width: "12.5%"
            },
            "& thead tr th:last-child": {
                width: "16.5%"
            },
            "& thead tr th:last-child div": {
                minWidth: "100%"
            },
            "& tbody tr td:first-child": {
                width: "13%"
            },
            "& tbody tr td:nth-child(2)": {
                width: "14.5%",
                textAlign: "left"
            },
            "& tbody tr td:nth-child(3)": {
                width: "14.5%",
                textAlign: "left"
            },
            "& tbody tr td:nth-child(4)": {
                width: "14.5%",
                textAlign: "left"
            },
            "& tbody tr td:nth-child(5)": {
                width: "14.5%",
                textAlign: "left"
            },
            "& tbody tr td:nth-child(6)": {
                width: "12.5%",
                textAlign: "left"
            },
            "& tbody tr td:last-child": {
                width: "16.5%"
            },
        },
    },
    title: {
        flexGrow: 1,
        marginBottom: "15px",
        display: 'inline-flex',
        marginRight: "5px"
    },
    floatLeft: {
        float: 'left'
    },
    floatRight: {
        float: 'right',
    },
    headOuter: {
        display: 'inline-block',
        width: '100%',
    },
    disabled: {
        display: 'none'
    },
    dashboardIconBackground: {
        fontSize: "20px"
    }
}));


const CaseListPage = () => {
    const dispatch = useDispatch();
    const caseState: ICaseState = useSelector((state: any) => state.caseState);
    const classes = useStyles();
    let [claimFileModalIsOpen, setClaimFileModalIsOpen] = useState(false);
    let [uploadCaseModalIsOpen, setUploadCaseModalIsOpen] = useState(false);
    let [supportingDocFilesModalIsOpen, setSupportingDocFilesModalIsOpen] = useState(false);
    const { isAuthenticated, isInitialized, isInRole } = useContext(UserContext) as UserContextType;
    let [claimFileModalProps, setClaimFileModalProps] = useState<IClaimFileModalProps>();
    let [supportingDocModalProps, setSupportingDocModalProps] = useState<ISupportingDocModalProps>()
    let [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const [stringContentData, setStringContentData] = useState<IStringValue | null>();
    const claimFileState: IClaimFileState = useSelector((state: any) => state.claimFileState);

    const location = useLocation();
    document.title = CaseListTabTitle.Title;

    useEffect(() => {
        const path = location.pathname + location.search;
        ReactGA.send({ hitType: "pageview", page: path, title: CaseListTabTitle.Title });
    }, [location]);


    const isDownloading: boolean = useSelector((state: any) => {
        return state.caseState.isDownloading;
    });

    const selectCase = (caseId: number, submissionTypeDisplay: SubmissionTypeDisplay, caseName: string, caseNumber: string) => {
        setSupportingDocFilesModalIsOpen(false);

        setClaimFileModalProps(({
            handleClose: handleUploadClaimFileModalClosed,
            submissionTypeDisplay: submissionTypeDisplay,
            submissionType: (SubmissionType[getEnumKeyByEnumValue(SubmissionTypeDisplay, submissionTypeDisplay)]),
            parentSubmissionId: 0,
            caseId: caseId,
            caseName: caseName,
            caseNumber: caseNumber,
            isNonClaimDeficiencyResponse: false,
            handleSupportingDocModalOpen: handleSupportingDocModalOpen,
            handleSupportingDocModalClose: handleSupportingDocModalClose,
            setSupportingDocModalProps: setSupportingDocModalProps
        }));

        setClaimFileModalIsOpen(true);
    }

    const searchCaseBook = () => {
        setUploadCaseModalIsOpen(true);
    };

    const handleUploadCaseModalClosed = () => {
        setUploadCaseModalIsOpen(false);
        dispatch(clearCaseSearch());
    };

    const handleUploadClaimFileModalClosed = () => {
        setClaimFileModalIsOpen(false);
    };

    const handleSupportingDocModalOpen = () => {
        setSupportingDocFilesModalIsOpen(true);
    };

    const handleSupportingDocModalClose = (submitted: boolean=false) => {
        if (submitted) {
            //show confirmation modal
            setConfirmationModalOpen(true);
        }

        setSupportingDocFilesModalIsOpen(false);
    };

    const downloadTemplate = (caseId: number) => {
        let caseSelected = caseState.cases.find(x => x.id === caseId);
        dispatch(downloadCaseTemplateAction(caseSelected!.id))
    };

    const handleClick = (event: any) => {
        setShow(!show);
        setTarget(event.target);
    };

    const processInfoResponse = (data: any) => {
        data.map((item: IStringValue) => {
            switch (item.key) {
                case Strings.Cases:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setStringContentData(item);
                    break;


                default:
                    break;
            }
        });
    };

    useEffect(() => {
        const fetchInfoStrings = async () => {
            const data: any = await getInfoJsonWithArray([
                Strings.Cases,
            ]);

            processInfoResponse(data);
        };


        if (isInitialized && isAuthenticated) {
            fetchInfoStrings();
        }
    }, [isAuthenticated, isInitialized]);

    const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);

    const handleClose = (
        event: {},
        reason: "backdropClick" | "escapeKeyDown"
    ) => {
        if (reason === "backdropClick") {
            console.log(reason);
        } else {
            setConfirmationModalOpen(false);
        }
    };

    return (
        <div className={classes.commonbackgroundcard}>

            <ConfirmationMessageModal open={ confirmationModalOpen} handleClose={ handleClose } />

            <div className={classes.headOuter}>
                <div className={classes.floatLeft} ref={ref}>
                    <Typography variant="h6" className={classes.title} noWrap id="casesPageTitle">
                        Cases
                    </Typography>
                    <Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent: "left", minWidth: "25px" }} onClick={handleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} /></Button>
                    <Overlay
                        show={show}
                        target={target}
                        placement="right-start"
                        container={ref}
                        containerPadding={20}
                        rootClose
                        onHide={() => setShow(false)}
                    >
                        <Popover id="popover-contained">
                            <Popover.Header as="h3"><span style={{ color: "#000" }}>Cases</span></Popover.Header>
                            {stringContentData && (
                                <Popover.Body dangerouslySetInnerHTML={{ __html: stringContentData.value }}></Popover.Body>
                            )}
                        </Popover>
                    </Overlay>

                </div>

                <div className={classes.floatRight}>
                    <Fab color="secondary" onClick={searchCaseBook} className={classes.fab} aria-label="add" disabled={!isInRole([EpiqProjectManager, EpiqTechSupport])}>
                        <AddIcon /> <span style={{ color: '#000', fontSize: '15px', fontWeight: '500' }}>add case</span>
                    </Fab>
                    {claimFileModalIsOpen && claimFileModalProps &&
                        <ClaimFileUploadModal
                            modalOpen={claimFileModalIsOpen}
                            claimFileModalProps={claimFileModalProps}
                        />
                    }
                    {supportingDocFilesModalIsOpen && supportingDocModalProps &&
                        <SupportingDocFilesUploadModal
                            modalOpen={supportingDocFilesModalIsOpen}
                            supportingDocModalProps={supportingDocModalProps}
                        />
                    }
                </div>
            </div>

            <CaseListGrid
                selectCase={selectCase}
                downloadTemplate={downloadTemplate}
            />

            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isDownloading}>
                <Stack alignItems="center">
                    <CircularProgress color="inherit" />
                    <br />

                    <Typography variant="h4" color="#fff">
                        Downloading document...
                    </Typography>
                </Stack>
            </Backdrop>

            <CasebookImportModal modalOpen={uploadCaseModalIsOpen} handleClose={handleUploadCaseModalClosed} />
        </div>
    )
};

export default CaseListPage;