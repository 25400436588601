import { IEnumDTO, IFilterDTO } from "../../../types/types";
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	Theme,
	Typography,
	Button
} from "@mui/material";

import { DEFAULT_ROWS_PER_PAGE } from "../../../constants/CommonConstants";
import React from "react";
import SecuritiesTableFilter from "./SecuritiesTableFilter";
import SecuritiesTableHeader from "./SecuritiesTableHeader";
import SecuritiesTableRow from "./SecuritiesTableRow";
import SecuritiesCollapsibleTableRow from "./SecuritiesCollapsibleTableRow";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const createLocalStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			marginBottom: '0rem',
		},
		paper: {
			width: "100%",
			marginBottom: theme.spacing(2),
			boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
		},
		title: {
			paddingTop: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			fontWeight: "bold",
		},
		table: {
			minWidth: 800,
		},
		exportBtn: {
			float: "right",
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			marginRight: theme.spacing(2)
		}
	})
);

export interface ISecuritiesTableProps {
	cssSelectorId?: string;
	columns: IColumnHeader[];
	subTableColumns?: IColumnHeader[]; 
	rows: ISecuritiesTableFieldMap[] | null;
	totalRowCount?: number;
	sorter: ISorter;
	paging?: string;	// if present, valid values are 'client'  and 'server' 
	pageNumber?: number; // if paging == 'server' pass in the current page number
	rowsPerServerPage?: number; // if paging =='server' pass in the rows per page
	title?: string;
	showFilter?: boolean;
	dataFetchFunc?: Function;
	resetFilterFunc?: Function;
	maxTableHeight?: number;
	collapsible?: boolean;
	subTableTitle?: string; 
	dataExportFunc?: Function;
	enableExport?: boolean;
	defaultFilter?: IFilterDTO[];
	disableSorting?: boolean;
}

const DEFAULT_MAX_TABLE_HEIGHT = 750;

export default function SecuritiesTable<T>({
	cssSelectorId,
	columns,
	subTableColumns,
	rows,
	totalRowCount,
	paging,
	pageNumber,
	rowsPerServerPage,
	sorter,
	title,
	showFilter,
	dataFetchFunc,
	resetFilterFunc,
	maxTableHeight,
	collapsible,
	subTableTitle,
	dataExportFunc,
	enableExport = false,
	defaultFilter,
	disableSorting = false}: ISecuritiesTableProps) {
	const classes = createLocalStyles();
	const [order, setOrder] = React.useState<Order>(sorter.order);
	const [orderBy, setOrderBy] = React.useState<keyof ISecuritiesTableFieldMap>(sorter.property);
	const [page, setPage] = React.useState(pageNumber || 0);
	const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerServerPage || DEFAULT_ROWS_PER_PAGE);
	const activeSorter: ISorter = {
		property: orderBy,
		order: order,
	};

	let localFilterCopy: IFilterDTO[];
	
	const updateLocalFilterCopy = (newFilter: IFilterDTO[]) => {
		localFilterCopy = newFilter;
	}

	const handleResetFilter = () => {
		if (defaultFilter) {
			localFilterCopy = defaultFilter;
		} else {
			localFilterCopy = [];
        }

		if (resetFilterFunc) {
			resetFilterFunc();
		}
		
		if (dataFetchFunc) {
			if (paging === 'server') {
				dataFetchFunc(localFilterCopy, 1, DEFAULT_ROWS_PER_PAGE);
			} else {
           		dataFetchFunc(localFilterCopy)
			}

		}
	}

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);

		if (paging === 'server') {
			if (paging === 'server' && dataFetchFunc !== undefined && localFilterCopy !== undefined) {
				dataFetchFunc(localFilterCopy, newPage+1, rowsPerPage);
			}
		}
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		let newRowsPerPage = parseInt(event.target.value, 10);
		setRowsPerPage(newRowsPerPage);
		setPage(0);

		if (paging === 'server') {
			if (paging === 'server' && dataFetchFunc !== undefined && localFilterCopy !== undefined) {
				dataFetchFunc(localFilterCopy, 1, newRowsPerPage);
			}
		}
	};

	const handleSearchClick = (filter: IFilterDTO[]) => {
		if (dataFetchFunc !== undefined) {
			if (paging === 'server') {
				dataFetchFunc(filter, 1, rowsPerPage);
			} else {
				dataFetchFunc(filter);
			}
		}
	};

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ISecuritiesTableFieldMap, filter?: IFilterDTO[]) => {

		if (disableSorting)
			return;

		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);

		sorter.property = property;
		sorter.order = isAsc ? "desc" : "asc";

		setPage(0);

		if (paging === 'server' && dataFetchFunc !== undefined && filter !== undefined) {
			dataFetchFunc(filter, 1, rowsPerPage);
		}
	};
	function genericSort(a: ISecuritiesTableFieldMap, b: ISecuritiesTableFieldMap, sorter: ISorter) {
		if (!sorter?.property) return 0;

		let objectA =
			a[sorter.property].sortValue === null || a[sorter.property].sortValue === undefined
				? a[sorter.property].value
				: a[sorter.property].sortValue;

		let objectB =
			b[sorter.property].sortValue === null || b[sorter.property].sortValue === undefined
				? b[sorter.property].value
				: b[sorter.property].sortValue;

		if (typeof objectA === "string" || objectA instanceof String) {
			objectA = objectA.toLowerCase();
		}

		if (typeof objectB === "string" || objectB instanceof String) {
			objectB = objectB.toLowerCase();
		}

		const result = () => {
			if (objectA === null) {
				return -1;
			}

			if (objectB === null) {
				return 1;
			}

			if (objectA > objectB) {
				return 1;
			}

			if (objectA < objectB) {
				return -1;
			}

			return 0;
		};

		return sorter.order !== "asc" ? result() * -1 : result();
	}

	const processRows = (rows: ISecuritiesTableFieldMap[]) => {
		if (paging === 'client') {
			return rows
				.sort((a, b) => genericSort(a, b, activeSorter))
				.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
				.map((row: any, index) => {
					if (collapsible && subTableColumns) {
						return <SecuritiesCollapsibleTableRow 
						columns={ columns } 
						subTableColumns={ subTableColumns }
						row={ row } 
						index={ index } 
						key={ index } 
						collapse={ row.collapsible.value }
						subTableTitle={ subTableTitle }
						/>
					}

					return <SecuritiesTableRow columns={columns} row={row} index={index} key={index} />;
					
				});
		}

		return rows
			.sort((a, b) => genericSort(a, b, activeSorter))
			.map((row: any, index) => {
				if (collapsible && subTableColumns) {
					return <SecuritiesCollapsibleTableRow 
					columns={ columns } 
					subTableColumns={ subTableColumns }
					row={ row } 
					index={ index } 
					key={ index} 
					collapse={ row.collapsible.value }
					subTableTitle={ subTableTitle }
					/>
				}

				return  <SecuritiesTableRow columns={columns} row={row} index={index} key={index} />;

			});
	};

	const handleExport = (e: React.MouseEvent) => {
		e.preventDefault();

		if (dataExportFunc) {
			dataExportFunc();
		}

		return false;
	}

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				{title ? <Typography className={classes.title}>{title}</Typography> : null}

				{enableExport &&
					<Button variant="contained" className={classes.exportBtn} color={'primary'} onMouseDown={(e) => handleExport(e)} style={{zIndex: "999"}} >Export</Button>
				}

				<TableContainer sx={{ maxHeight: maxTableHeight || DEFAULT_MAX_TABLE_HEIGHT }}>
					<Table 
						id={cssSelectorId ? cssSelectorId : "securitiesTable"}
						className={classes.table}
						aria-labelledby="tableTitle"
						aria-label="enhanced table"
					>
						{showFilter ? (
							<SecuritiesTableFilter
								filterColumns={columns}
								orderBy={orderBy}
								order={order}
								onRequestSort={handleRequestSort}
								fetchDataFunc={handleSearchClick}
								resetFilterFunc={handleResetFilter}
								updateParentFilterCopy={updateLocalFilterCopy}
								defaultFilter={ defaultFilter }
							/>
						) : (
							<SecuritiesTableHeader
									columns={columns}
									orderBy={orderBy}
									order={order}
									onRequestSort={handleRequestSort}
							/>
						)}

						<TableBody>
							{!rows || rows.length === 0 ? (
								<TableRow>
									<TableCell colSpan={columns.length} style={{ "textAlign": "center" }}>
										{rows && <Typography id="noResults" variant='h6'>The search returned no results.</Typography>}
									</TableCell>
								</TableRow>
							) : (
								processRows(rows)
							)}
						</TableBody>
					</Table>
				</TableContainer>

				{paging === 'server' && (
					<TablePagination
						id="securitiesTablePagination"
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={-1}	// -1 enables server side paging
						rowsPerPage={rowsPerPage}
						labelDisplayedRows={({ page }) => {
							let lowRecordNum = page * rowsPerPage + 1;
							let highRecordNum = page * rowsPerPage + (rows?.length || 0);
							
							if ((rows?.length || 0) === 0) {
								return '';
							}

							return `${lowRecordNum} - ${highRecordNum} of ${totalRowCount || 0}`;
							}}
						nextIconButtonProps={((rows?.length || 0) < rowsPerPage) || (page * rowsPerPage + (rows?.length || 0) === totalRowCount)
							? {disabled: true} : undefined }
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}

				{paging === 'client' && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={(rows?.length || 0)}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</Paper>
		</div>
	);
}

export type SecuritiesTableFieldType =
	| "string"
	| "number"
	| "datetime"
	| "date"
	| "selectOne"
	| "selectMany"
	| "link"
	| "action"
	| "boolean"
	| "array"
	| "selectManyString";

export type Order = "asc" | "desc";

export enum TableFieldType {
	link,
	button,
	iconButton,
	iconLink,
	date,
}

export interface IColumnHeader {
	field: keyof ISecuritiesTableFieldMap;
	label: string;
	parameterName?: string; // the parameter name in the stored proc that accesses the data
	type?: string; // the data type
	enumValues?: IEnumDTO[]; // for type=enum, this contains the values to be displayed in the select box
	value?: string; // the current filter value
	allowFilter?: boolean;
	hideHeader?: boolean;
	minWidth?: string;
	default?: string;
	style?: any;
	hideSortIcon?: boolean;
}

export interface ISorter {
	property: keyof ISecuritiesTableFieldMap;
	order: Order;
}

export interface ISecuritiesTableField {
	type: SecuritiesTableFieldType;
	value: any;
	sortValue?: string | number | Date | boolean;
}

export interface ISecuritiesTableFieldMap {
	[name: string]: ISecuritiesTableField;
}
