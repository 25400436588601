import { all, call, put, takeLeading } from 'redux-saga/effects';
import {getClaimantStatusByIdListSuccess, getClaimantStatusByIdListFailed, GET_CLAIMANT_STATUS_BY_ID_LIST} from './cmx.actions';
import { getClaimantStatusByIdListAsync } from './cmx.api';
import { AlertType } from '../../types/enums';
import { showAlert } from '../Alerts/alerts.actions';

function* getClaimantStatusByIdListSaga({ payload: { cmxTrackingNumberList } }: any ): any {

	try {

		const claimantStatusList = yield call(getClaimantStatusByIdListAsync, cmxTrackingNumberList);
		yield put(getClaimantStatusByIdListSuccess(claimantStatusList));

	}

	catch (error: any) {

		yield put(getClaimantStatusByIdListFailed(error));

	}
}

function* getClaimantStatusByIdListWatcher() {
	yield takeLeading(GET_CLAIMANT_STATUS_BY_ID_LIST, getClaimantStatusByIdListSaga)
}

export default function* cmxSaga() {
	yield all([
		getClaimantStatusByIdListWatcher()
	])
};