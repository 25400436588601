import { useEffect, useState, useContext } from "react";
import { Button, Theme, Typography, Modal, Box } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import Popover from 'react-bootstrap/Popover';
import { IStringValue } from "../../types/types";
import { getInfoJsonWithArray, transformHelpUrl } from "../../utilities/utilities";
import { UserContext, UserContextType } from "../../modules/User/UserContext";
import { EpiqUser } from "../../constants/roles";
import { Strings } from "../../types/enums";

const useStyles = makeStyles((theme: Theme) => ({
    confirmationModalWrapper: {
        border: "none",
        borderRadius: "5px",
        height: "295px",
        padding: "32px 32px 32px 32px",
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "400px",
        background: "#fff",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px",
        '& h2': {
            fontSize: "24px",
            textAlign: "center"
        },
        '& p': {
            fontSize: "24px",
            marginTop: "0px",
            textAlign: "center"
        },
        '& button': {
            margin: "0 auto",
            display: "flex",
            background: "#006B86"
        }
    },

}));

interface IConfirmationMessageModalProps {
    open: boolean,
    handleClose: any;
}

let event: any;

const handleBackdropClick = () => {
    event.stopPropagation();
    return false;
};

const ConfirmationMessageModal = ({ open, handleClose }: IConfirmationMessageModalProps) => {
    const classes = useStyles();
    const [stringContentData, setStringContentData] = useState<IStringValue | null>();
    const [confirmationMessageStringContentData, setConfirmationMessageStringContentData] = useState<IStringValue | null>();
    const { isAuthenticated, isInitialized, isInRole } = useContext(UserContext) as UserContextType;

    useEffect(() => {
        const processInfoResponse = (data: any) => {
            data.map((item: IStringValue) => {
                switch (item.key) {
                    case Strings.ConfirmationMessageModalHeading:
                        item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                        setStringContentData(item);
                        break;
                    case Strings.ConfirmationMessageModal:
                        item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                        setConfirmationMessageStringContentData(item);
                        break;
                }
            });
        };

        const fetchInfoStrings = async () => {
            const data: any = await getInfoJsonWithArray([Strings.Reports,
            Strings.ConfirmationMessageModalHeading,
            Strings.ConfirmationMessageModal]);

            processInfoResponse(data);
        }

        fetchInfoStrings();
    }, [isAuthenticated, isInitialized, isInRole]);

    return (
        <Modal
            open={open}
            onClose={(e: Event) => { handleClose(); }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onBackdropClick={handleBackdropClick}
        >
            <Box className={classes.confirmationModalWrapper}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {stringContentData && (
                        <Popover.Body dangerouslySetInnerHTML={{ __html: stringContentData.value }}></Popover.Body>
                    )}
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {confirmationMessageStringContentData && (
                        <Popover.Body dangerouslySetInnerHTML={{ __html: confirmationMessageStringContentData.value }}></Popover.Body>
                    )}
                </Typography>

                <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleClose}
                >
                    OK
                </Button>
            </Box>
        </Modal>

    )
}

export default ConfirmationMessageModal;
