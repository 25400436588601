import { EpiqAdmin, EpiqTechSupport } from '../../constants/roles';
import { IInstitution, IRegisterUser, IInstitutionListState, IUserListState } from '../../types/types';

import { Button, Card, CardContent, Grid, Theme, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import SecuritiesTable,
{
    IColumnHeader,
    ISecuritiesTableFieldMap,
    ISorter
} from '../../components/Shared/SecuritiesTable/SecuritiesTable';
import { UserContext, UserContextType } from '../User/UserContext';
import {
    createFilterDTOsFromColumnHeaders,
    headerContainsFilterValues
} from '../../utilities/filterCriteria';
import { useDispatch, useSelector } from 'react-redux';

import ApproveModal from "./RegistrationUserApproveModal";
import { IFilterDTO } from '../../types/types';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { clearApproveDenyUserResponseAction } from './userlist.actions';
import { getInstitutionList } from '../Institutions/institutionlist.actions';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        background: "#006B86",
        size: 'small',
        margin: theme.spacing(1),
        color: 'white'
    },
    textLink: {
        color: theme.palette.text.primary,
        padding: '0.5em'
    },
    actionColumn: {
        width: '275px',
    },
    actionText: {
        color: "#006B86",
    }
}));

const institutionListFilterColumns: IColumnHeader[] = [
    {
        field: 'name',
        label: 'Institution Name',
        parameterName: 'name',
        type: 'string',
        value: '',
        allowFilter: true
    },
    {
        field: 'email',
        label: 'Group Email',
        parameterName: 'email',
        type: 'string',
        value: '',
        allowFilter: true
    },
    {
        field: 'type',
        label: 'Institution Type',
        parameterName: 'type',
        type: 'string',
        value: '',
        allowFilter: true
    },
    {
        field: 'address',
        label: 'Address',
        parameterName: 'address',
        type: 'string',
        value: '',
        allowFilter: true
    },
    {
        field: 'phone',
        label: 'Phone',
        parameterName: 'phone',
        type: 'string',
        value: '',
        allowFilter: true
    },
    {
        field: 'actions',
        label: 'Actions',
        hideHeader: true
    }
];

const defaultSort: ISorter = {
    property: 'name',
    order: 'desc'
};

let sortInfo: ISorter = { ...defaultSort };

interface IReviewInstitutionListGridProps {
    registrationUser?: IRegisterUser;
}

const ReviewInstitutionListGrid = (
    { registrationUser }: IReviewInstitutionListGridProps) => {
    const dispatch = useDispatch();
    const institutionListState: IInstitutionListState =
        useSelector((state: any) => state.institutionListState);
    const classes = useStyles();
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const { isAuthenticated, isInitialized, isInRole } = useContext(UserContext) as UserContextType;
    let [selectedInstitutionId, setSelectedInstitutionId] = useState(0);
    let approveModalTitle = 'Approve User and Assign to Institution';
    let approveModalBody = 'Please confirm the approval of this user. This approval will allow the user to submit claims on behalf of ';
    let [address, setAddress] = useState(<address></address>);
    let isCreateNewInstitution = false;
    let userListState: IUserListState = useSelector((state: any) => state.userListState);
    const initialFilter = headerContainsFilterValues(institutionListFilterColumns) ?
        createFilterDTOsFromColumnHeaders(institutionListFilterColumns) : [];

    useEffect(() => {
        dispatch(getInstitutionList({ filter: initialFilter }));
    }, [dispatch, isAuthenticated, isInitialized, isInRole ]);

    const fetchFilteredList = (filters: IFilterDTO[]) => {
        dispatch(getInstitutionList({ filter: filters }));
    }

    const approveUser = (institution: IInstitution) => {
        setSelectedInstitutionId(institution.id);
        setAddress(
            <address>
                {institution.name} <br />
                {institution.address1} <br />
                {
                    (institution?.address2)
                        ? <> {institution?.address2}<br /></>
                        : ``
                }
                {
                    (institution?.address3)
                        ? <> { institution?.address3}<br /></>
                        : ``
                }
                {institution.city},
                {institution.stateProvince},
                {institution.zipCode} <br />
                {institution.country} <br />
            </address>
        );

        setApproveModalOpen(true);
    };

    const handleResetFilter = () => {
        sortInfo = { ...defaultSort };
    };

    const handleApproveModalClose = () => {
        setApproveModalOpen(false);
        dispatch(clearApproveDenyUserResponseAction());
    };

    const buildListRows = (): ISecuritiesTableFieldMap[] => {
        var institutionListRows: ISecuritiesTableFieldMap[] = [];
        institutionListState &&
            institutionListState.institutionList &&
            institutionListState.institutionList.forEach((item: IInstitution) => {
            let arrAddress = [];
            arrAddress.push(item.address1);
            arrAddress.push(item.address2);
            arrAddress.push(item.address3);
            arrAddress.push(item.city);
            arrAddress.push(item.stateProvince);
            arrAddress.push(item.country);
            arrAddress.push(item.zipCode);

            let address = arrAddress.filter(val => val).join();

            let newItem: ISecuritiesTableFieldMap = {
                name: {
                    type: "string",
                    value: item.name || "",
                    sortValue: item.name
                },
                type: {
                    type: "string",
                    value: item.institutionTypeName || "",
                    sortValue: item.institutionTypeName
                },
                address: {
                    type: "string",
                    value:
                        <Tooltip
                            title={
                                <Typography style={{ fontSize: 14 }}>
                                    {address.toUpperCase()}
                                </Typography>
                            }
                            placement="top"
                        >
                            <span>
                                {item.address1}
                            </span>
                        </Tooltip>,
                    sortValue: item.normalizedAddress
                },
                email: {
                    type: "string",
                    value: item.emailAddress || "",
                    sortValue: item.emailAddress
                },
                phone: {
                    type: "string",
                    value: item.phone || "",
                    sortValue: item.phone
                },
                actions: {
                    type: "link",
                    value: <div className={classes.actionColumn}>
                      {    
                         isInRole([EpiqAdmin, EpiqTechSupport]) && userListState.error === null &&
                        <> 
                        <Button
                            variant="text"
                            size="small"
                            className={classes.actionText}
                            onClick={() => {approveUser(item);}}>
                            <Tooltip
                                title={`This will approve the user and assign them to: ${item.name}`}>
                                <Typography variant='button'>Approve</Typography>
                            </Tooltip>

                        </Button>
                        </>
                      }
                    </div>
                }
            }

            institutionListRows.push(newItem);
        });

        return institutionListRows;
    };

    const rows = buildListRows();

    return (
        <>
            {(institutionListState.isLoading === true) ?
                <Card>
                    <CardContent>
                        {[...Array(5)].map((elementInArray, index) => (
                            <Grid container spacing={2} key={index}>
                                <Grid item xs={2}>
                                    <Skeleton animation="wave" />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton animation="wave" />
                                </Grid>
                                <Grid item xs={2}>
                                    <Skeleton animation="wave" />
                                </Grid>
                                <Grid item xs={2}>
                                    <Skeleton animation="wave" />
                                </Grid>
                                <Grid item xs={2}>
                                    <Skeleton animation="wave" />
                                </Grid>
                            </Grid>
                        ))}
                    </CardContent>
                </Card>
                : <SecuritiesTable
                    columns={institutionListFilterColumns}
                    rows={rows}
                    sorter={sortInfo}
                    paging={'client'}
                    showFilter={true}
                    dataFetchFunc={fetchFilteredList}
                    resetFilterFunc={handleResetFilter}
                />}

            <ApproveModal
                AssociateInstitutionId={selectedInstitutionId}
                isCreateNewInstitution={isCreateNewInstitution}
                insertedBy={-1}
                selectedUser={registrationUser}
                title={approveModalTitle}
                body={approveModalBody}
                Address={address}
                modalOpen={approveModalOpen}
                handleClose={handleApproveModalClose}
            />
        </>
    )
};
export default ReviewInstitutionListGrid;