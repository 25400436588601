import { EpiqAdmin, EpiqTechSupport } from '../../constants/roles';
import { IRegistrationDetail, IUserListState } from "../../types/types";

import {
    Button,
    Divider,
    Grid,
    Stack,
    Theme,
    Tooltip,
    Typography
} from "@mui/material";
import React, { useContext, useState } from "react";
import { UserContext, UserContextType } from "../User/UserContext";

import ApproveModal from "./RegistrationUserApproveModal";
import DenyModal from "./RegistrationDenyUserDialog";
import { clearApproveDenyUserResponseAction } from "./userlist.actions";
import { getInstitutionTypeName } from "../../utilities/institutionType";
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme: Theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
    },
    buttonMargin: {
        margin: '10px',
    },
    button: {
        background: "#006B86",
        size: 'small',
        margin: theme.spacing(1),
        color: 'white'
    },
    textLink: {
        color: theme.palette.text.primary,
        padding: '0.5em'
    },
    commonbackgroundcard: {
        boxShadow: '0px 10px 15px -3px rgb(0 0 0 / 30%)',
        padding: '1.5rem 1rem 0.25rem',
        margin: '2rem 1rem 0',
        background: '#fff',
        borderRadius: '5px',
    },
    title: {
        flexGrow: 1,
        marginLeft: '1rem',
        color: theme.palette.grey[600]
    },
    reviewInfoHeader: {
        fontWeight: 'bold',
        color: theme.palette.text.primary,
    },
    cstmcardicon: {
        height: 38, width: 38, color: 'black'
    },
    actionText: {
        color: '#006B86',
    },
    
    detailText: {
        color: theme.palette.text.primary,
    }
}));


interface ReviewDetailFormProps {
    registrationDetail?: IRegistrationDetail;
}

export default function ReviewDetailForm({ registrationDetail }: ReviewDetailFormProps) {
	const classes = useStyles();
    const dispatch = useDispatch();
    const { user, isInRole } = useContext(UserContext) as UserContextType;
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const [denyModalOpen, setDenyModalOpen] = useState(false);
    let userListState: IUserListState = useSelector((state: any) => state.userListState);
    let selectedInstitutionId: number = 0;
    let approveModalTitle = 'Approve User and Create Institution';
    let approveModalBody = 'Please confirm the approval of this user.  This approval will allow the user to submit claims on behalf of';
    let [address, setAddress] = useState(<address></address>);
    let isCreateNewInstitution = true;
	
    const handleApproveModalClose = () => {
        setApproveModalOpen(false);
    };

    const handleDenyModalClose = () => {
		setDenyModalOpen(false);
		dispatch(clearApproveDenyUserResponseAction());
	};

    const approveUser = () => {
		if (registrationDetail) {
            approveAndCreateNewInstitution();
			setApproveModalOpen(true);
		}
	};

    const denyUser = () => {
		if (registrationDetail) {
			setDenyModalOpen(true);
		}
	};

    const approveAndCreateNewInstitution = () => {
        if (registrationDetail) {
            setAddress(     
                <address>
                    <b> {registrationDetail?.institutionName} </b> <br />
                    {registrationDetail?.address1} <br />
                    {(registrationDetail?.address2 !== "" ) ? <> {registrationDetail?.address2 }<br /></> : `` } 
                    {(registrationDetail?.address3 !== "" ) ? <> {registrationDetail?.address3 }<br /></>  : `` }
                    {registrationDetail?.city},  {registrationDetail?.stateProvince},  {registrationDetail?.zipCode}<br />
                    {registrationDetail?.country} <br />
                </address>
            );

            setApproveModalOpen(true);
		}
    };

    return (
        <>
        <Grid container direction="row" justifyContent="flex-end">
            <Stack direction="row" spacing={1}>
            {
                isInRole([EpiqAdmin, EpiqTechSupport]) && userListState.error === null &&
                <Button
                    	variant="contained"
						color="primary"
						size="small"
						className="btn-primary"
                        onClick={denyUser}
                >
                    <Tooltip
                        title="This will deny the user's registration request. They will not be able to login to the system or submit claims.">
                        <Typography >Deny Request</Typography>
                    </Tooltip>
                </Button>
            }
            </Stack>
        </Grid>

        <Typography variant="body1" className={classes.title} noWrap>
            User Information
        </Typography>

        <Grid container direction="row" spacing={2} margin={1}>
            <Grid item xs={2.5}>
                <Typography className={classes.reviewInfoHeader}> First Name </Typography>
                <Typography className={classes.detailText} variant="body2">{registrationDetail && registrationDetail.user ? registrationDetail.user.firstName : ""}</Typography>
            </Grid>
            <Grid item xs={2.5}>
                <Typography className={classes.reviewInfoHeader}> Last Name </Typography>
                <Typography className={classes.detailText} variant="body2">{registrationDetail && registrationDetail.user ? registrationDetail.user.lastName : ""}</Typography>
            </Grid>
            <Grid item xs={2.5} >
                <Typography className={classes.reviewInfoHeader}> User Email </Typography>
                <Typography className={classes.detailText} variant="body2">{registrationDetail && registrationDetail.user ? registrationDetail.user.email : ""}</Typography>
            </Grid>
            <Grid item xs={2.5}>
                <Typography className={classes.reviewInfoHeader}> Direct Phone </Typography>
                <Typography className={classes.detailText} variant="body2">{registrationDetail && registrationDetail.user ? registrationDetail.user.phone : ""}</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography className={classes.reviewInfoHeader}> Extension </Typography>
                <Typography className={classes.detailText} variant="body2">{registrationDetail && registrationDetail.user ? registrationDetail.user.extension : ""}</Typography>
            </Grid>
        </Grid>
        <Divider />
        <br />
        <Typography variant="body1" className={classes.title} noWrap>
            Institution Information
        </Typography>
        <Grid container direction="row" margin={1} spacing={2}>
            <Grid item xs={12} sm={2.5}>
                <Typography className={classes.reviewInfoHeader}> Institution Name </Typography>
                <Typography className={classes.detailText} variant="body2">{registrationDetail ? registrationDetail.institutionName : ""}</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
                <Typography className={classes.reviewInfoHeader}> Institution Type </Typography>
                <Typography className={classes.detailText} variant="body2">{registrationDetail ? getInstitutionTypeName(registrationDetail.institutionTypeId) : ""}</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
                <Typography className={classes.reviewInfoHeader}> Address </Typography>
                <Typography className={classes.detailText} variant="body2">{registrationDetail ?
                <>
                {registrationDetail.address1}<br /> 
                {(registrationDetail?.address2 !== "" ) ? <> {registrationDetail?.address2 }<br /></> : `` } 
                {(registrationDetail?.address3 !== "" ) ? <> {registrationDetail?.address3 }<br /></>  : `` }
                {registrationDetail.city}, {registrationDetail.stateProvince}, {registrationDetail.zipCode}<br />
                </> : ""}
                </Typography>
            </Grid>

            <Grid item xs={12} sm={3}>
                <Typography className={classes.reviewInfoHeader}> Email </Typography>
                <Typography className={classes.detailText} variant="body2">{registrationDetail ? 
                registrationDetail.institutionEmail : ""}</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
                <Typography className={classes.reviewInfoHeader}> Phone </Typography>
                <Typography className={classes.detailText} variant="body2">{registrationDetail ? 
                registrationDetail.phone : ""}</Typography>
            </Grid>
            
        </Grid>

        <Grid container direction="row" justifyContent="flex-end">
            <Stack direction="row" spacing={1}>
            {    
                isInRole([EpiqAdmin, EpiqTechSupport]) && userListState.error === null &&
                <>
                <Button
                    	variant="contained"
						size="small"
                        color="primary"
						className="btn-primary"
                    onClick={approveUser}
                >
                    <Tooltip
                        title={`This will approve the user and create a New Institution: ${registrationDetail ? registrationDetail.institutionName : ''}`}>
                        <Typography>Approve</Typography>
                    </Tooltip>
                </Button>
                </>
            }
            </Stack>
        </Grid>

              <ApproveModal
                    AssociateInstitutionId={selectedInstitutionId}
                    isCreateNewInstitution={isCreateNewInstitution}
                    insertedBy={-1}
                    selectedUser={registrationDetail ? registrationDetail.user : undefined}
                    title={approveModalTitle}
                    body={approveModalBody}
                    Address={address}
                    modalOpen={approveModalOpen}
                    handleClose={handleApproveModalClose}
                />
   
            <DenyModal
                deniedUser={registrationDetail ? registrationDetail.user : undefined}
                dialogOpen={denyModalOpen}
                handleDialogClose={handleDenyModalClose}
            />
        </>
    );
}
