import { ICmxTrackingNumberList } from '../../types/types';
import authService from '../../components/api-authorization/AuthorizeService';

export const getClaimantStatusByIdListAsync = async (trackingNumbers: ICmxTrackingNumberList) => {
	const token = await authService.getAccessToken();

	try {
		let headers = new Headers({
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		});

		let response = await fetch(`api/cmx/claimStatus/`, {
			method: 'POST',
			headers: headers,
			body: JSON.stringify(trackingNumbers)
		});

		if (!response.ok) {
			throw new Error("Failed to retrieve Cmx claimant status");
		}

		const res = await response.json();
		return res;
	}

	catch (error) {
		console.error(error);
		throw error;
	}
}


