import { ICaseState} from "../../types/types";
import React, { useState, useEffect, useRef } from "react";
import { getCaseById, getCaseTemplate } from "./cases.actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { clearCaseSubmissionsListAction } from "../Submissions/submissions.actions";
import { AlertType, CaseDetailTabTitle} from "../../types/enums";
import CaseDetailForm from "./CaseDetailForm";
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { showAlert } from "../Alerts/alerts.actions";
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme: Theme) => ({
	commonbackgroundcard: {
		boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
		padding: '1.5rem 1rem 0.25rem',
		margin: '1rem 1rem 0 0',
		background: '#fff',
		borderRadius: "2px",
		position: "relative",
		"& tbody tr": {
			borderBottom: "1px solid rgba(224, 224, 224, 1)"
		},
		"& thead tr th:first-child": {
			width: "11%"
		},
		"& thead tr th:nth-child(2)": {
			width: "12%"
		},
		"& thead tr th:nth-child(3)": {
			width: "12%"
		},
		"& thead tr th:nth-child(3) > div": {
			width: "100%"
		},
		"& thead tr th:nth-child(4)": {
			width: "17%"
		},
		"& thead tr th:nth-child(4) > div": {
			width: "100%"
		},
		"& thead tr th:nth-child(5)": {
			width: "12%"
		},
		"& thead tr th:nth-child(6)": {
			width: "12%"
		},
		"& thead tr th:nth-child(7)": {
			width: "8%"
		},
		"& thead tr th:nth-child(7) span svg": {
			display: "none"
		},
		"& thead tr th:nth-child(7) p": {
			display: "none"
		},
		"& thead tr th:last-child": {
			width: "16%"
		},
		"& thead tr th:last-child div": {
			minWidth: "100%"
		},
		"& tbody tr td:first-child": {
			width: "11%",
			paddingLeft: "20px !important"
		},
		"& tbody tr td:nth-child(2)": {
			width: "12%",
			textAlign: "left"
		},
		"& tbody tr td:nth-child(3)": {
			width: "17%",
			textAlign: "left"
		},
		"& tbody tr td:nth-child(4)": {
			width: "17%",
			textAlign: "left"
		},
		"& tbody tr td:nth-child(5)": {
			width: "12%",
			textAlign: "left"
		},
		"& tbody tr td:nth-child(6)": {
			width: "12%",
			textAlign: "left"
		},
		"& tbody tr td:nth-child(7)": {
			width: "8%",
			textAlign: "left"
		},
		"& tbody tr td:last-child": {
			width: "8%"
		},
		"& tbody tr td:last-child li": {
			float: "left",
			padding: "0",
			marginRight:"5px"
		},
		"@media (max-width: 1368px)": {
			"& thead tr th span": {
				fontSize: "0.8rem"
			},
			"& tbody tr td": {
				fontSize: "0.8rem"
			},
			"& tbody tr td a": {
				fontSize: "0.8rem"
			},
			"& thead tr th:first-child": {
				width: "13%"
			},
			"& thead tr th:nth-child(2)": {
				width: "14.5%"
			},
			"& thead tr th:nth-child(3)": {

				width: "14.5%"
			},
			"& thead tr th:nth-child(4)": {
				width: "14.5%"
			},
			"& thead tr th:nth-child(5)": {
				width: "14.5%"
			},
			"& thead tr th:nth-child(6)": {
				width: "12.5%"
			},
			"& thead tr th:last-child": {
				width: "16.5%"
			},
			"& thead tr th:last-child div": {
				minWidth: "100%"
			},
			"& tbody tr td:first-child": {
				width: "13%"
			},
			"& tbody tr td:nth-child(2)": {
				width: "14.5%",
				textAlign: "left"
			},
			"& tbody tr td:nth-child(3)": {
				width: "14.5%",
				textAlign: "left"
			},
			"& tbody tr td:nth-child(4)": {
				width: "14.5%",
				textAlign: "left"
			},
			"& tbody tr td:nth-child(5)": {
				width: "14.5%",
				textAlign: "left"
			},
			"& tbody tr td:nth-child(6)": {
				width: "12.5%",
				textAlign: "left"
			},
			"& tbody tr td:last-child": {
				width: "16.5%"
			},
		},
	},
	title: {
		flexGrow: 1,
		marginBottom: '15px',
		display: 'inline-flex',
		marginRight: "5px"
	},
	dashboardIconBackground: {
		fontSize: "20px"
	}
}));

export default function CaseDetailPage() {

	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	let caseState: ICaseState = useSelector((state: any) => state.caseState);
	let { caseId } = useParams<{ caseId: string }>();
	document.title = CaseDetailTabTitle.Title;
	const ref = useRef(null);
	const location = useLocation();

	useEffect(() => {
		const path = location.pathname + location.search;
		ReactGA.send({ hitType: "pageview", page: path, title: "Case Details Page" });
	}, [location]);

	useEffect(() => {
		const caseIdNumber = Number(caseId);

		if (isNaN(caseIdNumber)) {
			dispatch(showAlert({
				hidden: false,
				message: `The requested case id is invalid.`,
				severity: AlertType.error
			}))

			history.push('/CaseList')
			return;
		}

		dispatch(clearCaseSubmissionsListAction());
		dispatch(getCaseById({ caseId: caseIdNumber, history: history }));
	}, [dispatch, caseId, history]);


	useEffect(() => {

		if (caseState?.needsRefresh) {
			dispatch(clearCaseSubmissionsListAction());
			dispatch(getCaseById({ caseId: Number(caseId), history: history }));
		}
		
	}, [dispatch, caseState?.needsRefresh, caseId, history])

	useEffect(() => {

		if (caseState.selectedCaseDetail) {
			dispatch(getCaseTemplate(caseId));
		}

	}, [dispatch, caseId, caseState.selectedCaseDetail])


	return (
		<div className={classes.commonbackgroundcard} ref={ref}>
				<CaseDetailForm
					caseDetail={caseState?.selectedCaseDetail}
					caseBookDetails={caseState?.selectedCaseDetail}
					template={caseState?.selectedCaseTemplate}
					isLoading={caseState ? caseState.isLoading : true}
				/>
		</div>
	);
}