import React, { Fragment, useEffect, useContext, useRef, useState, } from 'react';
import { IStringValue } from '../../types/types';
import { getInfoJsonWithArray, transformHelpUrl } from "../../utilities/utilities";
import { Link as MuiLink, Button } from '@mui/material';
import { Strings } from "../../types/enums";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { EpiqUser, Nominee } from '../../constants/roles';
import { UserContext, UserContextType } from '../../modules/User/UserContext'
import { useDispatch } from "react-redux";


import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	title: {
		flexGrow: 1,
		marginBottom: '15px',
		display: 'inline-flex',
		marginRight: "5px"
	},
	floatLeft: {
		float: 'left',
		width: '100%'
	},
	floatRight: {
		float: 'right',
	},
	dashboardIconBackground: {
		fontSize: "20px"
	}
}));

export default function AdminPageAppBar() {
	const classes = useStyles();
	const { isAuthenticated, isInitialized, isInRole } = useContext(UserContext) as UserContextType;
	let [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const ref = useRef(null);
	const [stringContentData, setStringContentData] = useState<IStringValue | null>();


	const handleClick = (event: any) => {
		setShow(!show);
		setTarget(event.target);
	};

	useEffect(() => {
		const processInfoResponse = (data: any) => {
			data.map((item: IStringValue) => {
				switch (item.key) {
					case Strings.Admin:
						item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
						setStringContentData(item);
						break;


					default:
						break;
				}
			});
		};

		const fetchInfoStrings = async () => {
			const data: any = await getInfoJsonWithArray([
				Strings.Admin,
			]);

			processInfoResponse(data);
		};


		if (isInitialized && isAuthenticated) {
			fetchInfoStrings();
		}
	}, [isInRole, isAuthenticated, isInitialized]);

	return (
		<Fragment>
			<div className={classes.floatLeft} ref={ref}>

				<Typography variant="h6" className={classes.title} noWrap>
					Admin
				</Typography>

				<Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent: "left", minWidth: "25px" }} onClick={handleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} /></Button>

				<Overlay
					show={show}
					target={target}
					placement="right-start"
					container={ref}
					containerPadding={20}
					rootClose
					onHide={() => setShow(false)}
				>
					<Popover id="popover-contained">
						<Popover.Header as="h3"><span style={{ color: "#000" }}>Admin</span></Popover.Header>
						{stringContentData && (
							<Popover.Body dangerouslySetInnerHTML={{ __html: stringContentData.value }}></Popover.Body>
						)}
					</Popover>
				</Overlay>
			</div>
		</Fragment>
	)
}