import { AlertType } from '../../types/enums';
import { IAction, ICase, IImportCase } from '../../types/types';
import {
	GET_CASES,
	GET_CASE_BY_ID,
	DOWNLOAD_CASE_TEMPLATE,
	GET_CASE_TEMPLATE,
	IMPORT_CASE,
	SAVE_CASE,
	getCaseByIdFailed,
	getCaseByIdSuccess,
	downloadCaseTemplateFailed,
	downloadCaseTemplateSucceeded,
	getCaseTemplateFailed,
	getCaseTemplateSuccess,
	getCasesFailed,
	getCasesSuccess,
	importCaseFailed,
	importCaseSuccess,
	saveCaseFailed,
	saveCaseSuccess
} from './cases.actions';
import { all, call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { clearCaseSearch, getCaseDetailById } from '../Casebook/casebook.actions';
import {
	getCaseById,
	downloadCaseTemplate,
	getCaseTemplate,
	getCases,
	importCase,
	saveCase
} from './cases.api';

import { getCasebookCaseForImportById } from '../Casebook/casebook.api';
import { showAlert } from '../Alerts/alerts.actions';

function* saveCaseSaga({ payload: { caseInfo } }: any): any {
	try {
		yield call(saveCase, caseInfo);
		yield put(saveCaseSuccess(null));
		yield put(showAlert({
			hidden: false,
			message: `Case saved successfully.`,
			severity: AlertType.success
		}));
	} catch (error: any) {
		yield put(saveCaseFailed(error.message));
		yield put(showAlert({
			hidden: false,
			message: "There was an error saving the case.",
			severity: AlertType.error
		}));
	}
}

function* saveCaseWatcher() {
	yield takeLeading(SAVE_CASE, saveCaseSaga)
}

function* getCasesSaga({ payload: { filter, showPastFilingDeadline } }: any): any {
	try {
		let data = yield call(getCases, filter, showPastFilingDeadline);

		yield put(getCasesSuccess(data));
	} catch (error: any) {
		yield put(getCasesFailed(error.message));
	}
}

function* getCasesWatcher() {
	yield takeEvery(GET_CASES, getCasesSaga)
}

function* getCaseByIdSaga({ payload: { caseId, history } }: any): any {
	try {
		// Get case details from Securities.
		const caseDetails = (yield call(getCaseById, caseId)) as ICase;

		// Get case details from Casebook.
		yield put(getCaseDetailById(caseDetails.casebookCaseDetailId));

		yield put(getCaseByIdSuccess(caseDetails));
	} catch (error: any) {
		yield put(showAlert({
			hidden: false,
			message: "There was an error retrieving the case.",
			severity: AlertType.error
		}));

		history.push('/CaseList');
		yield put(getCaseByIdFailed(error.message));
	}
}

function* getCaseByIdWatcher() {
	yield takeLeading(GET_CASE_BY_ID, getCaseByIdSaga);
}

function* getCaseTemplateSaga(action: IAction): any {
	try {
		const template = yield call(getCaseTemplate, action.payload);
		yield put(getCaseTemplateSuccess(template));
	} catch (error: any) {
		yield put(getCaseTemplateFailed(error.message));
	}
}

function* getCaseTemplateWatcher() {
	yield takeLeading(GET_CASE_TEMPLATE, getCaseTemplateSaga);
}

function* downloadCaseTemplateSaga(action: any): any {
	try {
		yield call(downloadCaseTemplate, action.caseId);
		yield put(downloadCaseTemplateSucceeded());

	} catch (error: any) {
		yield put(downloadCaseTemplateFailed(error.message));
		yield put(showAlert({
			hidden: false,
			message: "There was an error downloading the case template.",
			severity: AlertType.error
		}));
	}
}

function* downloadCaseTemplateWatcher() {
	yield takeLeading(DOWNLOAD_CASE_TEMPLATE, downloadCaseTemplateSaga);
}

function* importCaseSaga({ payload: { caseDetailId, userId, history } }: IAction): any {
	try {
		// Get the case to import from Casebook.
		const caseToImport: IImportCase = yield call(getCasebookCaseForImportById, caseDetailId);
		caseToImport.insertedByUserId = userId;

		// Import the case
		const theCase: any = yield call(importCase, caseToImport);

		// Clear the search results and indicate success.
		yield put(clearCaseSearch());
		yield put(importCaseSuccess());
		yield put(showAlert({
			hidden: false,
			message: `Case imported`,
			severity: AlertType.success
		}));
		history.push(`/CaseDetail/${theCase.id}`);
	} catch (error: any) {
		yield put(importCaseFailed(error));
		yield put(showAlert({
			hidden: false,
			message: `"Case was not able to be imported. Please try again later - ${error.message}`,
			severity: AlertType.error
		}));
	}
};

function* importCaseWatcher() {
	yield takeLeading(IMPORT_CASE, importCaseSaga);
}

export default function* casesSaga() {
	yield all([getCasesWatcher(),
	getCaseByIdWatcher(),
	getCaseTemplateWatcher(),
	importCaseWatcher(),
	downloadCaseTemplateWatcher(),
	saveCaseWatcher()
	]);
}