import {Box, Button} from "@mui/material";

import { Link } from 'react-router-dom';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    errortxtgray: {
      color:'#888896'
      },
      buttonMargin: {
        margin: '10px',
    },
    button: {
        background: "#006B86",
        size: 'small',
        margin: theme.spacing(1),
        color: 'white'
    },
  }));
  
const ErrorPage = () => {
    const classes = useStyles();
    return(
    <div className="abs-center wd-xl">
        <Box className="text-center" sx={{ m: "7rem" }}>
            <Box className="m-3">
            <img src="../../img/ghost.png" alt="404"></img>
            </Box>
            <Box sx={{ m: "2rem" }} >
            <img src="../../img/ghostshadow.png" alt="404"></img>
            </Box>
      
            <Box className={classes.errortxtgray} >
            <h1>Whoops,</h1>
            <h3>We couldn't find the page you were looking for, or you don't have access.</h3>
            </Box>
            <Box sx={{ m: "2rem" }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    className="btn-primary"
                >
                    <Link
                    color="primary"
                    className="btn-primary" 
                    to="/Dashboard">
                        Back to the Landing Page
                    </Link>
                </Button>
            </Box>
        </Box>

    </div>
    )
}

export default ErrorPage;
