import React, { useState, useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { Button, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { UserContext, UserContextType } from '../../modules/User/UserContext'
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from '../../modules/Alerts/alerts.actions';
import { AlertType, Strings } from '../../types/enums';
import { callGetStringsByKeys } from '../../utilities/utilities';
import { IStringValue } from '../../types/types';

export function LoginMenu() {
	const { isAuthenticated, isInitialized } = useContext(UserContext) as UserContextType;

	return (
		<>
			{isInitialized ?
				<>
					{isAuthenticated
						? <AuthenticatedView />
						: <AnonymousView />}
				</>
				:
				null
			}

		</>
	);
};

const AuthenticatedView = () => {
	const profilePath = `${ApplicationPaths.Profile}`;
	const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
	let [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { isAuthenticated, isInitialized, user } = useContext(UserContext) as UserContextType;

	const handleAccountMenuClose = () => {
		setAnchorEl(null);
	};

	const handleAccountMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
		setAnchorEl(event.currentTarget);
	};

	const settingsState: any = useSelector((state: any) => state.settings);

	const dispatch = useDispatch();

	useEffect(() => {
		if (isInitialized && isAuthenticated) {
			(async () => {
				try {
					await callGetStringsByKeys(JSON.stringify([Strings.AlertCountryCodeInvalid]))
						.then((response: IStringValue[] | null) => {
							let alertCountryCodeInvalidMessage = response ? response[0].value : ""

							if (user.id && user.countryCode < 1 && alertCountryCodeInvalidMessage) {
								dispatch(showAlert({
									hidden: false,
									message: alertCountryCodeInvalidMessage,
									severity: AlertType.error
								}))
							}
							else {
								dispatch(showAlert({
									hidden: true,
									message: '',
									severity: AlertType.info
								}))
							}
						});
				} catch (err) {
					console.log('Error occured when fetching books');
				}
			})();
		}
	}, [])

	return (
		<div>
			<Tooltip title={"Help"}>
				<IconButton
					size="small"
					color="inherit"
					style={{ marginRight: "4px" }}
					onClick={async () => {
						window.open(settingsState.settings.helpBaseUrl, '_blank');
						return false;
					}}
				>
					<HelpOutlineIcon />
				</IconButton>
			</Tooltip>

			<Button
				aria-label="account of current user"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				onClick={handleAccountMenuOpen}
				color="inherit"
				size="large" sx={{ background: 'none !important' }}>
				{user ? user.name : ''}
			</Button>

			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleAccountMenuClose}>
				<MenuItem component={RouterLink} to={profilePath}>Profile</MenuItem>
				<MenuItem component={RouterLink} to={logoutPath}>Log Out</MenuItem>
			</Menu>
		</div>
	);
};

const AnonymousView = () => {
	const registerPath = `${ApplicationPaths.Register}`;
	const loginPath = `${ApplicationPaths.Login}`;

	return (
		<>
			<Button color="inherit" component={RouterLink} to={registerPath}>Register</Button>
			<Button color="inherit" component={RouterLink} to={loginPath}>Login</Button>
		</>
	);
};
