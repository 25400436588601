import { GET_NOMINEE_CARD_COUNT, getNomineeCardCountsFailure, getNomineeCardCountsSuccess } from './dashboard.actions';
import { all, call, put, takeLeading } from 'redux-saga/effects';
import { AlertType } from '../../types/enums';

import { getNomineeCardCounts } from './dashboard.api';
import { showAlert } from '../Alerts/alerts.actions';

function* getNomineeCardCountsSaga({ payload: { userId , isUserSubmissions } }: any): any {

	try {
		const counts = yield call(getNomineeCardCounts, userId, isUserSubmissions);
		yield put(getNomineeCardCountsSuccess(counts));
	}
	catch (error: any) {
		yield put(getNomineeCardCountsFailure(error.message));
	}
}

function* getNomineeCardCountsWatcher() {
    yield takeLeading(GET_NOMINEE_CARD_COUNT, getNomineeCardCountsSaga);
}

export default function* nomineeCardCountsSaga() {
    yield all([getNomineeCardCountsWatcher()]) //only one of these right now. add more as needed
}