import { Fab, Theme } from '@mui/material';
import { useState, useEffect, useContext, useRef } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Typography, Button } from '@mui/material';
import InstitutionListGrid from './InstitutionListGrid';
import makeStyles from '@mui/styles/makeStyles';
import { InstitutionListPageTitle, Strings } from '../../types/enums';
import { IInstitutionListState, IStringValue } from '../../types/types';
import { getInfoJsonWithArray, transformHelpUrl } from "../../utilities/utilities";
import { useSelector } from 'react-redux';
import { TECHNICAL_ERROR_MESSAGE } from '../../constants/CommonConstants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { UserContext, UserContextType } from '../../modules/User/UserContext'
import { EpiqAdmin, EpiqTechSupport, EpiqUser, Nominee } from '../../constants/roles';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    fab: {
        background: 'none',
        color: '#006B86',
        boxShadow: 'none',
        width: 'auto',
        height: 'auto',
        "&:hover": {
            background: 'none',
        },
        "&:focus": {
            outline: 'none',
            background: 'none',
            boxShadow: 'none',
        },
        "&:active": {
            outline: 'none',
            background: 'none',
            boxShadow: 'none',
        },
        "&:disabled": {
            display: 'none',
        },
    },
    commonbackgroundcard: {
        boxShadow: '0px 10px 15px -3px rgb(0 0 0 / 30%)',
        padding: '1.5rem 1rem 0.25rem',
        margin: '2rem 1rem 0',
        background: '#fff',
        borderRadius: '5px',
    },
    title: {
        flexGrow: 1,
        marginBottom: "15px",
        display: 'inline-flex',
        marginRight: "5px"
    },
    floatLeft: {
        float: 'left'
    },
    floatRight: {
        float: 'right',
    },
    headOuter: {
        display: 'inline-block',
        width: '100%',
    },
    dashboardIconBackground: {
        fontSize: "20px"
    }
}));

const InstitutionListPage = () => {
    const classes = useStyles();
    document.title = InstitutionListPageTitle.Title;
    const institutionListState: IInstitutionListState = useSelector((state: any) => state.institutionListState);
    let [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const [stringContentData, setStringContentData] = useState<IStringValue | null>();
    const { isAuthenticated, isInitialized, isInRole } = useContext(UserContext) as UserContextType;
    const location = useLocation();

    useEffect(() => {
        const path = location.pathname + location.search;
        ReactGA.send({ hitType: "pageview", page: path, title: "Institutions List Page" });
    }, [location]);

    const handleClick = (event: any) => {
        setShow(!show);
        setTarget(event.target);
    };

    const processInfoResponse = (data: any) => {
        data.map((item: IStringValue) => {
            switch (item.key) {
                case Strings.Admininstitutions:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setStringContentData(item);
                    break;


                default:
                    break;
            }
        });
    };

    useEffect(() => {
        const fetchInfoStrings = async () => {
            const data: any = await getInfoJsonWithArray([
                Strings.Admininstitutions,
            ]);

            processInfoResponse(data);
        };


        if (isInitialized && isAuthenticated) {
            fetchInfoStrings();
        }
    }, [isAuthenticated, isInitialized]);

    const history = useHistory();

    const addInstitution = () => {
        history.push('InstitutionDetail/0');
    };

    return (
        <div className={classes.commonbackgroundcard}>
            <div className={classes.headOuter}>
                <div className={classes.floatLeft} ref={ref}>
                    <Typography variant="h6" className={classes.title} noWrap>
                        Institutions
                    </Typography>
                    <Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent: "left", minWidth: "25px" }} onClick={handleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} /></Button>
                    <Overlay
                        show={show}
                        target={target}
                        placement="right-start"
                        container={ref}
                        containerPadding={20}
                        rootClose
                        onHide={() => setShow(false)}
                    >
                        <Popover id="popover-contained">
                            <Popover.Header as="h3"><span style={{ color: "#000" }}>Institutions</span></Popover.Header>
                            {stringContentData && (
                                <Popover.Body dangerouslySetInnerHTML={{ __html: stringContentData.value }}></Popover.Body>
                            )}
                        </Popover>
                    </Overlay>
                </div>

                <div className={classes.floatRight}>
                    <Fab color="secondary" onClick={addInstitution} className={classes.fab} aria-label="add" disabled={!isInRole([EpiqAdmin, EpiqTechSupport])}>
                        <AddIcon /> <span style={{ color: '#000', fontSize: '15px', fontWeight: '500' }}>add institution</span>
                    </Fab>
                </div>
            </div>

            <InstitutionListGrid />

            {institutionListState.error && <p style={{ color: 'red' }}>{TECHNICAL_ERROR_MESSAGE}</p>}
        </div>
    )
};

export default InstitutionListPage;

