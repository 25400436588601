import * as React from 'react';
import { IApproveUserCriteria, IApproveUserResponse, IRegisterUser } from '../../types/types';
import { Theme, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { approveUser } from './userlist.actions';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        minWidth: "500px",
        maxwidth: "700px"
    }
}));

interface IModalDialogueProps {
    title: string,
    body: any,
    modalOpen: boolean,
    handleClose: any,
    selectedUser?: IRegisterUser,
    AssociateInstitutionId: number,
    isCreateNewInstitution: boolean,
    insertedBy: number,
    Address: any
}
const ScrollDialog = ({
    Address,
    isCreateNewInstitution,
    insertedBy,
    AssociateInstitutionId,
    selectedUser,
    title,
    body,
    modalOpen,
    handleClose }: IModalDialogueProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const approveUserResponse: IApproveUserResponse = useSelector((state: any) => state.userListState.approveUserResponse);
    const descriptionElementRef = React.useRef<HTMLElement>(null);

    const handleSubmitApproveUser = () => {

        if (!selectedUser)
            return;

        let criteria: IApproveUserCriteria = { insertedBy: insertedBy, associateInstitutionId: AssociateInstitutionId, isCreateNewInstitution: isCreateNewInstitution, registrationId: selectedUser.registrationId };
        dispatch(approveUser(criteria));
    };

    React.useEffect(() => {

        if (!modalOpen)
            return;

        const { current: descriptionElement } = descriptionElementRef;

        if (descriptionElement)
            descriptionElement.focus();

    }, [modalOpen]);

    React.useEffect(() => {

        if (!approveUserResponse)
            return;

        handleClose(false);
        
    }, [approveUserResponse, dispatch]);

    const bodytext = "Please confirm the approval of this user. This approval will allow the user to submit claims on behalf of a new institution."
    const approveusertext = bodytext.split('.')

    return (
        <div>
            <Dialog
                open={modalOpen}
                onClose={handleClose}
                fullWidth
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                classes={{ paper: classes.paper }}
            >
                <DialogTitle id="scroll-dialog-title"
                    className="cmn-dialogtitle"
                >
                    {title}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        component="span"
                        tabIndex={-1}
                        className="cmn-dialogtext">
                        <Typography
                            component="span"
                            style={{ wordWrap: "break-word" }}
                            color="black"
                            className="cmn-dialogtext"
                        >
                            <p> {approveusertext[0]}.</p>
                            <p> {approveusertext[1]}</p>

                            <br />
                            <p className="bold-text">{Address} </p>
                        </Typography>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit"
                        className="cmn-secbtn"
                        size="small"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        className="btn-primary"
                        variant="contained"
                        onClick={handleSubmitApproveUser}
                    >
                        Approve
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ScrollDialog;