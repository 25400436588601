import { INomineeCard } from "../../types/types";

export const GET_NOMINEE_CARD_COUNT = "GET_NOMINEE_CARD_COUNT"
export const GET_NOMINEE_CARD_COUNT_SUCCESS = "GET_NOMINEE_CARD_COUNT_SUCCESS";
export const GET_NOMINEE_CARD_COUNT_FAILURE = "GET_NOMINEE_CARD_COUNT_FAILURE";

export const getNomineeCardCountsAction = (payload: any) => ({
	type: GET_NOMINEE_CARD_COUNT,
	payload
});

export const getNomineeCardCountsSuccess = (payload: INomineeCard) => {
	return {
		type: GET_NOMINEE_CARD_COUNT_SUCCESS,
		payload
	};
};

export const getNomineeCardCountsFailure = (payload: any) => {
	return {
		type: GET_NOMINEE_CARD_COUNT_FAILURE,
		payload
	};
};