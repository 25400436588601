import { CardActionArea } from '@mui/material';
import React, { useEffect, useContext } from 'react';
import { Route } from 'react-router';
import AdminPageAppBar from './AdminAppBar';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import DomainIcon from '@mui/icons-material/Domain';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import Grid from "@mui/material/Grid";
import GroupIcon from '@mui/icons-material/Group';
import IconButton from '@mui/material/IconButton';
import { PieChart } from "react-minimal-pie-chart";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import WorkOutlinedIcon from '@mui/icons-material/WorkOutlined';
import makeStyles from '@mui/styles/makeStyles';
import { UserContext, UserContextType } from '../../modules/User/UserContext';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminCardCounts } from './admin.actions';
import { IAdminCardCountsState } from '../../types/types';
import { AdminTabTitle } from '../../types/enums';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({

	adminBackground: {
		paddingBottom: 30,
	},
	commonBackgroundCard: {
		boxShadow: 'none',
		padding: '1.5rem 1rem',
		margin: '1rem 1rem 0 0',
		background: '#E9EBEC',
		borderRadius: '5px',
	},
	card: {
		margin: 10,
		display: 'inline-block',
		width: 200,
		alignItems: 'center',
		justifyContent: 'space-evenly',
		verticalAlign: 'top',
		boxSizing: 'border-box',
		boxShadow: '0px 9px 9px -4px rgba(0,0,0,0.4)',
		borderRadius: '5px',
	},
	userCard: {
		width: '400px',
		margin: 10,
		alignItems: 'center',
		justifyContent: 'space-evenly',
		verticalAlign: 'top',
		boxSizing: 'border-box',
	},
	uChart: {
		margin: 20
	},
	uBadgeDot: {
		height: 15,
		width: 15,
		verticalAlign: 'middle',
	},
	totalCountText: {
		float: 'right',
	},
	uChartType: {
		fontSize: '14px',
		fontFamily: 'sans-serif',
		textAlign: 'left',
		marginBottom: 10,
	},
	countText: {
		position: 'absolute',
		marggin: '0 auto',
		fontSize: '36px',
		fontWeight: '800',
		textAlign: 'center',
		width: '100%',
	},
	cstmCardIcon: {
		height: 38,
		width: 38,
		color: 'black'
	}
}));

export default function AdminOverviewPage() {
	const { isInitialized } = useContext(UserContext) as UserContextType;
	const adminCardCountsState: IAdminCardCountsState = useSelector((state: any) => state.adminCardCountsState);
	const dispatch = useDispatch();
	const classes = useStyles();
	const location = useLocation();
	document.title = AdminTabTitle.Title;

	useEffect(() => {
		const path = location.pathname + location.search;
		ReactGA.send({ hitType: "pageview", page: path, title: AdminTabTitle.Title });
	}, [location]);

	useEffect(() => {

		if (isInitialized) {
			dispatch(getAdminCardCounts());
		}

	}, [isInitialized, dispatch])

	const userCardData = [
		{ title: 'approved', value: adminCardCountsState.adminCardCounts?.approved || 0, color: '#006B86' },
		{ title: 'unApproved', value: adminCardCountsState.adminCardCounts?.unApproved || 0, color: '#000000' },
	];

	const totalPiValue = (adminCardCountsState.adminCardCounts?.approved || 0) +
		(adminCardCountsState.adminCardCounts?.unApproved || 0);

	const formatter = new Intl.NumberFormat('en');

	return (
		<div className={classes.commonBackgroundCard}>

			<Route exact path="/Admin">
				<AdminPageAppBar />
			</Route>

			<Grid className={classes.adminBackground}>

				<Card className={classes.card}>
					<CardActionArea component={RouterLink} to={'/RegistrationRequests'}>
						<CardActions disableSpacing sx={{ justifyContent: 'center' }}>
							<IconButton aria-label="adminpanel" component="div">
								<AdminPanelSettingsIcon className={classes.cstmCardIcon} />
							</IconButton>
						</CardActions>

						<CardContent sx={{ justifyContent: 'center' }}>
							<Typography variant="h6" align="center" >
								{formatter.format(adminCardCountsState.adminCardCounts?.unApproved || 0)}
							</Typography>

							<Typography variant="body2" component="p" align="center" color="text.primary">
								Registration Requests
							</Typography>
						</CardContent>
					</CardActionArea>
				</Card>

				<Card className={classes.card}>
					<CardActionArea component={RouterLink} to={'/UserList'}>
						<CardActions disableSpacing sx={{ justifyContent: 'center' }}>
							<IconButton aria-label="users">
								<GroupIcon className={classes.cstmCardIcon} />
							</IconButton>
						</CardActions>

						<CardContent sx={{ justifyContent: 'center' }}>
							<Typography variant="h6" align="center">
								{formatter.format(adminCardCountsState.adminCardCounts?.approved || 0)}
							</Typography>

							<Typography component="p" variant="body2" align="center" color="text.primary">
								Approved Users
							</Typography>
						</CardContent>
					</CardActionArea>
				</Card>

				<Card className={classes.card}>
					<CardActionArea component={RouterLink} to={'/InstitutionList'}>
						<CardActions disableSpacing sx={{ justifyContent: 'center' }}>
							<IconButton aria-label="work" component="div">
								<DomainIcon className={classes.cstmCardIcon} />
							</IconButton>
						</CardActions>

						<CardContent sx={{ justifyContent: 'center' }}>
							<Typography variant="h6" align="center">
								{formatter.format(adminCardCountsState.adminCardCounts?.institutions || 0)}
							</Typography>

							<Typography component="p" align="center" variant="body2" color="text.primary">
								Institutions
							</Typography>
						</CardContent>
					</CardActionArea>
				</Card>

				<Card className={classes.userCard}>
					<Typography component="p" variant="body2" align="left" color="text.primary" sx={{ margin: '10px !important' }}>
						User Status Overview
					</Typography>

					<Box className={classes.uChart} sx={{ width: '40%', display: 'inline-block', }}>

                        <PieChart
                            data={userCardData}
                            lineWidth={20}
                            animate
                            startAngle={0}
                            reveal={100}
                            labelPosition={0}
                            lengthAngle={360}
                            stroke-dasharray={20}
                            label={({ x, y, dx, dy }) => (
                                <text
                                    x={x}
                                    y={y}
                                    dx={dx}
                                    dy={dy}
                                    dominantBaseline="central"
                                    textAnchor="middle"
                                    style={{
                                        fontSize: '30px',
                                        fontFamily: 'sans-serif',
                                    }}
                                >
                                    {totalPiValue}
                                </text>
                            )}
                            radius={50}
                            center={[50, 50]}
                            viewBoxSize={[100, 100]}
                        />

					</Box>

					<Box sx={{ width: '45%', display: 'inline-block', verticalAlign: 'middle' }}>

						<Typography component="p" variant="body2" className={classes.uChartType} >
							<FiberManualRecordRoundedIcon className={classes.uBadgeDot}
								sx={{ color: '#000000' }} />
							Registration Requests
							<span className={classes.totalCountText}>
								{formatter.format(adminCardCountsState.adminCardCounts?.unApproved || 0)}
							</span>
						</Typography>

						<Typography component="p" variant="body2" className={classes.uChartType} >
							<FiberManualRecordRoundedIcon className={classes.uBadgeDot}
								sx={{ color: '#006B86' }} />
							Approved Users
							<span className={classes.totalCountText}>
								{formatter.format(adminCardCountsState.adminCardCounts?.approved || 0)}
							</span>
						</Typography>

						<Divider />

						<Typography component="p" variant="body2" align="center" sx={{ fontWeight: '600' }}>
							Total <span className={classes.totalCountText}>
								{(adminCardCountsState.adminCardCounts?.approved || 0) +
									(adminCardCountsState.adminCardCounts?.unApproved || 0)}
							</span>
						</Typography>

					</Box>

					<Box>
					</Box>

				</Card>

			</Grid>

		</div>
	);
};