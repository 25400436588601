export const GET_LOGGED_IN_USER = "GET_LOGGED_IN_USER";
export const GET_LOGGED_IN_USER_SUCCESS = "GET_LOGGED_IN_USER_SUCCESS";
export const GET_LOGGED_IN_USER_FAILED = "GET_LOGGED_IN_USER_FAILED";

export const getLoggedInUser = () => ({
    type: GET_LOGGED_IN_USER
});

export const getLoggedInUserSuccess = (payload: any) => {
    return {
        type: GET_LOGGED_IN_USER_SUCCESS,
        payload
    };
}

export const getLoggedInUserFailed = (payload: any) => {
    return {
        type: GET_LOGGED_IN_USER_FAILED,
        payload
    };
}

