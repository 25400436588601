import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, Card, CardContent, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import SecuritiesCard from "../../components/Shared/SecuritiesCard";
import { ISubmissionDetail, IFilterDTO, IDocumentation, ISubmissionListState } from "../../types/types";
import { Skeleton } from "@mui/material";
import SecuritiesTable, {
	IColumnHeader,
	ISorter,
	ISecuritiesTableFieldMap,
} from "../../components/Shared/SecuritiesTable/SecuritiesTable";
import { getDocumentAction, getSubmissionDocumentsListAction, clearSubmissionDocumentsListAction } from "../Submissions/submissions.actions";
import { submissionDocumentType } from "../Submissions/common";
import { convertUTCToLocalTime } from "../../utilities/utilities";
import { TECHNICAL_ERROR_MESSAGE } from "../../constants/CommonConstants";
import { DocumentOrigin } from "../../types/enums";

const submissionColumns: IColumnHeader[] = [
	{ field: "submissionNumber", label: "Submission Number", parameterName: "submissionNumber", type: "string", value: "", allowFilter: true },
	{ field: "submittedOn", label: "Date of Submission", parameterName: "submittedOn", type: "date", value: "", allowFilter: true },
	{ field: "documentType", label: "Document Type", parameterName: "documentTypeList", type: "selectMany", enumValues: [], value: "", allowFilter: true, hideSortIcon: true },
	{ field: "fileName", label: "File Name", parameterName: "fileName", type: "string", value: "", allowFilter: true }
];

const sortInfo: ISorter = {
	property: "submittedOn",
	order: "desc",
};

const defaultFilter: IFilterDTO =
{
	'parameter': 'includeRelatedSubmissions',
	'type': 'boolean',
	'value': 'true'
};

interface ISubmissionDetailDocumentationFormProps {
	submissionDetail: ISubmissionDetail;
}

export default function SubmissionDetailDocumentationForm({ submissionDetail }: ISubmissionDetailDocumentationFormProps) {
	const dispatch = useDispatch();
	let documentListState: any = useSelector((state: any) => {
		return {
			submissionDocuments: state.submissionListState.submissionDocuments,
			documentsLoading: state.submissionListState.documentsLoading,
			documentsLoaded: state.submissionListState.documentsLoaded
		};
	});
	let submissionListState: ISubmissionListState = useSelector((state: any) => state.submissionListState);

	let isGenerating: boolean = useSelector((state: any) => state.submissionListState.deficientTemplateGenerating);

	useEffect(() => {
		dispatch(clearSubmissionDocumentsListAction());

		let initialFilter: IFilterDTO[] = [defaultFilter];

		dispatch(getSubmissionDocumentsListAction({
			submissionId: submissionDetail.id,
			filter: initialFilter
		}));

	}, [dispatch, isGenerating, submissionDetail.id]);

	const getSubmissionDocumentTypeFilter = () => {

		return submissionDocumentType.filter(item => item.isInternal === false).sort((a, b) => a.label.localeCompare(b.label)).map(({ label, value}) => ({ label, value: value.toString() }));
	};

	submissionColumns[2].enumValues = getSubmissionDocumentTypeFilter();

	const getSubmissionDocument = (documentId: number) => {
		dispatch(getDocumentAction(documentId));
	};

	const getDocumentTypeForDisplay = (docType: number) => {

		for (let i = 0; i < submissionDocumentType.length; i++) {
			if (submissionDocumentType[i].value === docType) {
				return submissionDocumentType[i].label;
			}
		};

		return 'Document type not found';
	};

	const fetchFilteredDocumentsList = (filters: IFilterDTO[]) => {

		if (filters.find(s => s.parameter === defaultFilter.parameter && s.value !== defaultFilter.value)?.parameter) {
			filters[filters.findIndex(x => x.parameter === defaultFilter.parameter && x.value !== defaultFilter.value)].value = defaultFilter.value;
		}

		if (!filters.find(s => s.parameter === defaultFilter.parameter)?.parameter) {
			filters.push(defaultFilter);
		}

		dispatch(getSubmissionDocumentsListAction({
			submissionId: submissionDetail.id,
			filter: filters
		}));
	};

	const buildDocumentRows = (): ISecuritiesTableFieldMap[] => {
		var documentRows: ISecuritiesTableFieldMap[] = [];
		let uploadedDocuments: IDocumentation[];

		if (!documentListState.documentsLoading &&
			!documentListState.documentsLoaded) {
			uploadedDocuments = submissionDetail.documentSubmissions;
		} else {
			uploadedDocuments = documentListState.submissionDocuments;
		}

		uploadedDocuments && uploadedDocuments.filter(a => a.documentOrigin === DocumentOrigin.External).forEach(item => {
			let newItem: ISecuritiesTableFieldMap = {
				submissionNumber: {
					type: "string", value: item.submissionNumber
				},
				submittedOn: {
					type: "datetime", value: ((item.submittedOn == null) ? null : convertUTCToLocalTime(item.submittedOn))
				},
				documentType: { type: "string", value: <div> {getDocumentTypeForDisplay(item.documentType)} </div> },
				fileName: {
					type: "link",
					value: (
						<span style={{ cursor: "pointer", color: "#006B86" }}
							onClick={() => {
								getSubmissionDocument(item.id);
							}}
						>
							{item.name}
						</span>
					),
				},
			};

			documentRows.push(newItem);
		});

		return documentRows;
	}

	const rows = buildDocumentRows();

	return (
		<Grid container spacing={2}>
			<Grid container direction="row" justifyContent="flex-end">
				<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isGenerating}>
					<Stack alignItems="center">
						<CircularProgress color="inherit" />
						<br />

						<Typography variant="h4" color="#fff">
							Generating document...
						</Typography>
					</Stack>
				</Backdrop>

			</Grid>
			<Grid item xs={12}>
				<SecuritiesCard>
					{submissionDetail ? (
						<CardContent>
							<div>
								<SecuritiesTable
									columns={submissionColumns}
									rows={rows}
									sorter={sortInfo}
									paging={'client'}
									showFilter={true}
									dataFetchFunc={fetchFilteredDocumentsList}
								/>

							</div>
						</CardContent>
					) : (
						<Card>
							<CardContent>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={4} lg={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={12} sm={4} lg={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={12} sm={4} lg={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={12} sm={4} lg={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={12} sm={8} lg={4}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={12} sm={4} lg={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={12} sm={8} lg={4}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={12} sm={8} lg={4}>
										<Skeleton animation="wave" />
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					)}
					{(submissionListState.error || submissionListState.errorGetDocuments) && <p style={{ color: 'red' }}>{TECHNICAL_ERROR_MESSAGE}</p>}
				</SecuritiesCard>
			</Grid>
		</Grid>
	);
}