import {
	CLEAR_GET_CASE_BY_ID,
	DOWNLOAD_CASE_TEMPLATE,
	DOWNLOAD_CASE_TEMPLATE_FAILED,
	DOWNLOAD_CASE_TEMPLATE_SUCCEEDED,
	GET_CASES,
	GET_CASES_FAILED,
	GET_CASES_SUCCESS,
	GET_CASE_BY_ID,
	GET_CASE_BY_ID_FAILED,
	GET_CASE_BY_ID_SUCCESS,
	GET_CASE_TEMPLATE,
	GET_CASE_TEMPLATE_FAILED,
	GET_CASE_TEMPLATE_SUCCESS,
	IMPORT_CASE,
	IMPORT_CASE_FAILED,
	IMPORT_CASE_SUCCESS,
	SAVE_CASE,
	SAVE_CASE_FAILED,
	SAVE_CASE_SUCCESS
} from './cases.actions';
import { IAction, ICaseState } from '../../types/types';

const initialState: ICaseState = {
	isLoading: false,
	isDownloading: false,
	saveSuccess:false,
	cases: [],
	error: null,
	needsRefresh:false,
}

export default (state = initialState, { type, payload }: IAction) => {
	switch (type) {
		case GET_CASES:
			return {
				...state,
				isLoading: true
			}
		case GET_CASES_SUCCESS:
			return {
				...state,
				isLoading: false,
				cases: payload
			}
		case GET_CASES_FAILED:
			return {
				...state,
				isLoading: false,
				error: payload
			}
		case GET_CASE_BY_ID:
			return {
				...state,
				isLoading: true,
				selectedCaseDetail: null,
				needsRefresh:false
			}
		case GET_CASE_BY_ID_SUCCESS:
			return {
				...state,
				isLoading: false,
				selectedCaseDetail: payload
			}
		case GET_CASE_BY_ID_FAILED:
			return {
				...state,
				isLoading: false,
				error: payload
			}
		case CLEAR_GET_CASE_BY_ID:
			return {
				...state,
				isLoading: false,
				selectedCaseDetail: null
			}
		case GET_CASE_TEMPLATE:
			return {
				...state,
				isLoading: true,
				selectedCaseTemplate: null
			}
		case GET_CASE_TEMPLATE_SUCCESS:
			return {
				...state,
				isLoading: false,
				selectedCaseTemplate: payload
			}
		case GET_CASE_TEMPLATE_FAILED:
			return {
				...state,
				isLoading: false,
				error: payload
			}		
		case IMPORT_CASE:
			return {
				...state,
				caseDetailId: payload.caseDetailId,
				history: payload.history
			}
		case IMPORT_CASE_SUCCESS:
			return {
				...state
			}
		case IMPORT_CASE_FAILED:
			return {
				...state,
				error: payload
			}
		case DOWNLOAD_CASE_TEMPLATE:
			return {
				...state,
				isDownloading: true
			}
		case DOWNLOAD_CASE_TEMPLATE_SUCCEEDED:
			return {
				...state,
				isDownloading: false
			}
		case DOWNLOAD_CASE_TEMPLATE_FAILED:
			return {
				...state,
				isDownloading: false,
				error: payload
			}
		case SAVE_CASE:
			return {
				...state,
				isLoading: true,
                saveSuccess:false
			}
		case SAVE_CASE_SUCCESS:
			return {
				...state,
				isLoading: false,
				caseDetail: payload,
				saveSuccess: true,
				needsRefresh: true
			}
		case SAVE_CASE_FAILED:
			return {
				...state,
				isLoading: false,
				error: payload,
				saveSuccess: false,
				needsRefresh: true
			}
		default:
			return state;
	}
}