import { IAction, IApproveUserResponse, IDenyUserResponse, IRemoveUserAccessResponse, IUpdateUserResponse } from '../../types/types';
import {
    APPROVE_USER,
    UPDATE_USER,
    DENY_USER,
    GET_APPROVE_USER_CONTENT,
    GET_USERLIST,
    REVIEW_USER,
    GET_ROLES_LIST,
    REMOVE_USER_ACCESS,
    approveUserFailure,
    approveUserSuccess,
    denyUserFailureAction,
    denyUserSuccessAction,
    getApproveUserContentFailure,
    getApproveUserContentSuccess,
    getUserListFailure,
    getUserListSuccess,
    reviewUserFailureAction,
    reviewUserSuccessAction,
    updateUserFailure,
    updateUserSuccess,
    getRolesListSuccess,
    getRolesListFailure,
    removeUserAccessSuccessAction,
    removeUserAccessFailureAction,
} from './userlist.actions';
import { all, call, put, takeLeading } from 'redux-saga/effects';
import { approveUser, denyUser, getApproveUserContent, getUserList, reviewUser, updateUser, getAllRoles, removeUserAccess } from './userlist.api';
import { AlertType } from '../../types/enums';
import { showAlert } from '../Alerts/alerts.actions';

function* getUserListSaga({ payload: { filter, userListType } }: any): any {
    try {
        const user = yield call(getUserList, filter, userListType);
        yield put(getUserListSuccess(user));
    } catch (error: any) {
        yield put(getUserListFailure(error.message));
    }
}

function* getUserListWatcher() {
    yield takeLeading(GET_USERLIST, getUserListSaga);
}

function* getRolesListSaga({ payload: { filter } }: any): any {
    try {
        const user = yield call(getAllRoles, filter);
        yield put(getRolesListSuccess(user));
    } catch (error: any) {
        yield put(getRolesListFailure(error.message));
    }
}

function* getRolesListWatcher() {
    yield takeLeading(GET_ROLES_LIST, getRolesListSaga);
}

function* getApproveUserContentSaga(action: IAction): any {
    try {
        const template = yield call(getApproveUserContent, action.payload);
        yield put(getApproveUserContentSuccess(template));
    } catch (error: any) {
        yield put(getApproveUserContentFailure(error.message));
    }
}

function* getApproveUserContentWatcher() {
    yield takeLeading(GET_APPROVE_USER_CONTENT, getApproveUserContentSaga);
}

function* approveUserSaga(action: IAction): any {
    try {
        yield call(approveUser, action.payload);
        const approveUserResponse: IApproveUserResponse = { success: true, message: 'user approved and mapped to institution successfully.' }
        yield put(approveUserSuccess(approveUserResponse));
        yield put(showAlert({
            hidden: false,
            message: approveUserResponse.message,
            severity: approveUserResponse.success
                ? AlertType.success
                : AlertType.error
        }));
    } catch (error: any) {
        yield put(approveUserFailure(error.message));
    }
}

function* getApproveUserWatcher() {
    yield takeLeading(APPROVE_USER, approveUserSaga);
}

function* denyUserSaga(action: IAction): any {
    try {
        yield call(denyUser, action.payload);
        const denyUserResponse: IDenyUserResponse = { success: true, message: 'User denied.' }
        yield put(denyUserSuccessAction(denyUserResponse));
        yield put(showAlert({
            hidden: false,
            message: denyUserResponse.message,
            severity: denyUserResponse.success
                ? AlertType.success
                : AlertType.error
        }));
    } catch (error: any) {
        yield put(denyUserFailureAction(error.message));
    }
}

function* getDenyUserWatcher() {
    yield takeLeading(DENY_USER, denyUserSaga);
}

function* removeUserAccessSaga(action: IAction): any {
    try {
        yield call(removeUserAccess, action.payload);
        const response: IRemoveUserAccessResponse = { success: true, message: 'User Deactivated.' }
        yield put(removeUserAccessSuccessAction(response));
        yield put(showAlert({
            hidden: false,
            message: response.message,
            severity: response.success
                ? AlertType.success
                : AlertType.error
        }));
    } catch (error: any) {
        yield put(removeUserAccessFailureAction(error.message));
        yield put(showAlert({
            hidden: false,
            message: `There was a problem while denying the user. Please try again later.`,
            severity: AlertType.error,
        }));
    }
}

function* getRemoveUserAccessWatcher() {
    yield takeLeading(REMOVE_USER_ACCESS, removeUserAccessSaga);
}

function* reviewUserSaga(action: IAction): any {
    try {
        var userData = yield call(reviewUser, action.payload);
        yield put(reviewUserSuccessAction(userData));
    } catch (error: any) {
        yield put(reviewUserFailureAction(error.message));
    }
}

function* getreviewUserWatcher() {
    yield takeLeading(REVIEW_USER, reviewUserSaga);
}

function* updateUserSaga(action: IAction): any {
    try {
        yield call(updateUser, action.payload);
        const updateUserResponse: IUpdateUserResponse = { success: true, message: 'user updated successfully.' }
        yield put(updateUserSuccess(updateUserResponse));
        yield put(showAlert({
            hidden: false,
            message: updateUserResponse.message,
            severity: updateUserResponse.success
                ? AlertType.success
                : AlertType.error
        }));
    } catch (error: any) {
        yield put(updateUserFailure(error.message));
        yield put(showAlert({
            hidden: false,
            message: `There was a problem while updating the user. Please try again later.`,
            severity: AlertType.error,
        }));
    }
}

function* getUpdateUserWatcher() {
    yield takeLeading(UPDATE_USER, updateUserSaga);
}

export default function* userlistSaga() {
    yield all([
        getUserListWatcher(),
        getApproveUserContentWatcher(),
        getApproveUserWatcher(),
        getDenyUserWatcher(),
        getreviewUserWatcher(),
        getUpdateUserWatcher(),
        getRolesListWatcher(),
        getRemoveUserAccessWatcher()
    ]) //only one of these right now. add more as needed
}