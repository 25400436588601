export enum TemplateType {
    Spreadsheet = 1,
    DeficiencySpreadsheet = 2
}

export enum AlertType {
    info = 'info',
    success = 'success',
    warning = 'warning',
    error = 'error'
}

export enum Strings {
    SubmissionDocsNotSubmitted = 'submissionDocsNotSubmitted',
    InstitutionEmailTitle = 'institutionEmailTitle',
    Dashboard = 'dashboard',
    DashboardStartedText = 'dashboardStartedText',
    DashboardDeficientText = 'dashboardDeficientText',
    DashboardInProcessText = 'dashboardInProcessText',
    DashboardPendingText = 'dashboardPendingText',
    DashboardErrorText = 'dashboardErrorText',
    DashboardNomineePendingText = 'dashboardNomineePendingText',
    DashboardPartiallyLoadedText = 'dashboardPartiallyLoadedText',
    DashboardCloseText = 'dashboardCloseText',
    DashboardLoadedText = 'dashboardLoadedText',
    DataVerification = 'dataVerification',
    AttestationDocument = 'attestationDocument',
    SignatureAuthorization = 'signatureAuthorization',
    ProofOfClaim = 'proofOfClaim',
    OptionalSupportingDocumentText = 'optionalSupportingDocumentText',
    TermsOfService = 'termsOfService',
    HelpSupportInfo = 'HelpSupportInfo',
    ReplacementSubmissionAcknowledgementText = 'ReplacementSubmissionAcknowledgementText',
    UpdateSubmissionAcknowledgementText = 'UpdateSubmissionAcknowledgementText',
    DashboardPendingAction = "dashboardPendingAction",
    Cases = "cases",
    CaseDetail = "caseDetail",
    SubmissionList = "submissionList",
    SubmissionDetails = "submissionDetails",
    CaseDetailSubmissionList = "caseDetailSubmissionList",
    TemplateList = "templateList",
    TemplateDetails = "templateDetails",
    Admin = "admin",
    AdminRegistrationRequest = "adminRegistrationRequest",
    AdminApprovedUsers = "adminApprovedUsers",
    Admininstitutions = "adminInstitutions",
    Reports = 'reports',
    ClaimsByClaimStatus = 'claimsByClaimStatus',
    ClaimsBySubmissionStatus = 'claimsBySubmissionStatus',
    SubmissionWizardGuide = 'submissionWizardGuide',
    SubmissionWizardActionGuide = 'submissionWizardActionGuide',
    PartiallyLoadedSubmissionList = "partiallyLoadedSubmissionList",
    ToasterMessageRegistration = "toasterMessageRegistration",
    DeficiencyMailingBatchSendOne = "deficiencyMailingBatchSendOne",
    DeficiencyMailingBatchAutoSendAll = "deficiencyMailingBatchAutoSendAll",
    DeficiencyMailingBatchReject = "deficiencyMailingBatchReject",
    BatchApprovalOptionsActionGuide = "releaseWBdialog",
    ConfirmationMessageModalHeading = "confirmationMessageModalHeading",
    ConfirmationMessageModal = "confirmationMessageModal",
    SubmissionModalKeyText = "submissionModalKeyText",
    SubmissionModalKeyTextClaimFile = "submissionModalKeyTextClaimFile",
    SubmissionModalKeyTextReminder = "submissionModalKeyTextReminder",
    DeficiencyModalKeyText = "deficiencyModalKeyText",
    DeficiencyModalKeyTextClaimFile = "deficiencyModalKeyTextClaimFile",
    DeficiencyModalKeyTextReminder = "deficiencyModalKeyTextReminder",
    DeficiencyModalKeyTextClaimCountError = "deficiencyModalKeyTextClaimCountError",
    DeficiencyModalKeyTextTransactionCountError = "deficiencyModalKeyTextTransactionCountError",
    LabelDownloadSubmissionTemplate = "labelDownloadSubmissionTemplate",
    DialogDownloadSubmissionTemplate = "dialogDownloadSubmissionTemplate",
    LabelAddSubmission = "labelAddSubmission",
    DialogAddSubmission = "dialogAddSubmission",
    LabelAmendSubmission = "labelAmendSubmission",
    DialogAmendSubmission = "dialogAmendSubmission",
    LabelCheckSubmissionStatus = "labelCheckSubmissionStatus",
    DialogCheckSubmissionStatus = "dialogCheckSubmissionStatus",
    UrlTutorial = "urlTutorial",
    UrlQuickStart = "urlQuickStart",
    AlertCountryCodeInvalid = "alertCountryCodeInvalid",
    SubmissionDefectAction = "submissionDefectAction",
    SubmissionUpdateAction = "submissionUpdateAction",
    SubmissionReplaceAction = "submissionReplaceAction",
    SubmissionWizardReqDocs = "submissionWizardReqDocs",
    SubmissionResumeAction = "submissionResumeAction",
    DashboardSubmissionStatus = "dashboardSubmissionStatus",
    DeficiencyResponseModalClaimSubmissionText = "deficiencyResponseModalClaimSubmissionText",
    ConsolidatedSupportingDocumentsListText = "consolidatedSupportingDocumentsListText",
    ConsolidatedSupportingDocuments = "consolidatedSupportingDocuments",
}

export enum Configuration {
    ProductionSupportEmailAddress = 1,
    ExtractorErrorThresholdPercent = 2,
    HeartbeatRetentionDays = 3,
    ErrorThresholdTriggerCount = 4,
    RegistrationApprovalEmailAddress = 5,
    OfficialFilingUrl = 10

}

export enum DocumentType {
    Template = 1,
    Other = 2,
    Submission = 3,
    ReturnSubmission = 4,
    ProofofClaim = 5,
    SignatureAuthorization = 6,
    AttestationDocument = 7,
    DataVerification = 8,
    DeficiencyWorkbook = 9,
    DeficiencyResponse = 10,
    DeficiencyNotice = 11,
    ConsolidatedSupportingDocuments = 12
}

export enum DocumentOrigin {
    Internal = 1,
    External = 2
}

export enum DocumentTypeDisplay {
    Template = "Template",
    Other = "Additional Documents",
    Submission = "Submission",
    ReturnSubmission = "ReturnSubmission",
    ProofofClaim = "Proof of Claim",
    SignatureAuthorization = "Signature Authorization",
    AttestationDocument = "Attestation Document",
    DataVerification = "Data Verification",
    ConsolidatedSupportingDocuments = "Consolidated Supporting Documents"
}

export enum FieldGroup {
    Instruction = 1,
    Summary = 2,
    Claimant = 3,
    Transaction = 4,
    System = 5,
    Custom = 6
}

export enum UserListType {
    unapproved = 0,
    approved = 1,
    all = 2
}

export enum SubmissionState {
    Submitted = 1,
    Extracting = 2,
    Extracted = 3,
    Verifying = 4,
    Verified = 5,
    Loading = 6,
    Loaded = 7,
    Error = 8,
    Rejected = 9,
    PartiallyExtracted = 10,
    PartiallyLoaded = 11,
    Started = 12,
    Deficient = 13,
    Received = 14,
    Replaced = 15
}
export enum SubmissionType {
    Original = 0,
    Correction = 1,
    Update = 2,
    Replacement = 3,
    Deficiency = 4
}

export enum SubmissionVisisbility {

    EveryOne = 1,
    EpiqOnly = 2,
    None = 3,
}

export enum SubmissionTypeDisplay {
    Original = "New Submission",
    Correction = "Correction Submission",
    Update = "Update Submission",
    Replacement = "Replacement Submission",
    Deficiency = "Defect Response"
}

export enum TemplateStatus {
    Error = -1,
    Development = 0,
    Generating = 1,
    Generated = 2,
    Approved = 3,
    Associated = 4,


}

export enum SubmissionTab {
    Summary = 0,
    Submissions = 1,
    Claims = 2,
    EpiqDocuments = 3,
    FilerDocuments = 4,
}

export enum TemplateTab {
    Summary = 0,
    Claimants = 1,
    Transactions = 2,
    Cases = 3,

}

export enum DashboardTabTitle {
    Title = "Epiq Filing - Dashboard"
}

export enum AdminTabTitle {
    Title = "Epiq Filing - Admin"
}

export enum CaseListTabTitle {
    Title = "Epiq Filing - Cases"
}

export enum ReportsTabTitle {
    Title = "Epiq Filing - Reports"
}

export enum CaseDetailTabTitle {
    Title = "Epiq Filing - Case Details"
}

export enum SubmissionTabTitle {
    Title = "Epiq Filing - Submissions"
}

export enum TemplateTabTitle {
    Title = "Epiq Filing - Templates"
}

export enum UserListPageTitle {
    Title = "Epiq Filing - Users"
}

export enum InstitutionListPageTitle {
    Title = "Epiq Filing - Institutions"
}

export enum RegistrationRequestsGridTitle {
    Title = "Epiq Filing - Registration Requests Grid"
}

export enum RegistrationRequestsPageTitle {
    Title = "Epiq Filing - Registration Requests"
}

export enum TermsOfUseTitle {
    Title = "Epiq Filing - Terms Of Use"
}