import { buffers, eventChannel, END } from 'redux-saga';
import authService from '../../components/api-authorization/AuthorizeService';
import { IResponse, IClaimFileUploadResponse, ICaseDocumentTypeCriteria } from '../../types/types';
import { SubmissionType, Configuration } from "../../types/enums";

export const createUploadFileChannel = async (claimFilePayload: FormData) => {
	const token = await authService.getAccessToken();

	return eventChannel(emitter => {
		const xhr = new XMLHttpRequest();

		const onProgress = (e: ProgressEvent) => {

			if (e.lengthComputable) {
				const progress = e.loaded / e.total;
				emitter({ progress });
			}

		};

		const onFailure = (e: ProgressEvent | string) => {
			let response: IClaimFileUploadResponse = { success: false, message: 'Upload failed' };

			try {
				const error = e.toString();
				response.message = error;
			} finally {
				emitter({ error: new Error(response.message) });
				emitter(END);
			}

		};

		xhr.upload.addEventListener("progress", onProgress);
		xhr.upload.addEventListener("error", onFailure);
		xhr.upload.addEventListener("abort", onFailure);

		xhr.onreadystatechange = () => {
			const { readyState, status, response } = xhr;

			// Documentation for xhr readyState, status, and response can be found here: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest
			if (readyState === 4) {

				const res = JSON.parse(response);

				if (status === 200) {
					emitter({ success: true, message: res.message, submissionId: res.submissionId, submissionDescription: res.submissionDescription });
					emitter(END);
				}
				else {

					onFailure(res.message);

				}
			}

		};

		xhr.open("POST", `api/Intake/UploadClaimFileAsync/`, true);
		xhr.setRequestHeader("Authorization", `Bearer ${token}`);
		xhr.send(claimFilePayload);

		return () => {
			xhr.upload.removeEventListener("progress", onProgress);
			xhr.upload.removeEventListener("error", onFailure);
			xhr.upload.removeEventListener("abort", onFailure);
			xhr.onreadystatechange = null;
			xhr.abort();
		};
	}, buffers.sliding(2));
}

export const createUploadSupportingDocFileChannelAsync = async (submissionId: number, documentTypeId: number, file: FormData) => {

	const token = await authService.getAccessToken();

	return eventChannel(emitter => {
		const xhr = new XMLHttpRequest();


		const onProgress = (e: ProgressEvent) => {
			
			if (e.lengthComputable) {

				const progress = e.loaded / e.total;
				emitter({ progress: progress });

			}

		};

		const onFailure = (e: ProgressEvent | string) => {

			let response: IResponse = { success: false, message: 'Upload failed'};

			try {
				const error = (e) ? e.toString() : response.message;
				response.message = error;
			} finally {
				emitter({ error: new Error(response.message) });
				emitter(END);
			}

		};

		xhr.upload.addEventListener("progress", onProgress);
		xhr.upload.addEventListener("error", onFailure);
		xhr.upload.addEventListener("abort", onFailure);

		xhr.onreadystatechange = () => {
			const { readyState, status, response } = xhr;

			// Documentation for xhr readyState, status, and response can be found here: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest
			if (readyState === 4) {

				const res = JSON.parse(response);

				if (status === 200) {

					emitter({ success: res.success, message: res.message});
					emitter(END);

				}
				else {

					onFailure(res.message);

				}
			}

		};

		xhr.open("POST", `api/Intake/UploadSupportingDocFileAsync/${submissionId}/${documentTypeId}`, true);
		xhr.setRequestHeader("Authorization", `Bearer ${token}`);

		xhr.send(file);

		return () => {
			xhr.upload.removeEventListener("progress", onProgress);
			xhr.upload.removeEventListener("error", onFailure);
			xhr.upload.removeEventListener("abort", onFailure);
			xhr.onreadystatechange = null;
			xhr.abort();
		};
	}, buffers.sliding(2));
};


export const publishSubmissionMessageAsync = async (submissionId: number) => {
	const token = await authService.getAccessToken();

	try {
		let response = await fetch(`api/Intake/PublishSubmissionMessageAsync`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify(submissionId)
		});

		const res = await response.json();

		if (!response.ok) {
			
			throw new Error(res.message);

		}

		return res;
	}

	catch (error) {
		console.error(error);
		throw error;
	}

};

export const deleteSubmissionByIdAsync = async (submissionId: number) => {
	const token = await authService.getAccessToken();

	try {
		let response = await fetch(`api/Intake/DeleteSubmissionByIdAsync/${submissionId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		});

		const res = await response.json();

		if (!response.ok) {
			
			throw new Error(res.message);

		}

		return res;
	}

	catch (error) {
		console.error(error);
		throw error;
	}

};

export const getCaseDocumentTypesAsync = async(criteria: ICaseDocumentTypeCriteria) => {
	const token = await authService.getAccessToken();

	try {

		let response = await fetch(`api/Intake/GetCaseDocumentTypesAsync/`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify(criteria)
		});

		const res = await response.json();

		if (!response.ok) {
		
			throw new Error(res.message);

		}

		return res;
	}

	catch (error) {
		console.error(error);
		throw error;
	}

};

export const getConfigurationValueAsync = async (configurationKey: Configuration) => {
	const token = await authService.getAccessToken();

	try {
		let response = await fetch(`api/Intake/GetConfigurationByConfigurationKeyAsync/`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify(configurationKey)
		});

		const res = await response.json();

		if (!response.ok) {
		
			throw new Error(res.message);

		}

		return res;
	}

	catch (error) {
		console.error(error);
		throw error;
	}

};

export const getStringContentAsync = async (key: string) => {
	const token = await authService.getAccessToken();

	try {
		let response = await fetch(`api/Common/GetStringsByKeys`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify([key])
		});

		const res = await response.json();

		if (!response.ok) {

			throw new Error(res.message);

		}

		return res;
	}

	catch (error) {
		console.error(error);
		throw error;
	}

};

export const deleteDocumentsByIdListAsync = async (documentIds: number[]) => {
	const token = await authService.getAccessToken();

	try {

		let response = await fetch(`api/Intake/DeleteDocumentsByIdListAsync/`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify(documentIds)
		});

		const res = await response.json();

		if (!response.ok) {
		
			throw new Error(res.message);

		}

		return res;
	}

	catch (error) {
		console.error(error);
		throw error;
	}

};

