import { STANDARD_KABOB_HEIGHT } from '../../constants/CommonConstants';
import { EpiqAdmin, EpiqTechSupport } from '../../constants/roles';
import { IRegisterUser, IUserListState } from '../../types/types';

import { Card, CardContent, Grid, IconButton, Menu, MenuItem, Link as MuiLink, Theme } from '@mui/material';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import SecuritiesTable, { IColumnHeader, ISecuritiesTableFieldMap, ISorter } from '../../components/Shared/SecuritiesTable/SecuritiesTable';
import { UserContext, UserContextType } from '../User/UserContext';
import { createFilterDTOsFromColumnHeaders, headerContainsFilterValues } from '../../utilities/filterCriteria';
import { useDispatch, useSelector } from 'react-redux';

import { IFilterDTO } from '../../types/types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';
import { UserListType } from '../../types/enums';
import { getUserList } from './userlist.actions';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        background: "#006B86",
        size: 'small',
        margin: theme.spacing(1),
        color: 'white'
    },
    textLink: {
        color: theme.palette.text.primary,
        padding: '0.5em'
    },
    textLinkRight: {
        color: theme.palette.text.primary,
        padding: '0.5em',
        float: 'right',
        "&:focus": {
            outline: "none",
        }
    },
    actionColumn: {
        width: '275px'
    },
    kabobIconEnable: {
        color: "#087C9B",
        filter: "dropShadow(1px 1px 2px rgba(0, 0, 0, 0.44))",
        "&:disabled": {
            color: "#BCC0C5",
        },
    }
}));

const userListFilterColumns: IColumnHeader[] = [
    {
        field: 'email',
        label: 'Email/UserName',
        parameterName: 'email',
        type: 'string',
        value: '',
        allowFilter: true
    },
    {
        field: 'firstName',
        label: 'First Name',
        parameterName: 'firstName',
        type: 'string',
        value: '',
        allowFilter: true
    },
    {
        field: 'lastName',
        label: 'Last Name',
        parameterName: 'lastName',
        type: 'string',
        value: '',
        allowFilter: true
    },
    {
        field: 'institutionName',
        label: 'Institution Name',
        parameterName: 'institutionName',
        type: 'string',
        value: '',
        allowFilter: true
    },
    {
        field: 'approvedOn',
        label: 'Approved on',
        parameterName: 'approvedOnEndDate',
        type: 'date',
        value: '',
        allowFilter: true
    },
    {
        field: 'actions',
        label: 'Actions'
    }
];

const defaultSort: ISorter = {
    property: 'email',
    order: 'asc'
}

let sortInfo: ISorter = { ...defaultSort };

interface IUserListGridProps {
    slideoutDrawer: Function,
    removeUserAccess: Function,
    manageUserRoles: Function,
}

const UserListGrid = (
    {
        slideoutDrawer,
        removeUserAccess,
        manageUserRoles
    }: IUserListGridProps) => {
    const dispatch = useDispatch();
    const userListState: IUserListState =
        useSelector((state: any) => state.userListState);
    const { isAuthenticated, isInitialized, isInRole } =
        useContext(UserContext) as UserContextType;
    const { user } = useContext(UserContext) as UserContextType;
    const [kabobsMenu, setKabobsMenu] = React.useState<null | HTMLElement>(null);
    let [selectedRowIndex, setSelectedRowIndex] = useState(-1);
    const classes = useStyles();
    const open = Boolean(kabobsMenu);

    const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        setKabobsMenu(event.currentTarget);
        setSelectedRowIndex(index);
    };

    const handleClose = () => {
        setKabobsMenu(null);
        setSelectedRowIndex(-1);
    };

    const handleResetFilter = () => {
        sortInfo = { ...defaultSort };
    };

    const initialFilter = headerContainsFilterValues(userListFilterColumns) ?
        createFilterDTOsFromColumnHeaders(userListFilterColumns) : defaultSort;

    useEffect(() => {
        dispatch(getUserList({ filter: initialFilter, userListType: UserListType.approved }));
    }, [dispatch, isAuthenticated, isInitialized, isInRole]);

    if (userListState.needsRefresh) {
        userListState.needsRefresh = false;

        dispatch(getUserList({
            filter: headerContainsFilterValues(userListFilterColumns) ? createFilterDTOsFromColumnHeaders(userListFilterColumns) : [],
            userListType: UserListType.approved
        }));
    }

    const removeUserAccessContainer = (userid: number) => {
        removeUserAccess(userid);
    }

    const fetchFilteredUserList = (filters: IFilterDTO[]) => {
        dispatch(
            getUserList(
                {
                    filter: filters,
                    userListType: UserListType.approved
                }
            )
        );
    }

    const buildUserListRows = (): ISecuritiesTableFieldMap[] => {
        var userListRows: ISecuritiesTableFieldMap[] = [];

        userListState &&
            userListState.userList &&
            userListState.userList.forEach((item: IRegisterUser, index) => {
                let newItem: ISecuritiesTableFieldMap = {
                    email: {
                        type: "string",
                        value: item.email || ""
                    },
                    firstName: {
                        type: "string",
                        value: <span> {item.firstName || ""}</span>,
                        sortValue: item.firstName
                    },
                    lastName: {
                        type: "string",
                        value: <span>{item.lastName || ""}</span>,
                        sortValue: item.lastName
                    },
                    institutionName: {
                        type: "string",
                        value: <span>
                            {item.institution ? item.institution.name : ""}
                        </span>,
                        sortValue: item.institution ? item.institution.name : ""
                    },
                    approvedOn: {
                        type: "date",
                        value: item.approvedOn
                    },
                    actions: {
                        type: "link",
                        value: <Fragment>
                            <div className={classes.actionColumn}>

                                {
                                    isInRole([EpiqAdmin, EpiqTechSupport]) &&
                                    <>
                                        <IconButton
                                            aria-label="more"
                                            className={classes.kabobIconEnable}
                                            id={"long-button-" + index}
                                            aria-haspopup="true"
                                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                                handleClick(event, index);
                                            }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </>
                                }

                                <Menu
                                    id={"long-menu-" + index}
                                    MenuListProps={{
                                        'aria-labelledby': 'long-button' + index,
                                    }}
                                    anchorEl={kabobsMenu}
                                    open={selectedRowIndex === index}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                        style: {
                                            maxHeight: STANDARD_KABOB_HEIGHT * 4.5,
                                            width: '20ch',
                                        },
                                    }}
                                >

                                    <div>
                                        {
                                            item.isEpiqUser && isInRole([EpiqAdmin, EpiqTechSupport]) &&
                                            <>
                                                <MenuItem onClick={() => { manageUserRoles(item.id) }}> Manage Roles </MenuItem>
                                            </>
                                        }

                                        {
                                            isInRole([EpiqAdmin, EpiqTechSupport]) &&
                                            <>
                                                <MenuItem onClick={() => { removeUserAccessContainer(item.id) }}>Deactivate User </MenuItem>
                                            </>
                                        }

                                        {
                                            <>
                                                <MenuItem onClick={() => { slideoutDrawer(item.id, true) }}>User Details</MenuItem>
                                            </>
                                        }
                                    </div>
                                </Menu>
                            </div>
                        </Fragment>
                    }
                }

                userListRows.push(newItem);
            });

        return userListRows;
    };

    let rows = null as ISecuritiesTableFieldMap[] | null;

    if (!userListState.error)
        rows = buildUserListRows();

    return (
        <>
            {(userListState.isLoading === true) ?
                <Card>
                    <CardContent>
                        {[...Array(5)].map((elementInArray, index) => (
                            <Grid container spacing={2} key={index}>
                                <Grid item xs={2}>
                                    <Skeleton animation="wave" />
                                </Grid>
                                <Grid item xs={4}>
                                    <Skeleton animation="wave" />
                                </Grid>
                                <Grid item xs={2}>
                                    <Skeleton animation="wave" />
                                </Grid>
                                <Grid item xs={2}>
                                    <Skeleton animation="wave" />
                                </Grid>
                                <Grid item xs={2}>
                                    <Skeleton animation="wave" />
                                </Grid>
                            </Grid>
                        ))}
                    </CardContent>
                </Card>
                : <SecuritiesTable
                    columns={userListFilterColumns}
                    rows={rows}
                    sorter={sortInfo}
                    paging={'client'}
                    showFilter={true}
                    dataFetchFunc={fetchFilteredUserList}
                    resetFilterFunc={handleResetFilter}
                />}

        </>
    )
};

export default UserListGrid;