import React, { createContext, useEffect, useState } from 'react';
import authService from '../../components/api-authorization/AuthorizeService';
import { getLoggedInUser } from './user.api';

export interface IUser {
    id: number;
    institutionId: number;
    name: string;
    roles: string[];
    email: string;
    isEpiqUser: boolean;
    isOffshoreUser: boolean;
    countryCode: number;
}

export type UserContextType = {
    user: IUser;
    isAuthenticated: boolean;
    isInRole: Function;
    isInitialized: boolean;
}

export const UserContext = createContext<UserContextType | null>(null);

const UserProvider = ({ children }: any) => {
    let [user, setUser] = useState<IUser>({
        id: 0,
        institutionId: 0,
        name: '',
        roles: [],
        email: '',
        isEpiqUser: false,
        isOffshoreUser: false,
        countryCode: 0
    });

    let [isInitialized, setIsInitialized] = useState<boolean>(false);
    let [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    let [subscription, setSubscription] = useState<any>();

    useEffect(() => {
        setSubscription(authService.subscribe(() => populateState()));
        populateState();

        return function cleanup() {
            authService.unsubscribe(subscription);
        };
        // eslint-disable-next-line
    }, []);

    const isInRole = (roles:string[]):boolean => { 
        let userRoles = user?.roles?.map( i => i.toUpperCase())
        
        if (userRoles && userRoles.length > 0) {
            for (let i=0; i < roles.length; i++) {
                if (userRoles.includes(roles[i].toUpperCase())) {
                    return true;
                }            
            }
        }

        return false;
    }

    const populateState = async () => {
        let isAuthenticated = await authService.isAuthenticated();
        setIsAuthenticated(isAuthenticated);

        if (isAuthenticated) {

            let loggedInUser: any = await getLoggedInUser();

            if (loggedInUser) {
                setUser(loggedInUser);
                setIsInitialized(true);
            }
        }
        else
            setIsInitialized(true);
    }

    return (
        <UserContext.Provider value={{ user, isAuthenticated, isInRole, isInitialized }}>
            {children}
        </UserContext.Provider>
    );
}

export default UserProvider;