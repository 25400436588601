import { ITemplate, ITemplateState, IStringValue } from "../../types/types";
import { getInfoJsonWithArray, transformHelpUrl } from "../../utilities/utilities";
import { Theme, Button } from "@mui/material";
import React, { useState, useRef, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";
import TemplateListGrid from "./TemplateListGrid";
import { TemplateTabTitle, Strings } from "../../types/enums";
import { TECHNICAL_ERROR_MESSAGE } from "../../constants/CommonConstants";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { UserContext, UserContextType } from '../../modules/User/UserContext';
import { EpiqUser, Nominee } from '../../constants/roles';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme: Theme) => ({
	fab: {
		position: "absolute",
		bottom: theme.spacing(5),
		right: theme.spacing(5),
	},
	button: {
		background: "#006B86",
		size: "small",
		margin: theme.spacing(1),
		color: "white",
	},
	textLink: {
		color: theme.palette.text.primary,
		padding: "0.5em",
	},
	commonbackgroundcard: {
		boxShadow: "0px 10px 15px -3px rgb(0 0 0 / 30%)",
		padding: "1.5rem 1rem",
		margin: "1rem 1rem 0 0",
		background: "#fff",
		borderRadius: "5px",
	},
	title: {
		flexGrow: 1,
		marginBottom: "15px",
		display: 'inline-flex',
		marginRight: "5px"
	},
	dashboardIconBackground: {
		fontSize: "20px"
	},
	floatLeft: {
		float: 'left'
	},
	floatRight: {
		float: 'right',
	},
	headOuter: {
		display: 'inline-block',
		width: '100%',
	}
}));

const TemplateListPage = () => {
	const templateState: ITemplateState = useSelector((state: any) => state.templateState);
	const classes = useStyles();
	const { user, isInitialized, isAuthenticated, isInRole } = useContext(UserContext) as UserContextType;
	let [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const ref = useRef(null);
	const [stringContentData, setStringContentData] = useState<IStringValue | null>();

	let [selectedTemplate, setSelectedTemplate] = useState<ITemplate>();

	document.title = TemplateTabTitle.Title;
	const location = useLocation();

	useEffect(() => {
		const path = location.pathname + location.search;
		ReactGA.send({ hitType: "pageview", page: path, title: TemplateTabTitle.Title });
	}, [location])


	const selectTemplate = (templateId: number) => {
		const templateSelected = templateState.templates.find((x) => x.id === templateId);

		if (templateSelected) {
			setSelectedTemplate(templateSelected);
		}
	};

	const handleClick = (event: any) => {
		setShow(!show);
		setTarget(event.target);
	};

	const processInfoResponse = (data: any) => {
		data.map((item: IStringValue) => {
			switch (item.key) {
				case Strings.TemplateList:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setStringContentData(item);
					break;


				default:
					break;
			}
		});
	};

	useEffect(() => {
		const fetchInfoStrings = async () => {
			const data: any = await getInfoJsonWithArray([
				Strings.TemplateList,
			]);

			processInfoResponse(data);
		};


		if (isInitialized && isAuthenticated) {
			fetchInfoStrings();
		}
	}, [isAuthenticated, isInitialized]);

	return (
		<div className={classes.commonbackgroundcard}>
			<div className={classes.headOuter}>
			<div className={classes.floatLeft} ref={ref}>
			<Typography variant="h6" className={classes.title} noWrap>
				Templates
			</Typography>
			<Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent:"left", minWidth:"25px" }} onClick={handleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} /></Button>
					<Overlay
						show={show}
						target={target}
						placement="right-start"
						container={ref}
						containerPadding={20}
						rootClose
						onHide={() => setShow(false)}
					>
						<Popover id="popover-contained">
							<Popover.Header as="h3"><span style={{ color: "#000" }}>Templates</span></Popover.Header>
						{stringContentData && (
							<Popover.Body dangerouslySetInnerHTML={{ __html: stringContentData.value }}></Popover.Body>
						)}
						</Popover>
					</Overlay>
					
				</div>
			</div>
			<TemplateListGrid selectTemplate={selectTemplate} />

			{templateState.error && <p style={{ color: 'red' }}>{TECHNICAL_ERROR_MESSAGE}</p>}
		</div>
	);
};

export default TemplateListPage;
