import React, { useState } from 'react';
import { Card } from '@mui/material';

const SecuritiesCard = (props: any) => {
    let [hoverState, setHoverState] = useState(false);

    const handleOnMouseEnter = () => {
        setHoverState(true);
    };

    const handleOnMouseExit = () => {
        setHoverState(false);
    };

    return (
        <Card raised={hoverState} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseExit}>
            {props.children}
        </Card>
    )
};

export default SecuritiesCard;