import { Button, Card, CardContent, Grid, Theme } from "@mui/material";
import { IFilterDTO, ITemplate, ITemplateState } from "../../types/types";
import React, { Fragment, useEffect, useState } from "react";
import SecuritiesTable, {
    IColumnHeader,
    ISecuritiesTableFieldMap,
    ISorter,
} from "../../components/Shared/SecuritiesTable/SecuritiesTable";
import { UserContext, UserContextType } from "../User/UserContext";
import { createFilterDTOsFromColumnHeaders, headerContainsFilterValues } from "../../utilities/filterCriteria";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { TemplateStatus, TemplateType } from "../../types/enums";
import { getTemplatesAction } from "../Templates/template.actions";
import makeStyles from "@mui/styles/makeStyles";
import { useContext } from "react";

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        background: "#006B86",
        size: "small",
        margin: theme.spacing(1),
        color: "white",
    },
    textLink: {
        color: theme.palette.text.primary,
        padding: "0.5em",
    },
    disabledButton: {
        backgroundColor: "#cfcfcf !important",
        pointerEvents: "none",
        cursor: "not-allowed"
    },
}));

const templateListFilterColumns: IColumnHeader[] = [
    { field: "name", label: "Name", parameterName: "name", type: "string", value: "", minWidth: "256px" },
    {
        field: "description",
        label: "Description",
        parameterName: "description",
        type: "string",
        value: "",
        minWidth: "384px",
    },
    {
        field: "caseCount",
        label: "Cases (# of cases)",
        parameterName: "caseCount",
        type: "number",
        value: "",
        minWidth: "96px",
    },

    { field: "actions", label: "Actions",  hideHeader: true  },
];

const defaultSort: ISorter = {
    property: "caseCount",
    order: "desc",
};

let sortInfo: ISorter = { ...defaultSort };

interface CaseTemplateListGridGridProps {
    handleSelectedCaseTemplate: Function,
    caseTemplateHasBeenSelected: Function
}

const CaseTemplateListGrid = ({ handleSelectedCaseTemplate, caseTemplateHasBeenSelected}: CaseTemplateListGridGridProps) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const templateState: ITemplateState = useSelector((state: any) => state.templateState);
    let selectedCaseTemplate: ITemplate = useSelector((state: any) => state.caseState.selectedCaseTemplate);
    const initialFilter = headerContainsFilterValues(templateListFilterColumns)
        ? createFilterDTOsFromColumnHeaders(templateListFilterColumns)
        : [];
    const { user, isInitialized, isAuthenticated, isInRole } = useContext(UserContext) as UserContextType;
   
    useEffect(() => {
        // get filters with a parameter equal to templateTypeId
        const templateTypeFilter = initialFilter.find((filter) => filter.parameter === "templateTypeId");

        // if the filter does not exist, add it
        if (!templateTypeFilter) {
            initialFilter.push({ parameter: "templateTypeId", type: 'number', value: TemplateType.Spreadsheet.toString() });
        }

        dispatch(getTemplatesAction({ filter: initialFilter}));
    }, [dispatch, user, isInitialized, isAuthenticated, isInRole ]);

    const fetchFilteredTemplateStatus = (filters: IFilterDTO[]) => {
        // get filters with a parameter equal to templateTypeId
        const templateTypeFilter = filters.find((filter) => filter.parameter === "templateTypeId");

        // if the filter does not exist, add it
        if (!templateTypeFilter) {
            filters.push({ parameter: "templateTypeId", type: 'number', value: TemplateType.Spreadsheet.toString() });
        }

        dispatch(getTemplatesAction({ filter: filters }));
    };

    const handleResetFilter = () => {
        sortInfo = { ...defaultSort };
    };

    const buildCaseTemplateListRows = (): ISecuritiesTableFieldMap[] => {
        var caseTemplateListRows: ISecuritiesTableFieldMap[] = [];
        templateState.templates  && 
            templateState.templates.forEach((item, index) => {  
             if((item.templateStatusId === TemplateStatus.Approved ) || (item.templateStatusId === TemplateStatus.Associated )){
                let newItem: ISecuritiesTableFieldMap = {
                    name: {
                        type: "link",
                        value: (
                            <Link color="success" to={`/TemplateDetail/${item.id}`}>
                                {item.name}
                            </Link>
                        ),
                        sortValue: item.name,
                    },
                    description: { type: "string", value: item.description },
                    caseCount: { type: "number", value: item.caseCount || 0 },
                    actions: {
                        type: "link",
                        value: (
                            <Fragment>

                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={() => {handleSelectedCaseTemplate(item.id);}}
                                    classes={
                                        caseTemplateHasBeenSelected(item.id)
                                            ? {
                                                    root: classes.disabledButton,
                                                    disabled: classes.disabledButton,
                                              }
                                            : {}
                                            
                                    }
                                    >
                                    {caseTemplateHasBeenSelected(item.id) ? "Selected" : "Select"}
                                </Button>

                            </Fragment>
                        ),
                    },
                };
                
                caseTemplateListRows.push(newItem);
            };
            });

        return caseTemplateListRows;
    };

    const rows = buildCaseTemplateListRows();

    return (
        <>
            {templateState.isLoading === true ? (
                <Card>
                    <CardContent>
                        {[...Array(5)].map((elementInArray, index) => (
                            <Grid container spacing={2} key={index}>
                                <Grid item xs={2}>
                                    <Skeleton animation="wave" />
                                </Grid>

                                <Grid item xs={4}>
                                    <Skeleton animation="wave" />
                                </Grid>

                                <Grid item xs={2}>
                                    <Skeleton animation="wave" />
                                </Grid>

                                <Grid item xs={2}>
                                    <Skeleton animation="wave" />
                                </Grid>
                            </Grid>
                        ))}
                    </CardContent>
                </Card>
            ) : (
                <SecuritiesTable
                    columns={templateListFilterColumns}
                    rows={rows}
                    sorter={sortInfo}
                    showFilter={false}
                    dataFetchFunc={fetchFilteredTemplateStatus}
                    resetFilterFunc={handleResetFilter}

                />
            )}
        </>
    );
};

export default CaseTemplateListGrid;