import {
	IFilterDTO,
	IClaimantCriteria
} from '../../types/types';

import { ISorter
 } from '../../components/Shared/SecuritiesTable/SecuritiesTable';
import authService from '../../components/api-authorization/AuthorizeService';
import { createFilterCriteria } from '../../utilities/filterCriteria';
import download from "downloadjs"

export const getClaimantsForSubmission = async (submissionId: number,
										cmxCaseNumber: number,
                                        filter: IFilterDTO[], 
										sorter?: ISorter,
										pageNumber?: number,
										rowsPerPage?: number
										) => {
	try
	{
		let criteria = createFilterCriteria(filter) as IClaimantCriteria;
		const token = await authService.getAccessToken();
		
		criteria.submissionId = submissionId;
		criteria.cmxCaseNumber = cmxCaseNumber;

		if (sorter) {
			criteria.sortField = sorter.property as string;
			criteria.sortDirection = sorter.order;
		}

		if (pageNumber) {
			criteria.pageNumber = pageNumber;
		}
		
		if (rowsPerPage) {
			criteria.rowsPerPage = rowsPerPage
		}

		let response = await fetch(`api/Claimant/`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify(criteria)
		});

		if (!response.ok) {

			throw new Error("Failed to get claimants for submission.");

		}

		return response.json();
	}

	catch (exception)
	{

		console.error(exception);
		throw exception;

	}

};

export const exportSubmissionClaimants = async (submissionId: number, filter: IFilterDTO[], cmxCaseNumber: number) => {
	try {
		let criteria = createFilterCriteria(filter) as IClaimantCriteria;
		const token = await authService.getAccessToken();

		criteria.submissionId = submissionId;
		criteria.cmxCaseNumber = cmxCaseNumber;

		let response = await fetch(`api/Claimant/export/${criteria.submissionId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify(criteria)
		});

		if (!response.ok) {

			throw new Error("Failed to get claimants for submission.");

		}

		let blob = await response.blob();
		let file = new Blob([blob], { type: 'application/octet-stream' });
		let fileName = response.headers.get('Content-Disposition')?.split(';')[1].trim().split('=')[1].replaceAll('"', '');
		download(file, fileName, "application/octet-stream");
	}

	catch (exception) {

		console.error(exception);
		throw exception;

	}

};

export const refreshClaimStatus = async (submissionId: number,
										cmxCaseNumber: number,
										showCmxClaimResults: boolean,
										caseId: number) => {
	try {
		const token = await authService.getAccessToken();

		let criteria = {
			CmxCaseNumber: cmxCaseNumber,
			ShowCmxClaimResults: showCmxClaimResults,
			CaseId: caseId
		}
		
		let response = await fetch(`api/Claimant/refreshstatus/${submissionId}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify(criteria)
		});

		if (!response.ok) {

			throw new Error("Failed to refresh claim status for submission.");

		}
	}
	catch (exception) {
		console.error(exception);
		throw exception;
	}
};

