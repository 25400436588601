import { STANDARD_KABOB_HEIGHT } from "../../constants/CommonConstants";
import { ITemplateState } from "../../types/types";
import { EpiqAdmin, EpiqProjectManager, EpiqTechSupport, EpiqTemplateAdmin } from "../../constants/roles";

import { Card, CardContent, Grid, Theme } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Skeleton from "@mui/material/Skeleton";
import makeStyles from "@mui/styles/makeStyles";
import { getTemplatesAction } from "./template.actions";
import { IFilterDTO } from "../../types/types";
import SecuritiesTable, {
	IColumnHeader,
	ISecuritiesTableFieldMap,
	ISorter,
} from "../../components/Shared/SecuritiesTable/SecuritiesTable";
import { createFilterDTOsFromColumnHeaders, headerContainsFilterValues } from "../../utilities/filterCriteria";
import { useContext } from "react";
import { UserContext, UserContextType } from "../User/UserContext";

const useStyles = makeStyles((theme: Theme) => ({
	button: {
		background: "#006B86",
		size: "small",
		margin: theme.spacing(1),
		color: "white",
	},
	textLink: {
		color: theme.palette.text.primary,
		padding: "0.5em",
	},
	actionColumn: {
		width: "275px",
	},
	kabobIconEnable: {
		color: "#087C9B",
		filter: "dropShadow(1px 1px 2px rgba(0, 0, 0, 0.44))",
		"&:disabled": {
			color: "#BCC0C5",
		},
	}
}));

const templateListFilterColumns: IColumnHeader[] = [
	{ field: "name", label: "Name", parameterName: "name", type: "string", value: "", allowFilter: true, minWidth: "256px" },
	{
		field: "description",
		label: "Description",
		parameterName: "description",
		type: "string",
		value: "",
		allowFilter: true,
		minWidth: "384px",
	},
	{
		field: "caseCount",
		label: "Cases (# of cases)",
		parameterName: "caseCount",
		type: "number",
		value: "",
		allowFilter: false,
		minWidth: "96px",
	},
	{
		field: "updatedOn",
		label: "Last Updated",
		parameterName: "updatedOn",
		type: "date",
		value: "",
		allowFilter: false,
	},
];

const defaultSort: ISorter = {
	property: "caseCount",
	order: "desc",
};

let sortInfo: ISorter = { ...defaultSort };

interface TemplateListGridProps {
	selectTemplate: Function;
}

const TemplateListGrid = ({ selectTemplate }: TemplateListGridProps) => {
	const dispatch = useDispatch();
	const templateState: ITemplateState = useSelector((state: any) => state.templateState);
	const classes = useStyles();
	let showKebobs = false;

	const initialFilter = headerContainsFilterValues(templateListFilterColumns)
		? createFilterDTOsFromColumnHeaders(templateListFilterColumns)
		: [];
	const { user, isInitialized, isAuthenticated, isInRole } = useContext(UserContext) as UserContextType;

	if (isInitialized && isAuthenticated && user.isEpiqUser)
		if (isInRole([EpiqAdmin, EpiqProjectManager, EpiqTechSupport, EpiqTemplateAdmin]))
			showKebobs = true;

	useEffect(() => {
		dispatch(getTemplatesAction({ filter: initialFilter }));
	}, [dispatch]);

	const fetchFilteredTemplateList = (filters: IFilterDTO[]) => {
		dispatch(getTemplatesAction({ filter: filters }));
	};


	const handleResetFilter = () => {
		sortInfo = { ...defaultSort };
	};

	const buildTemplateListRows = (): ISecuritiesTableFieldMap[] => {
		var templateListRows: ISecuritiesTableFieldMap[] = [];

		templateState.templates &&
			templateState.templates.forEach((item, index) => {
				let newItem: ISecuritiesTableFieldMap = {
					name: {
						type: "link",
						value: (
							<Link color="success" to={`/TemplateDetail/${item.id}`}>
								{item.name}
							</Link>
						),
						sortValue: item.name,
					},
					description: { type: "string", value: item.description },
					caseCount: {
						type: "link", value: (<Link color="success" to={`/TemplateDetail/${item.id}/Cases`}>
							{item.caseCount || 0}
						</Link>)
					},
					updatedOn: { type: "date", value: item.updatedOn },
				};

				templateListRows.push(newItem);
			});

		return templateListRows;
	};

	let rows = null as ISecuritiesTableFieldMap[] | null;

	if (!templateState.error)
		rows = buildTemplateListRows();

	return (
		<>
			{templateState.isLoading === true ? (
				<Card>
					<CardContent>
						{[...Array(5)].map((elementInArray, index) => (
							<Grid container spacing={2} key={index}>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>

								<Grid item xs={4}>
									<Skeleton animation="wave" />
								</Grid>

								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>

								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
							</Grid>
						))}
					</CardContent>
				</Card>
			) : (
				<SecuritiesTable
					columns={templateListFilterColumns}
					rows={rows}
					sorter={sortInfo}
					paging={'client'}
					showFilter={true}
					dataFetchFunc={fetchFilteredTemplateList}
					resetFilterFunc={handleResetFilter}
				/>
			)}
		</>
	);
};

export default TemplateListGrid;