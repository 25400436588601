import authService from "../../components/api-authorization/AuthorizeService";

export const getSettings = () => {
    return fetch('config.json')
        .then((response) => response.json())
        .catch((error) => Promise.reject(error));
}

export const getPowerBiURL = async () => {
	const token =  await authService.getAccessToken();

	try {

		let response = await fetch(`api/Common/GetPowerBiURL`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
            }
        });

		if (!response.ok)
			throw new Error(response.statusText);
			return await response.text();

	}

	catch (exception) {
		console.error("Error getting PowerBiURL.")
		console.error(exception)
	}

	return '';
};