import { submissionStates } from "../modules/Submissions/common";
import { SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE, SUBMISSION_IN_PROCESS_STATUS_TEXT } from "../constants/CommonConstants";

interface ISubmissionState {
    label: string;
    value: string;
    order: number;
}

const sortSubmissionState = (a: ISubmissionState, b: ISubmissionState) => a.order - b.order;

export const getSubmissionStatusList = (isNomineeUser: boolean): any => {

    let submissionStateList: ISubmissionState[] = submissionStates;

    if (isNomineeUser && submissionStateList[submissionStateList.findIndex(x => x.label.toLowerCase() === "error")])
    {
            submissionStateList[submissionStateList.findIndex(x => x.label.toLowerCase() === "error")].label = SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE;
    }

    return submissionStateList.sort(sortSubmissionState);
}

export const getSubmissionStatusText = (isNomineeUser: boolean, state: string): string => {

    switch (state.toLowerCase()) {
            case 'submitted':
            case 'extracted':
            case 'extracting':
            case 'verifying':
            case 'verified':
            case 'loading':
            case 'partially extracted':
                return SUBMISSION_IN_PROCESS_STATUS_TEXT;
           case 'error': 
                return (isNomineeUser) ? SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE : state;
            default:
                return state;
        }
 
}

export const transformNomineeStatusList = (statusFilter: string): string => {
    let arr:string[] = statusFilter.split(',');
    let idx = arr.indexOf('1'); //check for In Process placeholder status

    if (idx === -1) {   // no In Process found. return the input
        return statusFilter; 
    }

    arr[idx] = '1,2,3,4,5,6,10'; // replace placeholder with actual status values
    return arr.join(',');
}