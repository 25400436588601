import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ICase, ITemplate, ITemplateState, IStringValue } from "../../types/types";
import { getInfoJsonWithArray, transformHelpUrl } from "../../utilities/utilities";
import { Strings, TemplateTab } from "../../types/enums";
import { Typography, Theme, CardContent, Grid, InputLabel, TextField, Button, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SecuritiesCard from "../../components/Shared/SecuritiesCard";
import { FieldGroup } from "../../types/enums";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { saveTemplateAction } from "./template.actions";
import { EpiqProjectManager, EpiqTechSupport, EpiqTemplateAdmin, EpiqUser, Nominee } from '../../constants/roles';
import { UserContext, UserContextType } from "../User/UserContext";
import { TECHNICAL_ERROR_MESSAGE } from "../../constants/CommonConstants";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { Description } from "@mui/icons-material";

const useStyles = makeStyles((theme: Theme) => ({
	"@global tbody tr:nth-child(even)": {
		background: "lightgray"
	},
	tableFixHead: {
		overflowY: "auto",
		height: "106px",
		width: "100%",
	},
	fixedHeader: {
		position: "sticky",
		top: "0",
	},
	table: {
		borderCollapse: "collapse",
		width: "100%",
	},
	thead: {
		tableLayout: 'fixed'
	},
	td: {
		padding: "8px 16px",
	},
	th: {
		background: "#eee",
	},
	title: {
		flexGrow: 1,
		marginBottom: "15px",
		display: 'inline-flex',
		marginRight: "5px"
	},
	label: {
		color: "black",
		fontWeight: "bold",
		float: "left",
		marginRight: "5px",
		marginBottom: "15px",
	},
	subTitle: {
		fontSize: "12px",
		color: "grey",
		float: "right",
		fontStyle: "italic",
		opacity: ".5",
		marginLeft: "5px",
		marginTop: "4px",
	},
	buttom: {
		color: 'black'
	},
	textfield_label: {
		color: 'rgba(0, 0, 0, 0.6)',
		position: 'relative'
	},
	floatLeft: {
		float: 'left'
	},
	floatRight: {
		float: 'right',
	},
	dashboardIconBackground: {
		fontSize: "20px"
	}
}));

interface ITemplateDetailFormProps {
	template?: ITemplate;
}

interface ITabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: ITabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component="div">{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default function TemplateDetailForm({ template: templateDetail }: ITemplateDetailFormProps) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	// tabs pulled from example here - https://mui.com/material-ui/react-tabs/#basic-tabs

	const [tabNumber, setTabNumber] = React.useState(0);
	let { tabName = "Summary" } = useParams<{ tabName: any }>();
	const handleChange = (event: React.SyntheticEvent, newTabNumber: number) => {
		setTabNumber(newTabNumber);
	};
	const { isAuthenticated, isInitialized, isInRole } = useContext(UserContext) as UserContextType;
	const { user } = useContext(UserContext) as UserContextType;

	let [templateDetailWorkingCopy, setTemplateDetailWorkingCopy] = useState<ITemplate>();
	let [isDirty, setIsDirty] = useState<boolean>();
	let [caseList, setcaseList] = useState<ICase>();
	let allowEdit = false;
	let templateState: ITemplateState = useSelector((state: any) => state.templateState);

	let [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const ref = useRef(null);
	const [stringContentData, setStringContentData] = useState<IStringValue | null>();
	const [templateDetails, setTemplateDetails] = useState<string>("");
	const [tabDetermined, setTabDetermined] = useState<boolean>(false);

	if (isInitialized && isAuthenticated) {
		if (user.isEpiqUser && isInRole([EpiqProjectManager, EpiqTechSupport, EpiqTemplateAdmin])) {
			allowEdit = true;
		}
	}

	document.title = (templateDetail === null || templateDetail === undefined) ? 'Template Detail' : "Epiq Filling - " + "Template Details - " + templateDetail.name;

	useEffect(() => {
		setIsDirty(false);

		if (tabName.toLowerCase() === 'cases') {
			setTabNumber(gettabNameFromUrl(tabName));
			setTabDetermined(true);
			return;
		}


		if (templateDetail) {
			setTemplateDetailWorkingCopy({ ...templateDetail });
		}

		

		setcaseList(caseList);
		setTabDetermined(true);
	}, [templateDetail, caseList]);

	const handleSave = (e: any) => {
		dispatch(saveTemplateAction(templateDetailWorkingCopy));

		setIsDirty(false);
	}

	const cancelTemplate = () => {
		history.push("/TemplateList");
	}

	const handleChangeTemplateDetail = (e: any) => {
		const { name, value } = e.target;

		if ((name.toLowerCase() == 'description' && value !== templateDetail?.description) ||
			(name.toLowerCase() == 'instructions' && value !== templateDetail?.instructions)) {
			setIsDirty(true)
		} else {
			setIsDirty(false);
		}

		setTemplateDetailWorkingCopy((prevState: any) => ({
			...prevState,
			[name]: value,
		}));
	}

	const gettabNameFromUrl = (tabName: any) => {
		var urltabName = tabName.toLowerCase();
		var tabSubmissionIndex = 3;

		switch (urltabName) {
			case "summary": tabSubmissionIndex = TemplateTab.Summary; break;
			case "claimants": tabSubmissionIndex = TemplateTab.Claimants; break;
			case "transactions": tabSubmissionIndex = TemplateTab.Transactions; break;
			case "cases": tabSubmissionIndex = TemplateTab.Cases; break;
		}
		return tabSubmissionIndex;
	};

	const handleClick = (event: any) => {
		setShow(!show);
		setTarget(event.target);
	};

	const processInfoResponse = (data: any) => {
		data.map((item: IStringValue) => {
			switch (item.key) {
				case Strings.TemplateDetails:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setStringContentData(item);
					break;


				default:
					break;
			}
		});
	};

	useEffect(() => {
		const fetchInfoStrings = async () => {
			const data: any = await getInfoJsonWithArray([
				Strings.TemplateDetails,
			]);

			processInfoResponse(data);
		};


		if (isInitialized && isAuthenticated) {
			fetchInfoStrings();
		}
	}, [isAuthenticated, isInitialized]);

	return (
		<div>
			{templateDetail ? (
				<SecuritiesCard>
					<CardContent>
						<Grid container spacing={1}>
							<Grid item xs={8}>
								<div className={classes.floatLeft} ref={ref}>
									<InputLabel className={classes.label}>Template:</InputLabel>

									<Typography className={classes.title} noWrap>
										{templateDetail.name}
									</Typography>
								</div>
							</Grid>

							{(isDirty && allowEdit) && (
								<Grid item xs={4}>
									<Stack direction="row" spacing={2} justifyContent="right">
										<Button
											variant="contained"
											color="primary"
											onClick={(event: React.MouseEvent<HTMLElement>) => {
												handleSave(event);
											}}
										>
											Save
										</Button>
										<Button
											variant="outlined"
											color="secondary"
											onClick={cancelTemplate}
										>
											Cancel
										</Button>
									</Stack>
								</Grid>
							)}

							<Grid item xs={12}>
								<TextField
									name="description"
									id="description"
									label="Description"
									multiline
									rows={2}
									defaultValue={templateDetailWorkingCopy?.description ? templateDetailWorkingCopy.description : templateDetail?.description}
									onChange={handleChangeTemplateDetail}
									fullWidth={true}
									InputLabelProps={{ className: classes.textfield_label }}
									disabled={!allowEdit}

								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									name="instructions"
									id="instructions"
									label="Instructions"
									multiline
									rows={2}
									value={templateDetailWorkingCopy?.instructions ? templateDetailWorkingCopy.instructions : templateDetail?.instructions}
									onChange={handleChangeTemplateDetail}
									fullWidth={true}
									sx={{ marginTop: "20px" }}
									InputLabelProps={{ className: classes.textfield_label }}
									disabled={!allowEdit}
								/>
							</Grid>
						</Grid>
					</CardContent>
					{templateState.error && <p style={{ color: 'red' }}>{TECHNICAL_ERROR_MESSAGE}</p>}
				</SecuritiesCard>
			) : (
				<div ref={ref}>
					<Typography variant="h6" className={classes.title} noWrap>
						<div>Template Details: </div>
					</Typography>
					<Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent: "left", minWidth: "25px" }} onClick={handleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} /></Button>
					<Overlay
						show={show}
						target={target}
						placement="right-start"
						container={ref}
						containerPadding={20}
						rootClose
						onHide={() => setShow(false)}
					>
						<Popover id="popover-contained">
							<Popover.Header as="h3"><span style={{ color: "#000" }}>Template Details</span></Popover.Header>
							{stringContentData && (
								<Popover.Body dangerouslySetInnerHTML={{ __html: stringContentData.value }}></Popover.Body>
							)}
						</Popover>
					</Overlay>
				</div>
			)}
			{tabDetermined &&
				<Box sx={{ width: "100%" }}>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<Tabs value={tabNumber} onChange={handleChange} aria-label="basic tabs example">
							<Tab sx={{ color: 'rgba(0, 0, 0, 0.6)' }} label="Summary" {...a11yProps(0)} />
							<Tab sx={{ color: 'rgba(0, 0, 0, 0.6)' }} label="Claimants" {...a11yProps(1)} />
							<Tab sx={{ color: 'rgba(0, 0, 0, 0.6)' }} label="Transactions" {...a11yProps(2)} />
							<Tab sx={{ color: 'rgba(0, 0, 0, 0.6)' }} label="Cases" {...a11yProps(3)} />
						</Tabs>
					</Box>

					<TabPanel value={tabNumber} index={0}>
						<table className='table table-striped' aria-labelledby="tabelLabel">
							<thead style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
								<tr key="summaryHeader">
									<th>Column</th>
									<th>CMx Mapping</th>
									<th>Required</th>
								</tr>
							</thead>
							<tbody style={{ display: 'block', height: '400px', overflow: 'auto' }}>
								{templateDetail?.worksheets?.filter(ws => ws.fieldGroup === FieldGroup.Summary)[0]?.worksheetColumns?.map((column) => (
									<tr style={{ display: 'table', width: '100%', tableLayout: 'fixed' }} key={column.id}>
										<td>{column.name}</td>
										<td>{column.mapToField}</td>
										<td>{column.isRequired ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}</td>
									</tr>
								))}
							</tbody>
						</table>
					</TabPanel>

					<TabPanel value={tabNumber} index={1}>
						<table className='table table-striped' aria-labelledby="tabelLabel">
							<thead style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
								<tr key="claimantsHeader">
									<th>Column</th>
									<th>CMx Mapping</th>
									<th>Required</th>
								</tr>
							</thead>

							<tbody style={{ display: 'block', height: '400px', overflow: 'auto' }}>
								{templateDetail?.worksheets?.filter(ws => ws.fieldGroup === FieldGroup.Claimant)[0]?.worksheetColumns?.map((column) => (
									<tr style={{ display: 'table', width: '100%', tableLayout: 'fixed' }} key={column.id}>
										<td>{column.name}</td>
										<td>{column.mapToField}</td>
										<td>{column.isRequired ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}</td>
									</tr>
								))}
							</tbody>
						</table>
					</TabPanel>

					<TabPanel value={tabNumber} index={2}>
						<table className='table table-striped' aria-labelledby="tabelLabel">
							<thead style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
								<tr key="transactionsHeader">
									<th>Column</th>
									<th>CMx Mapping</th>
									<th>Required</th>
								</tr>
							</thead>

							<tbody style={{ display: 'block', height: '400px', overflow: 'auto' }}>
								{templateDetail?.worksheets?.filter(ws => ws.fieldGroup === FieldGroup.Transaction)[0]?.worksheetColumns?.map((column) => (
									<tr style={{ display: 'table', width: '100%', tableLayout: 'fixed' }} key={column.id}>
										<td>{column.name}</td>
										<td>{column.mapToField}</td>
										<td>{column.isRequired ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}</td>
									</tr>
								))}
							</tbody>
						</table>
					</TabPanel>

					<TabPanel value={tabNumber} index={3}>
						<table className='table table-striped' aria-labelledby="tabelLabel">
							<thead style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}>
								<tr key="casesHeader">
									<th>Case Name</th>
									<th>Case Number</th>
									<th></th>
								</tr>
							</thead>

							<tbody style={{ display: 'block', height: '400px', overflow: 'auto' }}>
								{templateDetail?.caseList?.map((column, index) => (
									<tr key={index} style={{ display: 'table', width: '100%', tableLayout: 'fixed' }} >
										<td>{column.name}</td>
										<td>{column.number}</td>
										<td></td>
									</tr>
								))}
							</tbody>
						</table>
					</TabPanel>
				</Box>
			}
		</div>
	);
}