import { takeLeading, all, put } from 'redux-saga/effects';
import { showAlertSuccess, showAlertFailed, SHOW_ALERT } from './alerts.actions';
import { IAction } from '../../types/types';

function* showAlertSaga(action: IAction) {
    try {
        yield put(showAlertSuccess(action.payload))
    } catch (error: any) {
        yield put(showAlertFailed(error.message));
    }
}

function* showAlertWatcher() {
    yield takeLeading(SHOW_ALERT, showAlertSaga);
}

export default function* alertsSaga() {
    yield all([showAlertWatcher()])
}