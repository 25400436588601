import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Icon,
    Modal,
    Theme,
} from "@mui/material";
import React, { useCallback } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";

const createLocalStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: "absolute",
        },
        modal: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            justifyContent: "center",
            width: "700px",
        },
        modalHeader: {
            backgroundColor: theme.palette.primary.main,
            minWidth: 500,
            color: theme.palette.common.white,
            textEmphasisColor: theme.palette.common.white,
            font: "14",
        },
        modalHeaderTitle: {
            textDecoration: "underline",
            padding: "5px;",
        },
        disabledButton: {
            backgroundColor: "#cfcfcf !important",
        },
        title: {
            flexGrow: 1,
            color: "black",
        },
        label: {
            color: "black",
            float: "left",
            fontSize: "20px",
            marginRight: "5px",
            marginTop: "1px",
        },
        cardAction: {
            justifyContent: "flex-end",
        },
        primaryBtn: {
            color: "#fff!important",
            backgroundColor: "#007bff!important",
        },
        secondaryBtn: {
            color: "blue",
            backgroundColor: "#fff!important",
            border: "1px solid blue",
        },
        paragraph: {
            marginBottom: "0px",
        },
        modalBackground: {
            backgroundColor: "inherit",
        },
        error: {
            color: 'red',
            float: 'left',
            fontSize: '20px'
        },
    })
);

interface ISaveForLaterModalProps {
    modalOpen: boolean;
    handleClose: any;
    message: string;
    saveforlatersubmit: Function;
}

const SaveForLaterModal = ({
    modalOpen,
    handleClose,
    message,
    saveforlatersubmit
}: ISaveForLaterModalProps) => {
    const classes = createLocalStyles();
    const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(true);

    const handleModalClosed = useCallback(() => {

        if (!modalOpen)
            return;

        handleClose(false);
    }, [handleClose]);

    const updateUserRoles = (): void => {
        saveforlatersubmit(false);
        handleClose(false);
        setConfirmationModalOpen(false);
    };

    return (
        <Modal open={modalOpen} onClose={handleModalClosed}>
            <Card className={classes.modal}>
                <CardHeader
                    title="Save For Later"
                    classes={{ title: classes.modalHeaderTitle }}
                    action={
                        <Icon
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                handleModalClosed();
                            }}
                        >
                            close
                        </Icon>
                    }
                ></CardHeader>

                <CardContent className={classes.modalBackground}>
                    <Typography style={{ whiteSpace: 'normal' }} className={classes.title} dangerouslySetInnerHTML={{ __html: message }}>
                    </Typography>

                </CardContent>
                <CardActions className={classes.cardAction}>
                    <Button
                        classes={{ root: classes.primaryBtn }}
                        onClick={() => {
                            updateUserRoles();
                        }}
                        color="primary"
                    >
                        Continue
                    </Button>
                    <Button
                        onClick={() => {
                            handleModalClosed();
                        }}
                        classes={{ root: classes.secondaryBtn, disabled: classes.secondaryBtn }}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    );
};

export default SaveForLaterModal;