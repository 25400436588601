import { AlertType, DocumentType, DocumentTypeDisplay, Strings, SubmissionState, SubmissionType, SubmissionTypeDisplay } from "../../types/enums";
import { Button, Card, CardActions, CardHeader, Grid, Icon, InputLabel, Modal, Theme } from '@mui/material';
import {
    ICaseDocumentType, IClaimFileState, IDocument, IResponse, IStringValue,
    ISupportingDocModalProps, ISupportingDocTypeProps
} from '../../types/types';
import React, { useEffect, useState, useContext, useRef } from 'react';
import {
    clearCaseDocumentTypeAction,
    clearDeleteDocumentAction,
    clearPublishSubmissionMessageAction,
    clearSupportEmailAddressAction,
    clearSupportingDocFileAction,
    deleteDocumentsAction,
    getCaseDocumentTypesAction,
    publishSubmissionMessageAction,
    setSupportingDocTypeProps,
    uploadSupportingDocFileAction
} from './claimFile.actions';
import { clearSubmissionDocumentsListAction, getSubmissionDocumentsListAction }
    from '../Submissions/submissions.actions';
import { useDispatch, useSelector } from 'react-redux';
import CancelSubmissionModal from './CancelSubmissionModal';
import SaveForLaterModal from './SaveForLaterModal';
import SupportingDocFilesUploadCard from "./SupportingDocFilesUploadCard";
import { Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { showAlert } from '../Alerts/alerts.actions';
import { useCallback } from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { transformHelpUrl, getInfoJsonWithArray } from "../../utilities/utilities";
import { UserContext, UserContextType } from '../../modules/User/UserContext'
import { EpiqUser } from '../../constants/roles';
import ConfirmationMessageModal from '../../components/Shared/ConfirmationMessageModal';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const createLocalStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
        },
        claimmodal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            justifyContent: 'center',
            width: '700px;'
        },
        modalHeader: {
            backgroundColor: theme.palette.primary.main,
            minWidth: 500,
            color: theme.palette.common.white,
            textEmphasisColor: theme.palette.common.white,
            font: '16',
        },
        modalHeaderTitle: {
            paddingBottom: '5px;'
        },
        caseTitleNumber: {
            flexGrow: 1,
            color: 'black',
            display: 'flex',
            marginRight: "5px",
            whiteSpace: "pre-line"
        },
        titleLeft: {
            textDecoration: 'underline',
            flexGrow: 1,
            color: 'black',
            display: 'inline-flex',
            marginRight: "5px"

        },
        titleRight: {
            color: 'black',
            float: 'right',
            marginRight: "35px"
        },
        label: {
            color: 'black',
            float: 'left',
            fontSize: '20px',
            marginRight: '5px',
            marginTop: '1px'
        },
        cardAction: {
            justifyContent: 'flex-end'
        },
        disabledButton: {
            backgroundColor: '#cfcfcf !important',
        },
        primaryBtn: {
            color: '#fff!important',
            backgroundColor: '#007bff!important'
        },
        secondaryBtn: {
            color: 'blue',
            backgroundColor: '#fff!important',
            border: '1px solid',
            borderColor: 'blue'
        },
        paragraph: {
            marginBottom: '-5px'
        },
        uploadedBtn: {
            color: 'white!important',
            backgroundColor: '#28a745!important'
        },
        error: {
            color: 'red',
            float: 'left',
            fontSize: '20px'
        },
        dashboardIconBackground: {
            fontSize: "20px"
        },
    }),
);

const getDocTypeIdFromDescription = (description: string): number => {
    let id;

    switch (description) {
        case DocumentTypeDisplay.DataVerification:
            id = DocumentType.DataVerification;
            break;
        case DocumentTypeDisplay.AttestationDocument:
            id = DocumentType.AttestationDocument;
            break;
        case DocumentTypeDisplay.SignatureAuthorization:
            id = DocumentType.SignatureAuthorization;
            break;
        case DocumentTypeDisplay.ProofofClaim:
            id = DocumentType.ProofofClaim;
            break;
        case DocumentTypeDisplay.Other:
            id = DocumentType.Other;
            break;
        case DocumentTypeDisplay.ConsolidatedSupportingDocuments:
            id = DocumentType.ConsolidatedSupportingDocuments
            break;
        default:
            id = 0;
            break;
    }

    return id;
}

interface ISupportingDocModal {
    modalOpen: boolean,
    supportingDocModalProps: ISupportingDocModalProps
}

const SupportingDocModal = ({ modalOpen, supportingDocModalProps }: ISupportingDocModal) => {
    const dispatch = useDispatch();
    const classes = createLocalStyles();
    let [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const handleClose: Function = supportingDocModalProps.handleClose;
    const submissionTypeDisplay: SubmissionTypeDisplay = supportingDocModalProps.submissionTypeDisplay;
    const submissionType: SubmissionType = supportingDocModalProps.submissionType;
    const submissionState: SubmissionState = supportingDocModalProps.submissionState;
    const caseId: number = supportingDocModalProps.caseId;
    const caseName: string = supportingDocModalProps.caseName;
    const caseNumber: string = supportingDocModalProps.caseNumber;
    const submissionDescription = supportingDocModalProps.submissionDescription;
    const [outSubmissionId, setSubmissionId] = useState<number>(supportingDocModalProps.submissionId);
    const [filesAreSubmitted, setFilesAreSubmitted] = useState<boolean>(false);
    const [publishMessage, setPublishMessage] = useState<boolean>(false);
    const [submissionSaveForLaterMessageIsShown, setSubmissionSaveForLaterMessageIsShown] = useState<boolean>(false);
    const [cancelSubmissionModalIsOpen, setCancelSubmissionModalIsOpen] = useState(false);
    const documentTypes: ICaseDocumentType[] = useSelector((state: any) => state.claimFileState.documentTypes);
    const [docTypeRows, setDocTypeRows] = useState<ISupportingDocTypeProps[]>();
    const [submissionIsCanceled, setSubmissionIsCanceled] = useState<boolean>(false);
    const submissionDocuments: IDocument[] = useSelector((state: any) => state.submissionListState.submissionDocuments);
    const submissionDocumentsIsLoaded: boolean = useSelector((state: any) => state.submissionListState.documentsLoaded);
    const claimFileState: IClaimFileState = useSelector((state: any) => state.claimFileState);
    const [SaveForLaterModalModalDialogOpen, setSaveForLaterModalModalDialogOpen] = React.useState(false);
    const [saveLaterText, setSaveLaterText] = useState<string>("");
    const [stringContentData, setStringContentData] = useState<IStringValue[]>();
    const [additionalDocuments, setAdditionalDocuments] = useState<ISupportingDocTypeProps[]>([]);
    const [allFilesSelected, setAllFilesSelected] = useState(false);
    const [otherDocumentIdsForDeletion, setOtherDocumentIdsForDeletion] = useState<number[]>([]);
    const [newSubmissionStringContentData, setNewSubmissionStringContentData] = useState<IStringValue | null>();
    const { isInRole } = useContext(UserContext) as UserContextType;
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);
    const [parentConsolidatedDocsChecked, setParentConsolidatedDocsChecked] = useState<boolean>(false);

    const handleModalClose = () => {

        if (!filesAreSubmitted && !submissionIsCanceled) {
            cancelSubmission();
            return;
        }

        clearModal();
        setFilesAreSubmitted(false);
        setSubmissionId(0);
        dispatch(clearSupportingDocFileAction());
        dispatch(clearPublishSubmissionMessageAction());
        dispatch(clearCaseDocumentTypeAction());
        dispatch(clearSubmissionDocumentsListAction());
        dispatch(clearDeleteDocumentAction());
        dispatch(clearSupportEmailAddressAction());
        

        handleClose(submitClicked);
    };

    const location = useLocation();

    useEffect(() => {
        const path = location.pathname + location.search;
        ReactGA.send({ hitType: "pageview", page: path, title: "Claim Supporting Document File Upload Modal" });
    }, [location]);

    useEffect(() => {
        setSubmitClicked(false);

        const fetchInfoStrings = async () => {
            const data: any = await getInfoJsonWithArray([
                Strings.SubmissionWizardReqDocs,
                Strings.SubmissionDocsNotSubmitted,
                Strings.TermsOfService,
                Strings.DataVerification,
                Strings.AttestationDocument,
                Strings.SignatureAuthorization,
                Strings.ProofOfClaim,
                Strings.OptionalSupportingDocumentText,
                Strings.ConsolidatedSupportingDocumentsListText,
                Strings.ConsolidatedSupportingDocuments]);

            if (data) {
                let idx = -1;

                data.map((item: IStringValue, index: number) => {
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]))

                    if (item.key === Strings.SubmissionWizardReqDocs) {
                        idx = index; 
                        setNewSubmissionStringContentData(item);
                    }

                });

                if (idx > -1) {
                    data.splice(idx, 1);
                }

                setStringContentData(data);
            }
        }

        fetchInfoStrings().catch(console.error);
    }, []);

    useEffect(() => {
        if (modalOpen && !claimFileState.loadingDocumentTypes && !claimFileState.loadDocumentTypesSuccess)
            getRequiredDocumentTypes();

        if (modalOpen && !docTypeRows && claimFileState.loadDocumentTypesSuccess && (hasSubmissionDocuments() || hasNoSubmissionDocuments())) 
            buildDocTypeProps();

        if (sendSubmissionMessage())
            publishSubmissionMessage();

        if (saveForLaterMessage())
            showSubmissionSaveForLaterMessage();

        if (closeSupportingDocModal()) {
            handleModalClose();
        }

        hasAllFiles();

    }, [modalOpen, submissionDocumentsIsLoaded, filesAreSubmitted, submissionSaveForLaterMessageIsShown, claimFileState, parentConsolidatedDocsChecked]);

    const handleClick = (event: any) => {
        setShow(!show);
        setTarget(event.target);
    };
    
    const buildDocTypeProps = (): void => {
        setDocTypeRows(buildDocTypeRows());
    };

    const getRequiredDocumentTypes = (): void => {

        if (submissionState === SubmissionState.Started)
            getExistingSubmissionDocuments();

        let payload = {
            caseId: caseId,
            submissionType: submissionType,
            includeConsolidatedDocumentsType: true
        }

        dispatch(getCaseDocumentTypesAction(payload));
    };

    const getExistingSubmissionDocuments = (): void => {
        let payload = {
            submissionId: outSubmissionId,
            filter: []
        };

        dispatch(getSubmissionDocumentsListAction(payload));
    };

    let tempDocId: number = 1;

    function handleDocumentType(item: ICaseDocumentType, docTypeRows: ISupportingDocTypeProps[]) {
        let docType: DocumentType = item.documentTypeId;

        if (hasSubmissionDocuments())
            var submissionDocument = submissionDocuments.find(r => r.documentType === docType);

        let response: IResponse = { success: false, message: "" };

        let newItem: ISupportingDocTypeProps = {
            id: tempDocId++,
            docTypeDescription: item.label,
            existingFileName: submissionDocument ? submissionDocument.name : "",
            existingDocumentId: submissionDocument ? submissionDocument.id : 0,
            isUploading: false,
            isUploaded: false,
            isUploadFailed: false,
            isNotProvided: false,
            progress: 0,
            file: undefined,
            fileIsCorrectType: fileIsCorrectType,
            response: response,
            isConsolidatedDocumentsType: item.isConsolidated ? true : false
        };

        docTypeRows.push(newItem);
    }

    const buildDocTypeRows = (): ISupportingDocTypeProps[] => {
        var docTypeRows: ISupportingDocTypeProps[] = [];

        documentTypes.forEach((item) => {
            handleDocumentType(item, docTypeRows);
        });

        handleOtherDocumentType(additionalDocuments);
        handleAddNewRow();

        let payload = {
            supportingDocTypeProps: docTypeRows
        };

        dispatch(setSupportingDocTypeProps(payload));

        return docTypeRows;
    };

    const hasSubmissionDocuments = (): boolean => {
        return (submissionState === SubmissionState.Started && submissionDocumentsIsLoaded && submissionDocuments.length > 0)
            ? true
            : false;
    }

    const hasNoSubmissionDocuments = (): boolean => {
        return ((submissionState !== SubmissionState.Started) || (submissionDocumentsIsLoaded && submissionDocuments.length === 0))
            ? true
            : false;
    }

    const filesAreUploading = (): boolean => {
        return (filesAreSubmitted && docTypeRows && (docTypeRows.some(item => item.isUploading) || claimFileState.publishMessage))
            ? true
            : false;
    }

    const allFilesUploaded = (): boolean => {
        return (filesAreSubmitted && docTypeRows && docTypeRows.every(item => item.isUploading === false) && docTypeRows.every(item => item.isUploadFailed === false))
            ? true
            : false;
    }

    const hasAllFiles = (): boolean => {
        var goodFiles = [];
        let success = false;

        if (docTypeRows && docTypeRows.length > 0) {
            docTypeRows.forEach((item) => {

                if (item.docTypeDescription === DocumentTypeDisplay.Other && item.isNotProvided) {
                    goodFiles.push(true);
                    return;
                }

                let input = document.getElementById(`fileInput${item.id}`) as HTMLInputElement;

                if (input && input.files && input.files.length > 0 && item.file) {
                    let oldName = item.file.name;
                    let newName = input.files[0].name;

                    if (newName !== oldName)
                        item.file = input.files[0];
                }

                if (((input && input.files && fileIsCorrectType(input.files[0])) || item.existingDocumentId > 0) && item.isConsolidatedDocumentsType === parentConsolidatedDocsChecked )
                    goodFiles.push(true);

            });

            if (docTypeRows.filter(row => row.isConsolidatedDocumentsType === parentConsolidatedDocsChecked).length === goodFiles.length) {
                success = true;

                if (!allFilesSelected)
                    setAllFilesSelected(true);
            }
            else {
                success = false;

                if (allFilesSelected)
                    setAllFilesSelected(false);
            }
        }

        return success;
    };

    const hasFilesWithIncorrectFileType = (): boolean => {
        var badFiles = [];

        if (docTypeRows) {
            docTypeRows.forEach((item) => {
                let input = document.getElementById(`fileInput${item.id}`) as HTMLInputElement;

                if (input && input.files && input.files.length > 0 && !fileIsCorrectType(input.files[0]))
                    badFiles.push(true);
            });

            return (badFiles.length > 0) ? true : false
        }

        return false;
    };

    const fileIsCorrectType = (file: File): boolean => {

        if (file && file !== undefined) {
            let extension = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();

            return extension === 'xlsx' || extension === 'docx' || extension === 'pdf' || extension === 'txt';
        }

        return false;
    };

    const clearModal = (): void => {

        if (docTypeRows)
            docTypeRows.forEach((item) => {
                let input = document.getElementById(`fileInput${item.id}`) as HTMLInputElement;

                if (input)
                    input.value = "";
            });

    };

    const cancelSubmission = (): void => {
        setCancelSubmissionModalIsOpen(true);
    };

    const deleteExistingDocuments = (documentIds: number[]): void => {
        let payload = {
            documentIds: documentIds
        };

        dispatch(deleteDocumentsAction(payload));
    };

    const uploadFiles = (filesToUpload: ISupportingDocTypeProps[]): void => {
        let documentIds: number[] = [];

        filesToUpload.forEach((item) => {
            let data = new FormData();
            data.append('file', item.file!);

            let payload = {
                file: data,
                submissionId: outSubmissionId,
                documentTypeId: getDocTypeIdFromDescription(item.docTypeDescription),
                supportingDocTypeProps: item
            };

            dispatch(uploadSupportingDocFileAction(payload));

            if (item.existingDocumentId > 0)
                documentIds.push(item.existingDocumentId);

        })

        if (documentIds.length > 0)
            deleteExistingDocuments(documentIds);

    };

    const submitFiles = (publishSubmission: boolean): void => {
        setFilesAreSubmitted(true);
        
        let filesToUpload: ISupportingDocTypeProps[] = [];

        if (docTypeRows) {
            docTypeRows.forEach((item) => {

                if (item.file && item.isConsolidatedDocumentsType === parentConsolidatedDocsChecked) {
                    var file: File = item.file;
                    let isUploaded = item.isUploaded;

                    if (file && !isUploaded) {
                        item.isUploading = true;
                        filesToUpload.push(item);
                    }
                }
                else
                    item.isNotProvided = true;

            });

            var index = filesToUpload.length;

            additionalDocuments.forEach((item) => {

                if (item.file) {
                    item.id = index + 1;
                    var file: File = item.file;
                    let isUploaded = item.isUploaded;

                    if (file && !isUploaded) {
                        item.isUploading = true;
                        filesToUpload.push(item);
                    }
                }
                else
                    item.isNotProvided = true;
            });

            let payload = {
                supportingDocTypeProps: docTypeRows
            };

            dispatch(setSupportingDocTypeProps(payload));
        }

        if (otherDocumentIdsForDeletion.length > 0)
            deleteExistingDocuments(otherDocumentIdsForDeletion);

        if (filesToUpload.length > 0) { 
            uploadFiles(filesToUpload);
            
        }
            

        if (publishSubmission) {
            setSubmitClicked(true);
            setPublishMessage(true);
        }
    };

    function handleOtherDocumentType(additionalDocuments: ISupportingDocTypeProps[]) {
        let response: IResponse = { success: false, message: "" };

        let newItem: ISupportingDocTypeProps = {
            id: 0,
            docTypeDescription: DocumentTypeDisplay.Other,
            existingFileName: "",
            existingDocumentId: 0,
            isUploading: false,
            isUploaded: false,
            isUploadFailed: false,
            isNotProvided: true,
            progress: 0,
            file: undefined,
            fileIsCorrectType: fileIsCorrectType,
            response: response,
            isConsolidatedDocumentsType: false
        };

        if (hasSubmissionDocuments())
            submissionDocuments.filter(d => d.documentType === DocumentType.Other).forEach((d) => {
                newItem = {
                    ...newItem,
                    id: tempDocId++,
                    existingFileName: d.name,
                    existingDocumentId: d.id,
                };

                additionalDocuments.push(newItem);
            });
    }

    const handleOptionalDocFileUpload = useCallback((index, event) => {
        index = index + 1;
        var newFile = event.target.files[0];
        let response: IResponse = { success: false, message: "" };

        let newItem: ISupportingDocTypeProps = {
            id: 0,
            docTypeDescription: DocumentTypeDisplay.Other,
            existingFileName: "",
            existingDocumentId: 0,
            isUploading: false,
            isUploaded: false,
            isUploadFailed: false,
            isNotProvided: true,
            progress: 0,
            file: undefined,
            fileIsCorrectType: fileIsCorrectType,
            response: response,
            isConsolidatedDocumentsType: false
        };

        newItem = {
            ...newItem,
            id: index,
            existingFileName: "",
            existingDocumentId: 0,
            file: newFile
        };

        setAdditionalDocuments([newItem, ...additionalDocuments]);
    }, [additionalDocuments]);

    const handleAddNewRow = () => {
        let response: IResponse = { success: false, message: "" };

        let newItem: ISupportingDocTypeProps = {
            id: 0,
            docTypeDescription: DocumentTypeDisplay.Other,
            existingFileName: "",
            existingDocumentId: 0,
            isUploading: false,
            isUploaded: false,
            isUploadFailed: false,
            isNotProvided: true,
            progress: 0,
            file: undefined,
            fileIsCorrectType: fileIsCorrectType,
            response: response,
            isConsolidatedDocumentsType: false
        };

        setAdditionalDocuments([...additionalDocuments, newItem]);
    };

    const handleRemoveClick = useCallback((index: any) => {
        var list = [...additionalDocuments];
        var recordToDelete = list[index];
        setOtherDocumentIdsForDeletion([recordToDelete.existingDocumentId, ...otherDocumentIdsForDeletion]);
        list.splice(index, 1);
        setAdditionalDocuments(list);
    }, [additionalDocuments]);

    const handleCancelSubmissionModalClosed = () => {
        setCancelSubmissionModalIsOpen(false);
    };

    const handleSaveForLaterModalModalDialogClose = () => {
        setSaveForLaterModalModalDialogOpen(false);
    }

    const saveForLaterMessage = (): boolean => {
        return (allFilesUploaded() && !publishMessage) ? true : false;
    }

    const sendSubmissionMessage = (): boolean => {
        return (allFilesUploaded() && publishMessage && !claimFileState.publishMessageDispatched)
            ? true
            : false;
    }

    const publishSubmissionMessage = (): void => {
        let submissionMessagePayload = {
            submissionId: outSubmissionId
        };

        dispatch(publishSubmissionMessageAction(submissionMessagePayload));
    };

    const showSubmissionSaveForLaterMessage = (): void => {
        dispatch(
            showAlert({
                hidden: false,
                message: `Submission ${submissionDescription} saved for later`,
                severity: AlertType.success
            })
        );

        setSubmissionSaveForLaterMessageIsShown(true);
    };

    const saveForLater = async (): Promise<void> => {

        if (stringContentData !== null)
            stringContentData?.map((content) => {

                if (content.key === Strings.SubmissionDocsNotSubmitted) {
                    setSaveLaterText(content.value);
                    setSaveForLaterModalModalDialogOpen(true);
                }

            });
    };

    const closeSupportingDocModal = (): boolean => {
        return (allFilesUploaded() && (claimFileState.publishMessageSuccess || submissionSaveForLaterMessageIsShown))
            ? true
            : false;
    }

    const handleCloseConfirmationDialog = (
        event: {},
        reason: "backdropClick" | "escapeKeyDown"
    ) => {
        if (reason === "backdropClick") {
            console.log(reason);
        } else {
            setConfirmationModalOpen(false);
            handleModalClose()
        }
    };

    return (
        <Modal open={modalOpen} onClose={handleModalClose}>
            <Card className={classes.claimmodal}>
                <div style={{ padding: "16px", paddingBottom: "0" }} ref={ref}>
                    <CancelSubmissionModal
                        modalOpen={cancelSubmissionModalIsOpen}
                        handleCancelSubmissionClose={handleCancelSubmissionModalClosed}
                        handleSupportingDocModalClose={handleClose}
                        submissionId={outSubmissionId}
                        clearDocModal={handleModalClose}
                        setSubmissionIsCanceled={setSubmissionIsCanceled}
                        submissionDescription={submissionDescription}
                    />
                    <Typography variant="h5" className={classes.titleLeft} noWrap>
                        {submissionTypeDisplay}
                    </Typography>
                    <Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent: "left", minWidth: "25px" }} onClick={handleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} /></Button>
                    <Overlay
                        show={show}
                        target={target}
                        placement="right-start"
                        container={ref}
                        containerPadding={20}
                        rootClose
                        onHide={() => setShow(false)}
                    >
                        <Popover id="popover-contained">
                            <Popover.Header as="h3"><span style={{ color: "#000" }}>Action Guide</span></Popover.Header>
                            {newSubmissionStringContentData && (
                                <Popover.Body dangerouslySetInnerHTML={{ __html: newSubmissionStringContentData.value }}></Popover.Body>
                            )}
                        </Popover>
                    </Overlay>
                    <Typography variant="h5" className={classes.titleRight} noWrap>
                        {submissionDescription}
                    </Typography>
                </div>
                <CardHeader sx={{ paddingLeft: "16px", paddingTop: "5px", paddingBottom: "0px"} }
                    title={
                        <div ref={ref}>
                            <ConfirmationMessageModal open={confirmationModalOpen} handleClose={handleCloseConfirmationDialog} />
                        </div>
                    }
                    subheader={
                        <div>
                            <InputLabel className={classes.label}>Case:</InputLabel>
                            <Typography variant="h6" className={classes.caseTitleNumber} noWrap>
                                {caseName} {caseNumber}
                            </Typography>
                        </div>
                    }
                    classes={{ title: classes.modalHeaderTitle }}
                    action={<Icon style={{ position: "absolute", top: "20px", right: "20px", cursor: "pointer" }} onClick={() => { handleModalClose() }}>close</Icon>}>
                </CardHeader>

                <SupportingDocFilesUploadCard
                    docTypeRows={docTypeRows}
                    filesAreUploading={filesAreUploading()}
                    handleOptionalDocFileUpload={handleOptionalDocFileUpload}
                    additionalDocuments={additionalDocuments}
                    handleRemoveClick={handleRemoveClick}
                    stringContentData={stringContentData}
                    hasAllFiles={hasAllFiles}
                    setParentConsolidatedDocsChecked={setParentConsolidatedDocsChecked}
                />

                <Grid>

                </Grid>

                <CardActions className={classes.cardAction}>

                    <Button
                        disabled={!allFilesSelected || filesAreSubmitted}
                        classes={!allFilesSelected || filesAreSubmitted ? { root: classes.disabledButton, disabled: classes.disabledButton } :
                            { root: classes.primaryBtn, disabled: classes.primaryBtn }}
                        onClick={() => { submitFiles(true) }}
                        color="primary" >
                        Submit
                    </Button>
                    <Button
                        disabled={filesAreSubmitted || hasFilesWithIncorrectFileType()}
                        classes={filesAreSubmitted || hasFilesWithIncorrectFileType() ? { root: classes.disabledButton, disabled: classes.disabledButton } :
                            { root: classes.secondaryBtn, disabled: classes.secondaryBtn }}
                        onClick={() => { saveForLater() }}
                        variant="outlined"
                        color="secondary">
                        Save for later
                    </Button>
                    <Button
                        onClick={() => { !filesAreSubmitted ? cancelSubmission() : handleModalClose() }}
                        classes={{ root: classes.secondaryBtn, disabled: classes.secondaryBtn }}
                        color="secondary">
                        {!filesAreSubmitted ? "Cancel" : "Close"}
                    </Button>
                </CardActions>

                {
                    saveLaterText &&
                    (
                        <SaveForLaterModal
                            message={saveLaterText}
                            modalOpen={SaveForLaterModalModalDialogOpen}
                            handleClose={handleSaveForLaterModalModalDialogClose}
                            saveforlatersubmit={submitFiles}
                        />
                    )
                }
            </Card >
        </Modal >
    );
};

export default SupportingDocModal;